import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { DashboardService } from "../dashboard.service";
import { Router, ActivatedRoute } from "@angular/router";
import { MatDialogRef } from "@angular/material/dialog";
import { CommonService } from "../common.services";
import { SharedService } from "src/app/shared/shared.service";
import { TranslateService } from "@ngx-translate/core";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: "app-return-waiting-room",
  templateUrl: "./return-waiting-room.component.html",
  styleUrls: ["./return-waiting-room.component.scss"],
})
export class ReturnWaitingRoomComponent implements OnInit {
  patientForm: FormGroup;
  consultId: any;
  constructor(
    private formBuilder: FormBuilder,
    private dashboardService: DashboardService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private commonServices: CommonService,
    private translationService: TranslateService,
    private sharedService: SharedService,
    public dialogRef: MatDialogRef<ReturnWaitingRoomComponent>,
  ) {}

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.consultId = params.id;
    });
    this.patientForm = this.formBuilder.group({
      description: ["", Validators.required],
    });
  }
  returntowaitingRoom() {
    const formValue = this.patientForm.value;
    this.dashboardService
      .returntowaitingRoom(this.consultId, formValue)
      .subscribe(
        (res: any) => {
          this.translationService
            .get("Call has been returned to the queue successfully")
            .subscribe((text: string) => {
              this.sharedService.showMessage(text, "success");
            });
          this.dialogRef.close();
          this.router.navigate([""]);
        },
        (error: HttpErrorResponse) => {
          this.handleError(error);
        },
      );
  }
  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = error.error.message;
      this.sharedService.showErrorMessage(errorMessage, "single");
    } else {
      // server-side error

      if (error.status == 422) {
        let values = [];
        for (let key in error.error.errors.messages) {
          values.push(error.error.errors.messages[key]);
        }
        this.sharedService.showMultipleErrors(values, "multi");
      } else {
        if (typeof error.error.errors == "object") {
          errorMessage = error.error.errors.messages[0];
        } else {
          errorMessage = error.error.errors;
        }
        this.sharedService.showErrorMessage(errorMessage, "single");
      }
    }
  }
}
