<div class="flex">
  <div class="data">
    <img
      [src]="
        data == 'Cita no confirmada'
          ? '../../../assets/images/y-tick.svg'
          : '../../../assets/images/tick.svg'
      "
      style="margin-right: 10px"
    />
    <span>{{ data }}</span>
  </div>
  <!-- <div class="dismiss">
        <button mat-icon-button (click)="snackBarRef.dismiss()">
            <mat-icon>close</mat-icon>
          </button>
      </div> -->
</div>
