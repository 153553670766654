import { NgModule } from "@angular/core";
import { Routes, RouterModule, ExtraOptions } from "@angular/router";
import { LoginComponent } from "./login/login.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { AuthenticationGuard } from "./core/authentication.guard";
import { RegistrationComponent } from "./registration/registration.component";
import { VerifyComponent } from "./verify/verify.component";
import { Login2FAComponent } from "./login2-fa/login2-fa.component";
import { Login2FaTypeCodeComponent } from "./login2-fa-type-code/login2-fa-type-code.component";
import { AuthenticationAgentGuard } from "./core/authentication-agent.guard";
import { VerifyAgentComponent } from "./verify-agent/verify-agent.component";
import { CreatePasswordComponent } from "./create-password/create-password.component";

export const routes: Routes = [
  {
    path: "agent",
    loadChildren: () =>
      import("./agent/agent.module").then((a) => a.AgentModule),
    canActivate: [AuthenticationAgentGuard],
  },
  {
    path: "",
    loadChildren: () =>
      import("./dashboard/dashboard.module").then((d) => d.DashboardModule),
    canActivate: [AuthenticationGuard],
  },

  { path: "login", component: LoginComponent },
  { path: "forgot-pwd", component: ForgotPasswordComponent },
  { path: "reset-pwd", component: ResetPasswordComponent },
  { path: "verify", component: VerifyComponent },
  { path: "register", component: RegistrationComponent },
  { path: "verify-agent", component: VerifyAgentComponent },
  { path: "create-pwd", component: CreatePasswordComponent },
  { path: "login-2FA", component: Login2FAComponent },
  { path: "validate-2FA", component: Login2FaTypeCodeComponent },
  { path: "validate-2FA/:method", component: Login2FaTypeCodeComponent },
  { path: "**", component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
