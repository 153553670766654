import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import {
  MatDialogRef,
  MatDialog,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";

import { SpecialtiesService } from "src/app/core/specialties.service";
import moment from "moment";
import { TimepickerDialogComponent } from "../time-picker-dialog/timepicker-dialog.component";
import { DashboardService } from "src/app/dashboard/dashboard.service";
import { CleverTapService } from "src/app/clevertap.service";
import { CredentialsService } from "src/app/core/credentials.service";

@Component({
  selector: "app-schedule-appointment",
  templateUrl: "./schedule-appointment.component.html",
  styleUrls: ["./schedule-appointment.component.scss"],
})
export class ScheduleAppointmentComponent {
  appointmentForm: any;
  mytime: any;
  specialtyList: any[] = [];
  doctorList: any[] = [];
  selectedSpecialty: any;
  minDate = new Date();
  activeUser: any;
  constructor(
    private dialog: MatDialog,
    private readonly formBuilder: FormBuilder,
    private readonly specialtiesService: SpecialtiesService,
    private readonly dashboardService: DashboardService,
    public dialogRef: MatDialogRef<ScheduleAppointmentComponent>,
    private changeDetector: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly clevertap: CleverTapService,
    private readonly credentialsService: CredentialsService,
  ) {
    this.appointmentForm = this.formBuilder.group({
      date: [null, Validators.required],
      hour: ["", Validators.required],
      doctorSpecialist: ["", Validators.required],
      medicalSpecialty: ["", Validators.required],
    });

    this.activeUser = this.credentialsService.credentials.userDetails;

    this.getConsultationDetails(this.data._id);
  }

  onDateChange(event) {
    const date = moment(event.target.value, "DD/MM/YYYY");

    this.appointmentForm.controls.date.setValue(date.add(4, "hour").toDate());

    // La nueva fecha seleccionada
    // Aquí puedes hacer lo que necesites con la nueva fecha
  }

  getDatePlaceholder() {
    if (this.appointmentForm.controls.date.value == "") {
      return "Selecciona la fecha";
    } else {
      return "";
    }
  }

  getHourPlaceholder() {
    if (this.appointmentForm.controls.hour.value == "") {
      return "Selecciona la hora";
    } else {
      return "";
    }
  }

  setAppointmentData() {
    this.appointmentForm.setValue({
      doctorSpecialist: this.data.doctor?._id ?? "",
      medicalSpecialty: this.data.medicalSpecialty._id,
      hour: this.data?.hour ? this.getTime(this.data.hour) : "",
      date: this.data?.date
        ? moment(this.data.date).add(4, "hour").toDate()
        : "",
    });
  }

  getTime(hour: string) {
    if (!hour) {
      return "";
    }
    return moment(hour, "HH:mm:ss").format("h:mm A");
  }

  getDate(date: string) {
    return moment(date).add(4, "hour").format("DD/MM/YYYY");
  }

  submitAppointment() {
    if (this.appointmentForm.invalid) {
      this.appointmentForm.markAllAsTouched();
      return;
    }

    /* if(this.data.modalAction === 'schedule'){ */
    this.scheduleAppointment();
    /*   } */
  }

  scheduleAppointment() {
    /*    console.log(this.appointmentForm.value)schedule
    this.dialogRef.close(true); */
    this.specialtiesService
      .scheduleAppointment(this.data._id,{ ...this.appointmentForm.value, date:moment(this.appointmentForm.value.date).add(4, "hour").toDate()})
      .subscribe({
        next: (res) => {
          if (this.data.modalAction === "schedule") {
            this.clevertap.registerEvent$("appointment_scheduled", {
              user_id: this.data.patient.identificationNumber,
              specialty: res.data.medicalSpecialty,
              agent_id: this.activeUser._id,
              appointment_date: this.getDate(res.data.date),
              scheduling_time: this.getTime(res.data.hour),
            });
          }
          if (this.data.modalAction === "reschedule") {
            this.clevertap.registerEvent$("appointment_rescheduled", {
              user_id: this.data.patient.identificationNumber,
              specialty: res.data.medicalSpecialty,
              agent_id: this.activeUser._id,
              new_appointment_date: this.getDate(res.data.date),
              rescheduling_time: this.getTime(res.data.hour),
            });
          }

          this.dialogRef.close(true);
        },
        error: () => {
          this.dialogRef.close(false);
        },
      });
  }
  /*   rescheduleAppointment(){

       this.specialtiesService.rescheduleAppointment(this.data._id, this.appointmentForm.value).subscribe((response) => {
         console.log('response reschedule appointment', response)
         this.dialogRef.close(true);
       })
     } */

  getConsultationDetails(id) {
    this.dashboardService.getConsultationDetails(id).subscribe((res: any) => {
      this.getSpecialties(res.data.provider);
      this.setAppointmentData();
      this.getDoctors(res.data.medicalSpecialty);
    });
  }

  getSpecialties(provider: any) {
    this.specialtiesService.getSpecialties(provider).subscribe((response) => {
      this.specialtyList = response.data.providerMedicalSpecialty;
    });
  }

  getSpecialtyName(specialty: any) {
    return specialty.speciality
      ? specialty.speciality.speciality.es
      : specialty.subSpeciality.subSpeciality.es;
  }

  onSpecialtyChange(medicalSpecialty: any) {
    this.selectedSpecialty = this.specialtyList.find(
      (specialty) => specialty.medicalSpecialty._id === medicalSpecialty.value,
    );
    this.appointmentForm.controls.doctorSpecialist.setValue("", {
      emitEvent: false,
    });
    this.appointmentForm.controls.doctorSpecialist.setErrors(null);
    this.getDoctors(this.selectedSpecialty.medicalSpecialty);
  }

  getDoctors(specialty: any) {
    let params: any = {};
    if (specialty?.subSpeciality) {
      params["subSpeciality"] = specialty.subSpeciality._id;
      params["doctorRole"] = "2";
    }
    if (specialty?.speciality) {
      params["speciality"] = specialty.speciality._id;
      params["doctorRole"] = "2";
    }

    this.specialtiesService.getDoctorBySpecialty(params).subscribe((res) => {
      this.doctorList = res.data;
    });
  }

  openTimepicker() {
    const dialogRef = this.dialog.open(TimepickerDialogComponent, {
      width: "300px",
      panelClass: "timepicker-dialog-container",
      backdropClass: "timepicker-dialog-backdrop",
      data: this.appointmentForm.controls.hour.value,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.appointmentForm.controls.hour.setValue(result);
      }
    });
  }

  onClose() {
    this.dialogRef.close("close");
  }

  onCancel() {
    this.dialogRef.close(false);
  }
}
