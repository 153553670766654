import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";

import { CredentialsService } from "./credentials.service";

@Injectable({
  providedIn: "root",
})
export class AuthenticationAgentGuard implements CanActivate {
  constructor(
    private router: Router,
    private credentialsService: CredentialsService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean {
    if (this.credentialsService.isAuthenticated()) {
      if (this.credentialsService.credentials.role !== "agent") {
        this.router.navigate(["/dashboard"]);
        return true;
      } else {
        return true;
      }
    }
    this.router.navigate(["/login"], {
      queryParams: { redirect: state.url },
      replaceUrl: true,
    });

    return false;
  }
}
