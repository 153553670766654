import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import moment from "moment";
import "moment/locale/es";
import { SpecialtiesService } from "src/app/core/specialties.service";
import { SharedService } from "src/app/shared/shared.service";
import { ScheduleAppointmentComponent } from "../../schedule-appointment/schedule-appointment.component";
import {
  CANCEL_APPOINTMENT_DIALOG,
  CONFIRM_APPOINTMENT_DIALOG,
} from "src/app/shared/dialog/agent-dialog.config";
import { ConfirmDialogComponent } from "../../../confirm-dialog/confirm-dialog.component";
import { Router } from "@angular/router";
import { C } from "@angular/cdk/keycodes";
import { CleverTapService } from "src/app/clevertap.service";
import { CredentialsService } from "src/app/core/credentials.service";

moment.locale("es");
@Component({
  selector: "app-appointment-info",
  templateUrl: "./appointment-info.component.html",
  styleUrls: ["./appointment-info.component.scss"],
})
export class AppointmentInfoComponent implements OnInit {
  @Input() appointmentInfo: any;
  @Input() completeInfo: boolean = false;
  cancelStatus = [6, 7];
  confirmStatus = [3];
  rescheduleStatus = [4, 5];
  showOverlayAffiliationsView = false;
  activeUser: any;
  constructor(
    private readonly specialtiesService: SpecialtiesService,
    private readonly sharedService: SharedService,
    public dialog: MatDialog,
    private readonly router: Router,
    private changeDetector: ChangeDetectorRef,
    private readonly clevertap: CleverTapService,
    private readonly credentialsService: CredentialsService,
  ) {
    this.activeUser = this.credentialsService.credentials.userDetails;
  }
  ngOnInit(): void {
    if (this.appointmentInfo) {
      this.appointmentInfo = {
        ...this.appointmentInfo,
        specialtyName: this.getSpecialtyName(
          this.appointmentInfo.medicalSpecialty,
        ),
      };
    }
  }

  confirmAppointment(appointmentId: string) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "600px",
      disableClose: true,
      autoFocus: false,
      data: CONFIRM_APPOINTMENT_DIALOG,
      panelClass: "confirm-dialog-container",
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result !== "close") {
        this.specialtiesService
          .confirmAppointment(
            appointmentId,
            result ? { appointmentStatus: 8 } : { appointmentStatus: 9 },
          )
          .subscribe({
            next: (res) => {
              this.appointmentInfo = {
                ...this.appointmentInfo,
                appointmentStatus: result ? 8 : 9,
              };
              this.changeDetector.detectChanges();
              this.clevertap.registerEvent$("appointment_confirmed", {
                user_id: res.data.patientIdentificationNumber,
                specialty: res.data.medicalSpecialty,
                agent_id: this.activeUser._id,
                appointment_date: this.getDate(res.data.date),
              });
              this.sharedService.showMessage(
                !result ? "Cita no confirmada" : "Cita confirmada exitosamente",
                "success",
              );
              this.router.navigate(["agent/dashboard"]);
            },
            error: (error) => {
              this.sharedService.showErrorMessage(
                "La Cita no pudo ser confirmada",
                "error",
              );
            },
          });
      }
    });
  }

  cancelAppointment(appointmentId: string) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "600px",
      disableClose: true,
      autoFocus: false,
      data: CANCEL_APPOINTMENT_DIALOG,
      panelClass: "confirm-dialog-container",
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.specialtiesService
          .cancelAppointment(appointmentId, { appointmentStatusUpdatedBy: 1 })
          .subscribe({
            next: (res) => {
              this.appointmentInfo = {
                ...this.appointmentInfo,
                appointmentStatus: 7,
              };
              this.changeDetector.detectChanges();
              this.sharedService.showMessage(
                "Cita cancelada exitosamente",
                "success",
              );
              this.clevertap.registerEvent$("appoinment_cancelled", {
                user_id: res.data.patientIdentificationNumber,
                specialty: res.data.medicalSpecialty,
                agent_id: this.activeUser._id,
                appointment_date: this.getDate(res.data.date),
              });
              this.router.navigate(["agent/dashboard"]);
            },
            error: (error) => {
              this.sharedService.showErrorMessage(
                "La Cita no pudo ser cancelada",
                "error",
              );
            },
          });
      }
    });
  }

  showCompleteInfo() {
    this.completeInfo = true;
  }

  hideCompleteInfo() {
    this.completeInfo = false;
  }

  openScheduleFormDialog(action: string, title: string, button: string) {
    const dialogRef = this.dialog.open(ScheduleAppointmentComponent, {
      width: "480px",
      disableClose: true,
      panelClass: "schedule-appointment-container",
      autoFocus: false,
      data: {
        ...this.appointmentInfo,
        modalAction: action,
        modalTitle: title,
        button,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === "close") {
        return;
      }
      if (result === true) {
        this.sharedService.showMessage(
          "Cita reagendada exitosamente",
          "success",
        );
        this.appointmentInfo = {
          ...this.appointmentInfo,
          appointmentStatus: 3,
          appointmentRescheduledCount:
            this.appointmentInfo.appointmentRescheduledCount + 1,
        };
        this.changeDetector.detectChanges();
        this.router.navigate(["agent/dashboard"]);
      } else if (!result) {
        this.sharedService.showErrorMessage(
          "La Cita no pudo ser reagendada",
          "success",
        );
      }
    });
  }

  getDate(date: string) {
    return moment(date).add(4, "hour").format("DD/MM/YYYY");
  }

  formatAppointmentDate(date: string) {
    const month: string =
      moment(date).format("MMMM").charAt(0).toUpperCase() +
      moment(date).format("MMMM").slice(1);
    return moment(date).add(4, "hour").format(`DD [de] [${month}] YYYY`);
  }
  getSpecialtyName(specialty: any) {
    if (
      specialty.speciality instanceof Array &&
      specialty.speciality.length > 0
    ) {
      return specialty.speciality[0].speciality.es;
    }
    if (
      specialty.subSpeciality instanceof Array &&
      specialty.subSpeciality.length > 0
    ) {
      return specialty.subSpeciality[0].subSpeciality.es;
    }

    return specialty.speciality
      ? specialty.speciality.speciality.es
      : specialty.subSpeciality.subSpeciality.es;
  }

  getTime(hour: string) {
    if (!hour) {
      return "";
    }
    return moment(hour, "HH:mm:ss").format("h:mm A");
  }

  getAppointmentStatus(status: number, reschedule: number) {
    switch (status) {
      case 3:
        return reschedule > 0 ? "Reagendada" : "Agendada";
      case 4:
        return "Reagendada por paciente";
      case 5:
        return "Reagendada por doctor";
      case 7:
        return "Cancelada por doctor";
      case 6:
        return "Cancelada por paciente";
      case 8:
        return "Confirmada";
      case 9:
        return "No confirmada";
      default:
        return "";
    }
  }
  /*   getAppointmentStatus(status:number){
    switch(status){
      case 3:
        return 'Agendada'
      case 4:
        return 'Reagendada por paciente'
      case 5:
        return 'Reagendada por doctor'
      case 7:
        return 'Cancelada por doctor'
      case 6:
        return 'Cancelada por paciente'
      case 8:
        return 'Confirmada'
         case 9:
        return 'No confirmada'
      default:
        return ''
    }
  } */
}
