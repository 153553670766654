<div class="list-container">
  <div
    *ngFor="let message of messages"
    class="item-container"
    (click)="gotoPatient(message._id)"
  >
    <div class="img-container">
      <img
        *ngIf="message.patient.profilePic; else defaultPicture"
        class="img-user avatar-img"
        matListAvatar
        [src]="fileUrl + message.patient.profilePic"
      />
      <ng-template #defaultPicture>
        <ngx-avatars
          class="user-profile"
          [name]="message.patient.firstName + ' ' + message.patient.lastName"
          size="50"
        ></ngx-avatars>
      </ng-template>
      <div message *ngIf="isResScheduleByDoctor(message)" class="younger-icon">
        <img
          style="width: 100%; height: 100%; border-radius: 50%"
          src="assets/images/holadoc-corazon-azul.png"
        />
      </div>
    </div>
    <div class="text-container">
      <p style="font-size: 14px; font-weight: 600; line-height: 19.1px">
        {{ message.patient.firstName + " " + message.patient.lastName }}
      </p>
      <p style="font-size: 12px; line-height: 16px">
        {{ message.chiefComplaint.chiefComplaint.es }}
      </p>
    </div>
    <div class="icon-container">
      <mat-icon
        style="font-size: 19px; height: 19px; width: 19px; color: #46535b"
      >
        phone
      </mat-icon>
    </div>
  </div>
</div>
