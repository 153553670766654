import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { dataPrueba } from "../../data-test";
import { SpecialtiesService } from "src/app/core/specialties.service";
import { MatDialog } from "@angular/material/dialog";
import { ScheduleAppointmentComponent } from "./schedule-appointment/schedule-appointment.component";
import { Subscription } from "rxjs";
import { DashboardService } from "src/app/dashboard/dashboard.service";
import { environment } from "src/environments/environment";
import { ChangeDetectorRef } from "@angular/core";
@Component({
  selector: "app-agent-patient",
  templateUrl: "./patient.component.html",
  styleUrls: ["./patient.component.scss"],
})
export class PatientComponent implements OnInit {
  patient: any;
  consultationList: any[] = [];
  onlyOne = false;
  loading = true;
  private readonly subscriptions: Subscription = new Subscription();
  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly specialtiesService: SpecialtiesService,
    private readonly dashboardService: DashboardService,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.loading = true;
      const appointment = params.appointment;
      const patientId = params.patient;

      if (appointment == "true") {
        this.onlyOne = true;
        this.patientContainerDetails(patientId);
      } else {
        this.onlyOne = false;
        this.getPatientDetails(patientId);
      }
    });
  }
  getPatientDetails(id) {
    this.subscriptions.add(
      this.dashboardService.getPatientDetials(id).subscribe((res: any) => {
        this.patient = { patient: res };
        this.getConsultationList(id);
      }),
    );
  }

  patientContainerDetails(id) {
    this.subscriptions.add(
      this.dashboardService.getConsultationDetails(id).subscribe((res: any) => {
        this.loading = false;
        this.patient = res.data;
        this.consultationList = [];
        this.cdr.detectChanges();
      }),
    );
  }
  /*   getConsultationList(param: object) {
    const httpParams = this.getHttpParams(param);
    return this.http.get(`/consultations`, { params: httpParams });
  }
 */

  getConsultationList(id) {
    const params = {
      patient: id,
      "status[0]": 0,
      "status[1]": 1,
      "status[2]": 6,
      "appointmentStatus[0]": 3,
      "appointmentStatus[1]": 4,
      "appointmentStatus[2]": 5,
      "appointmentStatus[3]": 6,
      "appointmentStatus[4]": 7,
      "appointmentStatus[5]": 8,
      "appointmentStatus[6]": 9,
      size: 1000,
      /* "appointmentStatus[5]": 8, */
    };
    this.subscriptions.add(
      this.dashboardService
        .getConsultationList(params)
        .subscribe((res: any) => {
          this.loading = false;
          this.consultationList = res.data;
          this.cdr.detectChanges();
        }),
    );
  }

  goDashboard() {
    this.router.navigate(["agent/dashboard"]);
  }
}
