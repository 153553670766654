export enum AppointmentStatus {
  WAITING = 1,
  SCHEDULE = 2,
  SCHEDULED = 3,
  RESCHEDULE_BY_PATIENT = 4,
  RESCHEDULE_BY_DOCTOR = 5,
  CANCELLED_BY_PATIENT = 6,
  CANCELLED_BY_DOCTOR = 7,
  CONFIRMED = 8,
  NO_CONFIRMED = 9,
}
