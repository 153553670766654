import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnDestroy,
  Renderer2,
  AfterViewInit,
} from "@angular/core";
import { SocketEvent, SocketEvents } from "../../socketio.service";
import { ConnectionService } from "../../connection.service";
import { DashboardService } from "../../dashboard.service";
import { Router } from "@angular/router";
import { DataStoreServiceService } from "../../data-store-service.service";
import moment from "moment";
import { interval, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { MatSnackBar } from "@angular/material/snack-bar";
import { CredentialsService } from "src/app/core/credentials.service";
//import { analyzeAndValidateNgModules } from "@angular/compiler";
import { TranslateService } from "@ngx-translate/core";
import { SharedService } from "src/app/shared/shared.service";
import { SortOrder } from "src/app/shared/pipes/order-by/order-by.model";
import { P } from "@angular/cdk/keycodes";

export interface Section {
  Name: string;
  Complaint: string;
}

export interface TimeSpan {
  hours: number;
  minutes: number;
  seconds: number;
}

@Component({
  selector: "app-dashboard-specialist",
  templateUrl: "./specialist.component.html",
  styleUrls: ["./specialist.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpecialistComponent implements OnInit, OnDestroy {
  widthSet = false;
  loadingConsult = true;
  patientId;
  messages: any[] = [];
  messagesSpecialty: any[] = [];
  inProcessConsult: any = [];
  todayConsult: any = [];
  recentConsult: any = [];
  averagetimeConsult: any = [];
  waitingtimeConsult: any = [];
  consultId;
  waitingTime: any = [];
  public singPatientRecord: any;
  display;
  averageconsulttime;
  followUpList: any[] = [];
  consultComplete: boolean = false;
  currentLanguage: string;
  fileUrl: string;
  loading: boolean = true;
  sortOrder = SortOrder;
  private readonly subscriptions: Subscription = new Subscription();

  constructor(
    private connectionService: ConnectionService,
    private dashboardService: DashboardService,
    private router: Router,
    private store: DataStoreServiceService,
    private _snackBar: MatSnackBar,
    private changeDetector: ChangeDetectorRef,
    private credentialService: CredentialsService,
    private translateService: TranslateService,
    private sharedService: SharedService,
  ) {}

  ngOnInit(): void {
    if (this.translateService.currentLang == "en-US") {
      this.currentLanguage = "en";
    } else {
      this.currentLanguage = "es";
    }
    this.subscriptions.add(
      this.connectionService.messages.subscribe({
        next: (message) => {
          console.log("MESSAGE", message);

          const operations = {
            [SocketEvents.DoctorSpecialistWaitingRoom]: (
              event: SocketEvent,
            ) => {
              console.log("ESPECIALISTA", event);
              this.insertConsultation(this.messages, event);
            },
            [SocketEvents.RemoveFromDoctorSpecialistWaitingRoom]: (
              event: SocketEvent,
            ) => {
              console.log("ESPECIALISTA REMOVE", event);
              const idx = this.messages.findIndex(
                (x) => x._id === event.consultation,
              );
              if (idx !== -1) {
                this.messages.splice(idx, 1);
              }
            },
          };
          operations[message.operation]?.(message);
          this.loading = false;
        },
      }),
    );

    this.recentConstult();
    this.presentConstult();
    this.inprocessConstult();
    this.averageConsult();
    this.averagewaitingTime();

    this.getNextConsults();
    this.subscriptions.add(
      interval(1000).subscribe({
        next: () => {
          if (!this.changeDetector["destroyed"]) {
            this.changeDetector.detectChanges();
          }
        },
      }),
    );

    this.changeDetector.detectChanges();
    this.sendMessage();
    this.fileUrl = this.credentialService.credentials.fileUrl + "/";
  }

  getNextConsults() {
    this.dashboardService
      .getNextConsultations(this.credentialService.credentials.userDetails._id)
      .subscribe({
        next: (res: any) => {
          const response = res.data.map((item) => ({
            ...item,
            consultType: this.getConsultationType(item),
          }));

          this.followUpList = this.sortConsultByDate(response);
          console.log(response, "aa");
          console.log(this.followUpList);
        },
        error: (error: HttpErrorResponse) => {
          this.handleError(error);
        },
      });
  }

  sortConsultByDate(consults: any) {
    return consults.sort((a, b) => {
      const dateA = a.date.split("T")[0];
      const dateB = b.date.split("T")[0];
      const momentA = moment(
        `${dateA} ${a.hour}`,
        "YYYY-MM-DD hh:mm:ss",
      ).valueOf();
      const momentB = moment(
        `${dateB} ${b.hour}`,
        "YYYY-MM-DD hh:mm:ss",
      ).valueOf();
      return momentA - momentB;
    });
  }
  secondsToHms(entry) {
    let totalSeconds = Math.floor(
      (new Date().getTime() - new Date(entry.date).getTime()) / 1000,
    );
    var d = Number(totalSeconds);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);
    return (
      ("0" + h).slice(-2) +
      ":" +
      ("0" + m).slice(-2) +
      ":" +
      ("0" + s).slice(-2)
    );
  }

  sendMessage() {
    this.connectionService.sendMsg(SocketEvents.DoctorSpecialistWaitingRoom);
  }

  goToConsultationPage(record) {
    this.router.navigate(["/consults"], {
      queryParams: { id: record._id, patient: record.patient._id },
    });
  }

  waitingRoom(record) {
    /* this.subscriptions.add(
        this.dashboardService.getConsultation(record._id).subscribe({
          next: (res: any) => { */
    this.router.navigate(["/consults"], {
      queryParams: { id: record._id, patient: record.patient._id },
    });
    /*  },
          error: (error: HttpErrorResponse) => {
            this.handleError(error);
          },
        })
      ); */
  }

  followUpCall(followupItem) {
    this.subscriptions.add(
      this.dashboardService.getConsultation(followupItem._id).subscribe({
        next: (res: any) => {
          this.router.navigate(["/consults"], {
            queryParams: {
              id: followupItem._id,
              patient: followupItem.patient._id,
            },
          });
        },
        error: (error: HttpErrorResponse) => {
          this.handleError(error);
        },
      }),
    );
  }

  recentConstult() {
    this.subscriptions.add(
      this.dashboardService
        .getConsults({
          "status[0]": 5,
          doctor: this.credentialService.credentials.userDetails._id,
          startDate: moment().startOf("day").utc().format("YYYY-MM-DD"),
          endDate: moment().endOf("day").utc().format("YYYY-MM-DD"),
          sort: "-updatedAt",
        })
        .subscribe({
          next: (res: any) => {
            this.recentConsult = res["data"].map((item) => ({
              ...item,
              consultType: this.getConsultationType(item),
            }));
          },
          error: (error: HttpErrorResponse) => {
            this.handleError(error);
          },
        }),
    );
  }

  presentConstult() {
    this.subscriptions.add(
      this.dashboardService
        .getConsults({
          "status[0]": 0,
          "status[1]": 1,
          "appointmentType[0]": 3,
          doctor: this.credentialService.credentials.userDetails._id,
          startDate: moment().startOf("day").utc().format("YYYY-MM-DD"),
          endDate: moment().endOf("day").utc().format("YYYY-MM-DD"),
        })
        .subscribe({
          next: (res: any) => {
            this.todayConsult = res["data"];
            this.loadingConsult = false;
            this.changeDetector.detectChanges();
          },
          error: (error: HttpErrorResponse) => {
            this.handleError(error);
          },
        }),
    );
  }

  inprocessConstult() {
    this.subscriptions.add(
      this.dashboardService
        .getConsults({
          "status[0]": 2,
          "status[1]": 3,
          "status[2]": 4,
          includeFollowUp: 1,
          doctor: this.credentialService.credentials.userDetails._id,
        })
        .subscribe({
          next: (res: any) => {
            this.inProcessConsult = res["data"];
          },
          error: (error: HttpErrorResponse) => {
            this.handleError(error);
          },
        }),
    );
  }

  resumeConsult(info) {
    this.router.navigate(["/consults"], {
      queryParams: { id: info._id, patient: info.patient._id },
    });
  }

  averageConsult() {
    this.subscriptions.add(
      this.dashboardService.getAverageConsult().subscribe({
        next: (res: any) => {
          this.averagetimeConsult = res["data"];

          if (
            this.averagetimeConsult.length > 0 &&
            this.averagetimeConsult[0].consultationTime !== null
          ) {
            var averagetime =
              this.averagetimeConsult[0].consultationTime.toFixed(0);
            var hours = Math.floor(averagetime / 3600);
            var minutes = Math.floor((averagetime - hours * 3600) / 60);
            var seconds = averagetime - hours * 3600 - minutes * 60;

            // round seconds
            seconds = Math.round(seconds * 100) / 100;

            var result = hours < 10 ? "0" + hours : hours;
            result += ":" + (minutes < 10 ? "0" + minutes : minutes);
            result += ":" + (seconds < 10 ? "0" + seconds : seconds);
            this.averageconsulttime = result;
            return result;
          } else {
            this.averageconsulttime = "00:00:00";
          }
        },
        error: (error: HttpErrorResponse) => {
          this.handleError(error);
        },
      }),
    );
  }
  averagewaitingTime() {
    this.subscriptions.add(
      this.dashboardService.getwaitTime().subscribe({
        next: (res: any) => {
          this.waitingtimeConsult = res["data"];
          if (
            this.waitingtimeConsult.length > 0 &&
            this.waitingtimeConsult[0].waitingTime != null
          ) {
            var waitingtime = this.waitingtimeConsult[0].waitingTime.toFixed(0);

            var hours = Math.floor(waitingtime / 3600);
            var minutes = Math.floor((waitingtime - hours * 3600) / 60);
            var seconds = waitingtime - hours * 3600 - minutes * 60;

            // round seconds
            seconds = Math.round(seconds * 100) / 100;

            var result = hours < 10 ? "0" + hours : hours;
            result += ":" + (minutes < 10 ? "0" + minutes : minutes);
            result += ":" + (seconds < 10 ? "0" + seconds : seconds);
            this.display = result;
            return result;
          } else {
            this.display = "00:00:00";
          }
        },
        error: (error: HttpErrorResponse) => {
          this.handleError(error);
        },
      }),
    );
  }

  recentCompleteInfo(info) {
    this.router.navigate(["/consults"], {
      queryParams: { id: info._id, patient: info.patient._id },
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = error.error.message;
      this.sharedService.showErrorMessage(errorMessage, "single");
    } else {
      // server-side error

      if (error.status == 422) {
        let values = [];
        for (let key in error.error.errors.messages) {
          values.push(error.error.errors.messages[key]);
        }
        this.sharedService.showMultipleErrors(values, "multi");
      } else {
        if (typeof error.error.errors == "object") {
          errorMessage = error.error.errors.messages[0];
        } else {
          errorMessage = error.error.errors;
        }
        this.sharedService.showErrorMessage(errorMessage, "single");
      }
    }
  }

  private insertConsultation(
    consultationMap: any[],
    message: SocketEvent,
  ): void {
    const doctorProviders: any[] =
      this.credentialService.credentials.userDetails.providers;
    if (Array.isArray(message.data)) {
      message.data.forEach((consultation) => {
        if (
          doctorProviders.find(
            (provider) => provider._id === consultation.provider._id,
          )
        ) {
          const isInList = consultationMap.some(
            (x) => x._id === consultation._id,
          );
          if (!isInList) {
            consultationMap.push({
              ...consultation,
              consultType: this.getSpecialtyNameByDoctor(
                consultation.medicalSpecialty,
              ),
            });
          }
        }
      });
    } else {
      if (
        doctorProviders.find(
          (provider) => provider._id === message.data.provider._id,
        )
      ) {
        const isInList = consultationMap.some(
          (x) => x._id === message.data._id,
        );
        if (!isInList) {
          consultationMap.push({
            ...message.data,
            consultType: this.getSpecialtyNameByDoctor(
              message.data.medicalSpecialty,
            ),
          });
        }
      }
    }
  }

  public isYounger(message: any) {
    const dateOfBirth = message.patient.dateOfBirth;
    const now = moment();

    if (moment(now).diff(dateOfBirth, "y") < 18) {
      return true;
    } else {
      return false;
    }
  }

  getDate(date: any) {
    return moment(date).add(4, "hour").format("DD-MM-YYYY");
  }

  getTime(hour: string) {
    if (!hour) {
      return "";
    }
    return moment(hour, "HH:mm:ss").format("h:mm A");
  }

  getConsultationType(consult: any) {
    if (consult.appointmentType === 3) {
      return `${this.getSpecialtyName(consult.medicalSpecialty)} - `;
    } else {
      return "";
    }
  }

  getSpecialtyName(specialty: any) {
    if (specialty?.speciality.length > 0) {
      return specialty.speciality[0].speciality.es;
    } else if (specialty?.subSpeciality.length > 0) {
      return specialty.subSpeciality[0].subSpeciality.es;
    } else {
      return "";
    }
  }
  getSpecialtyNameByDoctor(specialty: any) {
    if (specialty?.speciality) {
      return specialty.speciality.speciality.es + " - ";
    } else if (specialty?.subSpeciality) {
      return specialty.subSpeciality.subSpeciality.es + " - ";
    } else {
      return "";
    }
  }
}
