import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectorRef, Component } from "@angular/core";
import { Subscription } from "rxjs";
import { DashboardService } from "src/app/dashboard/dashboard.service";
import { AppointmentStatus } from "../appointment-list/appointment-item/appointment.enum";
import moment from "moment";
import { Router } from "@angular/router";
const animations = {
  growManage: {
    from: { height: "45%" },
    to: { height: "100%" },
  },
  growLast: {
    from: { top: "-55%" },
    to: { top: "0px" },
  },
};

@Component({
  selector: "app-agent-appointment-management",
  templateUrl: "./appointment-management.component.html",
  styleUrls: ["./appointment-management.component.scss"],
})
export class AgentAppointmentManagementComponent {
  seeMore = false;
  disableButton = false;
  consults: any[] = [];
  selectedStatus: any = "";

  statusArray = [
    {
      value: AppointmentStatus.SCHEDULED,
      label: "Agendada",
    },
    {
      value: AppointmentStatus.RESCHEDULE_BY_PATIENT,
      label: "Reagendada por paciente",
    },
    {
      value: AppointmentStatus.RESCHEDULE_BY_DOCTOR,
      label: "Reagendada por doctor",
    },
    {
      value: AppointmentStatus.CONFIRMED,
      label: "Confirmada",
    },
    {
      value: AppointmentStatus.CANCELLED_BY_DOCTOR,
      label: "Cancelada por doctor",
    },
    {
      value: AppointmentStatus.CANCELLED_BY_PATIENT,
      label: "Cancelada por paciente",
    },
    {
      value: AppointmentStatus.NO_CONFIRMED,
      label: "No confirmada",
    },
  ];

  canceledStatus = [
    AppointmentStatus.CANCELLED_BY_DOCTOR,
    AppointmentStatus.CANCELLED_BY_PATIENT,
  ];
  private readonly subscriptions: Subscription = new Subscription();
  constructor(
    private readonly dashboardService: DashboardService,
    private changeDetector: ChangeDetectorRef,
    private readonly router: Router,
  ) {
    this.onStatusChange("");
  }

  displayTable() {
    const manageList = document.getElementById(
      "managementAppointmentList",
    ) as HTMLElement;
    const lastList = document.getElementById(
      "lastAppointmentList",
    ) as HTMLElement;
    this.seeMore = !this.seeMore;

    if (this.seeMore) {
      manageList.animate(
        [animations.growManage.from, animations.growManage.to],
        { duration: 1000, iterations: 1, fill: "forwards" },
      );

      lastList.animate([animations.growLast.from, animations.growLast.to], {
        duration: 1000,
        iterations: 1,
        fill: "forwards",
        direction: "reverse",
      });
    } else {
      manageList.animate(
        [animations.growManage.from, animations.growManage.to],
        {
          duration: 1000,
          iterations: 1,
          fill: "forwards",
          direction: "reverse",
        },
      );
      lastList.animate([animations.growLast.from, animations.growLast.to], {
        duration: 1000,
        iterations: 1,
        fill: "forwards",
      });
    }
  }
  get displayState() {
    return `Estado ${this.selectedStatus}`;
  }

  onStatusChange(event: any) {
    let params;
    if (event === "") {
      params = {
        "status[0]": 0,
        "status[1]": 1,
        "status[2]": 6,
        "appointmentStatus[0]": 3,
        "appointmentStatus[1]": 4,
        "appointmentStatus[2]": 5,
        "appointmentStatus[3]": 6,
        "appointmentStatus[4]": 7,
        "appointmentStatus[5]": 8,
        "appointmentStatus[6]": 9,
        size: 100,
        /* "appointmentStatus[5]": 8, */
      };
    }
    if (event !== "") {
      params = {
        "status[0]": 0,
        "status[1]": 1,
        "status[2]": 6,
        size: 100,
        appointmentStatus: event,
      };
    }

    this.recentConstult(params);
  }

  getTime(hour: string) {
    if (!hour) {
      return "";
    }
    return moment(hour, "HH:mm:ss").format("h:mm A");
  }

  getDate(date: string) {
    return moment(date).add(4, "hour").format("DD/MM/YYYY");
  }

  getAppointmentStatus(status: number, reschedule: number) {
    switch (status) {
      case 3:
        return reschedule > 0 ? "Reagendada" : "Agendada";
      case 4:
        return "Reagendada por paciente";
      case 5:
        return "Reagendada por doctor";
      case 7:
        return "Cancelada por doctor";
      case 6:
        return "Cancelada por paciente";
      case 8:
        return "Confirmada";
      case 9:
        return "No confirmada";
      default:
        return "";
    }
  }

  getDoctorName(item: any) {
    if (item.doctor) {
      return `${item?.doctor.firstName} ${item?.doctor.lastName}`;
    } else {
      return "";
    }
  }

  gotoPatient(id: number) {
    this.router.navigate([`agent/patient`], {
      queryParams: { patient: id, appointment: true },
    });
  }

  recentConstult(params: any) {
    this.subscriptions.add(
      this.dashboardService.getConsults(params).subscribe({
        next: (res: any) => {
          this.consults = res.data;
          this.changeDetector.detectChanges();
        },
        error: (error: HttpErrorResponse) => {
          console.log(error);
        },
      }),
    );
  }
}
