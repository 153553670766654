import {
  Component,
  OnInit,
  OnDestroy,
  NgZone,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from "@angular/core";
import { CredentialsService } from "../core/credentials.service";
import { qbEndpoints } from "../core/qb.conf";
import { BnNgIdleService } from "bn-ng-idle";
import { environment } from "src/environments/environment";
import { ChildrenOutletContexts, Router } from "@angular/router";
import { AuthenticationService } from "../core/authentication.service";
import { EMPTY, Subscription, interval } from "rxjs";
import { catchError, concatMap, switchMap, tap } from "rxjs/operators";
/* import { PushNotificationsService } from "ng-push-ivy"; */
import { CallStateService } from "../shared/call-state/call-state.service";
import {
  transition,
  trigger,
  animate,
  group,
  query,
  style,
} from "@angular/animations";
////
import { DashboardService } from "../dashboard/dashboard.service";
import { QbService } from "../dashboard/qb.service";
import { ConnectionService } from "../dashboard/connection.service";
import { VideoService } from "../dashboard/video/video.service";
import { SocketEvent, SocketEvents } from "../dashboard/socketio.service";
import { DataStoreServiceService } from "../dashboard/data-store-service.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { SharedService } from "../shared/shared.service";
import moment from "moment";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: "app-agent",
  templateUrl: "./agent.component.html",
  styleUrls: ["./agent.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger("routeAnimations", [
      transition("AgentDashboardComponent => PatientComponent", [
        style({ position: "relative" }),
        query(
          ":enter, :leave",
          style({
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
          }),
          { optional: true },
        ),

        group([
          // Block executes in parallel
          query(
            ":enter",
            [
              style({ transform: "translateX(100%)", opacity: 0 }),
              animate(
                "1.5s ease-in-out",
                style({ transform: "translateX(0%)", opacity: 1 }),
              ),
            ],
            { optional: true },
          ),
          query(
            ":leave",
            [
              style({ transform: "translateX(0%)", opacity: 1 }),
              animate(
                "1.5s ease-in-out",
                style({ transform: "translateX(-100%)", opacity: 0 }),
              ),
            ],
            { optional: true },
          ),
        ]),
      ]),
      transition("PatientComponent => AgentDashboardComponent", [
        style({ position: "relative" }),
        query(
          ":enter, :leave",
          style({
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
          }),
          { optional: true },
        ),
        group([
          // Block executes in parallel
          query(
            ":enter",
            [
              style({ transform: "translateX(-100%)", opacity: 0 }),
              animate(
                "1.5s ease-in-out",
                style({ transform: "translateX(0%)", opacity: 1 }),
              ),
            ],
            { optional: true },
          ),
          query(
            ":leave",
            [
              style({ transform: "translateX(0%)", opacity: 1 }),
              animate(
                "1.5s ease-in-out",
                style({ transform: "translateX(100%)", opacity: 0 }),
              ),
            ],
            { optional: true },
          ),
        ]),
      ]),
    ]),
  ],
})
export class AgentComponent implements OnInit, OnDestroy {
  qbDetails: any;
  userDetails: any;
  userProviders: any;
  doctorCallState = false;
  currentLanguage: string | undefined;
  fileUrl: string | undefined;
  loading: boolean = true;
  private readonly subscriptions: Subscription = new Subscription();

  constructor(
    //private _pushNotifications: PushNotificationsService,
    private contexts: ChildrenOutletContexts,
  ) {
    const pageTitle = document.getElementById("pageTitle") as HTMLElement;
    pageTitle.innerText = "HolaDOC Agente";

    // this._pushNotifications.requestPermission();
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
  getRouteAnimationData() {
    return this.contexts.getContext("primary")?.route?.snapshot.data[
      "animation"
    ];
  }
}
