import { Platform } from "@angular/cdk/platform";
import { Injectable } from "@angular/core";
import clevertap from "clevertap-web-sdk";

@Injectable({
  providedIn: "root",
})
export class CleverTapService {
  constructor(private readonly platform: Platform) {}
  registerEvent$(eventName: string, eventData: any) {
    // eslint-disable-next-line no-console
    console.log("registerEvent$", eventName, eventData);
    clevertap.event.push(eventName, {
      ...eventData,
      timestamp: new Date().toISOString(),
      device_type: this.platform.isBrowser ? "web" : "mobile",
    });
  }
  registerUser$(data: any) {
    // eslint-disable-next-line no-console
    console.log("onUserLogin$", data);
    clevertap.onUserLogin.push(data);
  }
}
