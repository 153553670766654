import { NgModule, LOCALE_ID } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AgentRoutingModule } from "./agent-routing.module";
import { AgentComponent } from "./agent.component";
import { SharedModule } from "../shared/shared.module";
import { MaterialModule } from "../material.module";
import { Routes, RouterModule } from "@angular/router";
import { FlexLayoutModule } from "@angular/flex-layout";
import { AvatarModule } from "ngx-avatars";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatChipsModule } from "@angular/material/chips";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { TranslateModule } from "@ngx-translate/core";

/* import {
  MAT_DATE_LOCALE,
  SatDatepickerModule,
  SatNativeDateModule,
} from "saturn-datepicker"; */
import { BnNgIdleService } from "bn-ng-idle";
import { CallStateService } from "../shared/call-state/call-state.service";
import { MatTooltipModule } from "@angular/material/tooltip";
//import { SignatureFieldComponent } from "../shared/signature-field/signature-page.component";
import { MatInputModule } from "@angular/material/input";
//import { SignatureModule } from "../shared/signature-module/signature.module";
import { AgentDashboardComponent } from "./components/dashboard/agent-dashboard.component";
import { PatientComponent } from "./components/patient/patient.component";
import { AppointmentListComponent } from "./components/dashboard/appointment-list/appointment-list.component";
import { PatientInfoComponent } from "./components/patient/patient-info/patient-info.component";
import { MatTabsModule } from "@angular/material/tabs";
import { AppointmentItemComponent } from "./components/dashboard/appointment-list/appointment-item/appointment-item.component";
import { AppointmentInfoComponent } from "./components/patient/patient-info/appointment-info/appointment-info.component";
import { AgentAppointmentManagementComponent } from "./components/dashboard/appointment-management/appointment-management.component";
import { AgentLastScheduledComponent } from "./components/dashboard/last-scheduled/last-scheduled.component";
import { ScheduleAppointmentComponent } from "./components/patient/schedule-appointment/schedule-appointment.component";
import { ConfirmDialogComponent } from "./components/confirm-dialog/confirm-dialog.component";
import { TimepickerDialogComponent } from "./components/patient/time-picker-dialog/timepicker-dialog.component";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatIconModule } from "@angular/material/icon";

@NgModule({
  declarations: [
    AgentComponent,
    AgentDashboardComponent,
    PatientComponent,
    AppointmentListComponent,
    AppointmentItemComponent,
    PatientInfoComponent,
    AppointmentInfoComponent,
    AgentAppointmentManagementComponent,
    AgentLastScheduledComponent,
    ScheduleAppointmentComponent,
    ConfirmDialogComponent,
    TimepickerDialogComponent,
  ],
  imports: [
    CommonModule,
    AgentRoutingModule,
    FormsModule,
    SharedModule,
    MaterialModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    AvatarModule,
    MatChipsModule,
    RouterModule,
    MatSnackBarModule,
    TranslateModule,
    // SatDatepickerModule,
    //  SatNativeDateModule,
    MatIconModule,
    MatTooltipModule,
    MatInputModule,
    // SignatureModule,
    MatTabsModule,
  ],
  //entryComponents: [AgentComponent],
  /* providers: [BnNgIdleService, { provide: LOCALE_ID, useValue: 'es-ES' },], */
})
export class AgentModule {}
