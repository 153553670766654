<!-- <app-home-header></app-home-header> -->
<div class="container">
  <div class="table-container">
    <div class="damn-head-wrapper" style="margin-bottom: 20px;gap: 30px;">
    <!--   <div style="width: 20%"> -->
        <div
          class="manual-input"
        
          
        >
          <mat-icon style="color: grey" matPrefix class="search-icon"
            >search</mat-icon
          >
          <input
            matInput
            [(ngModel)]="tableSearchQueryString"
            (ngModelChange)="tableSearchQuery.next($event)"
            type="text"
            class="form-control"
            id="search"
            placeholder="{{ 'Search' | translate }}"
            autocomplete="off"
          />
          <mat-icon
            matSuffix
            class="search-icon"
            style="cursor: pointer;overflow: unset;"
            (click)="clearSearch()"
            *ngIf="tableSearchQueryString"
            >clear</mat-icon
          >
      </div>
      <!-- /div> -->

      <!--    <div style="margin-left: 3%; width: 20%"> -->
        <div
        class="manual-input date-range"

        
          
        >
     <!--      <input
            matInput
            placeholder="{{ 'dd/mm/yyyy-dd/mm/yyyy' | translate }}"
            [max]="maxDate"
            [matDatepicker]="resultPicker"
            readonly
            [value]="date"
            (dateChange)="getdateFilter($event.value)"
          />
          <mat-datepicker
            panelClass="hdd-datepicker"
            #resultPicker
           
          >
          </mat-datepicker>
          <mat-date-range-picker #picker></mat-date-range-picker>
          <mat-datepicker-toggle
            *ngIf="!searchDate"
            matSuffix
            [for]="resultPicker"
          ></mat-datepicker-toggle> -->
          <mat-date-range-input [rangePicker]="picker" [max]="maxDate"  style="height: 30px !important;">
            <input matStartDate placeholder="dd/mm/yyyy -" readonly [value]="startingDate" (dateChange)="setStartDate($event.value)">
            <input matEndDate placeholder="dd/mm/yyyy" [value]="endingDate" readonly  (dateChange)="getdateFilter($event.value)">
          </mat-date-range-input>
          
          <mat-datepicker-toggle  *ngIf="!searchDate" matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
          <span matIconSuffix  class="pickerDate"(click)="clearDateFilter()">
            <mat-icon
            
              *ngIf="searchDate"
              
              src="../../assets/images/delete.png"
              >close</mat-icon
            >
          </span>
        </div>
      <!-- </div> -->
    </div>

    <table
      mat-table
      matSort
      [dataSource]="dataSource"
      (matSortChange)="sortData($event)"
      class="logi-box-shadow table"
    >
      <ng-container matColumnDef="FirstName">
        <th mat-header-cell *matHeaderCellDef>
          {{ "First Name" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.patient.firstName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="LastName">
        <th mat-header-cell *matHeaderCellDef>
          {{ "Last Name" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.patient.lastName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="DOB">
        <th mat-header-cell *matHeaderCellDef>
          {{ "Date of Birth" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{
            element.patient.dateOfBirth
              | date: "mediumDate" : "+0:00" : pipeLanguage
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="Venezuelan ID">
        <th mat-header-cell *matHeaderCellDef>
          {{ "Venezuelan ID" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.patient.identificationNumber }}
        </td>
      </ng-container>

      <ng-container matColumnDef="Gender">
        <th mat-header-cell *matHeaderCellDef>
          {{ "Gender" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="element.patient.gender == 1">
            {{ "Male" | translate }}
          </span>
          <span *ngIf="element.patient.gender == 2">
            {{ "Female" | translate }}
          </span>
          <span *ngIf="element.patient.gender == 3">
            {{ "Other" | translate }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="chiefComplaint">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          {{ "Chief Complaint" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{
            element.appointmentType == 2
              ? "Por triaje"
              : element.chiefComplaint.chiefComplaint[currentLanguage]
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="date">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          {{ "Consult Date" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.date | date: "mediumDate" : undefined : pipeLanguage }}
        </td>
      </ng-container>

      <ng-container matColumnDef="Status">
        <th mat-header-cell *matHeaderCellDef>
          {{ "Status" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.status == 5 ? ("Completed" | translate) : "" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="Call Type">
        <th mat-header-cell *matHeaderCellDef>
          {{ "Call Type" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="element.communicationMode == 1">
            {{ "Phone Call" | translate }}
          </span>
          <span *ngIf="element.communicationMode == 2">
            {{ "Video Call" | translate }}
          </span>
          <span *ngIf="element.communicationMode == 3">
            {{ "Chat" | translate }}
          </span>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        style="cursor: pointer"
        (click)="getPatientDetails(row)"
      ></tr>
    </table>
    <mat-paginator
      #paginator
      [pageSize]="pageSize"
      [length]="length"
      [hidePageSize]="true"
      [pageIndex]="currentPage"
      [pageSizeOptions]="pageSizeOptions"
      (page)="handlePaginator($event)"
    ></mat-paginator>
    <div [hidden]="!emptyData" style="text-align: center; font-weight: bold">
      {{ "No records found" | translate }}
    </div>
  </div>
</div>
