import { Component, OnInit, Inject } from "@angular/core";
import { DashboardService } from "../dashboard.service";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from "@angular/material/dialog";
import { FormBuilder, FormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { SharedService } from "src/app/shared/shared.service";
import { HttpErrorResponse } from "@angular/common/http";

export interface DialogData {
  document: any;
  status: boolean;
}

@Component({
  selector: "app-attachment-popup",
  templateUrl: "./attachment-popup.component.html",
  styleUrls: ["./attachment-popup.component.scss"],
})
export class AttachmentPopupComponent implements OnInit {
  tags: any = [];
  attachmentForm: FormGroup;
  attachmentDetails: any;
  imageExtension: any;
  currentLanguage = "es";
  pipeLanguage = "es";
  loading: boolean = true;
  editMode: boolean = false;
  editAccess: boolean = false;
  constructor(
    private dashboardService: DashboardService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private translationService: TranslateService,
    private sharedService: SharedService,
    public dialogRef: MatDialogRef<AttachmentPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
    if (this.data.status == true) {
      this.editAccess = true;
    } else {
      this.editAccess = false;
    }
    this.getallTags();
  }

  ngOnInit(): void {
    if (this.translationService.currentLang == "en-US") {
      this.currentLanguage = "en";
      this.pipeLanguage = this.translationService.currentLang;
    } else {
      this.currentLanguage = "es";
      this.pipeLanguage = "es-ES";
    }

    this.attachmentForm = this.fb.group({
      tags: [""],
      description: [""],
    });
  }

  getallTags() {
    this.dashboardService.getTags().subscribe(
      (res: any) => {
        this.tags = res.data;
        this.getAttachmentsInfo(this.data.document);
      },
      (error: HttpErrorResponse) => {
        this.handleError(error);
      },
    );
  }

  getAttachmentsInfo(document) {
    let patchTag;
    this.dashboardService.getAttachmentsInfo(document).subscribe(
      (res: any) => {
        this.attachmentDetails = res.data;
        let url = new URL(res.data.url);
        let path = url.pathname;
        this.imageExtension = path.split(".").pop().toLowerCase();
        this.loading = false;
        if (this.attachmentDetails.tag) {
          for (var i = 0; i < this.tags.length; i++) {
            if (this.tags[i]._id == this.attachmentDetails.tag.id) {
              patchTag = this.tags[i];
            }
          }
          this.attachmentForm.patchValue({
            tags: patchTag,
          });
        }
        this.attachmentForm.patchValue({
          description: this.attachmentDetails.description,
        });
      },
      (error: HttpErrorResponse) => {
        this.handleError(error);
      },
    ),
      (err) => console.log("Error getting Attachments", err);
  }

  closePopup() {
    this.dialog.closeAll();
  }

  changeTags(val) {}

  enableEdit() {
    this.editMode = true;
  }

  updateAttachment() {
    let data = {
      tag: this.attachmentForm.value["tags"]._id,
      description: this.attachmentForm.value["description"],
    };
    this.dashboardService
      .getupdateAttachement(this.data.document, data)
      .subscribe(
        (res: any) => {
          this.translationService
            .get("Attachment has been Updated Successfully")
            .subscribe((text: string) => {
              this.sharedService.showMessage(text, "success");
            });
          this.dialog.closeAll();
        },
        (error: HttpErrorResponse) => {
          this.handleError(error);
        },
      );
  }
  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = error.error.message;
      this.sharedService.showErrorMessage(errorMessage, "single");
    } else {
      // server-side error

      if (error.status == 422) {
        let values = [];
        for (let key in error.error.errors.messages) {
          values.push(error.error.errors.messages[key]);
        }
        this.sharedService.showMultipleErrors(values, "multi");
      } else {
        if (typeof error.error.errors == "object") {
          errorMessage = error.error.errors.messages[0];
        } else {
          errorMessage = error.error.errors;
        }
        this.sharedService.showErrorMessage(errorMessage, "single");
      }
    }
  }
}
