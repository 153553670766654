<div
  style="
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: relative;
  "
>
  <div id="lastAppointmentList" class="section-container last-appointment">
    <app-agent-last-scheduled></app-agent-last-scheduled>
  </div>
  <div
    id="managementAppointmentList"
    class="section-container management-appointment"
  >
    <app-agent-appointment-management></app-agent-appointment-management>
  </div>
</div>
