<div class="container" *ngIf="!loading">
  <mat-icon
    style="margin-left: 90%; cursor: pointer"
    mat-dialog-close
    mat-button
    (click)="closePopup()"
    >close</mat-icon
  >
  <!-- <pre>{{ attachmentDetails | json }}</pre> -->
  <p style="float: right; margin-top: 10px; margin-bottom: 10px">
    {{ "Uploaded date" | translate }}:
    <span>{{
      this.attachmentDetails.updatedAt
        | date: "longDate" : "+0:00" : pipeLanguage
    }}</span>
  </p>

  <div>
    <a
      target="_blank"
      href="{{ attachmentDetails.url }}"
      *ngIf="imageExtension == 'png'"
      ><img src="{{ attachmentDetails.url }}" class="img" /> <br
    /></a>

    <a
      target="_blank"
      href="{{ attachmentDetails.url }}"
      *ngIf="imageExtension == 'jpg' || imageExtension == 'jpeg'"
      ><img src="{{ attachmentDetails.url }}" class="img" /> <br
    /></a>

    <a
      target="_blank"
      href="{{ attachmentDetails.url }}"
      *ngIf="imageExtension == 'doc' || imageExtension == 'docx'"
      ><img src="../../../assets/images/word.svg" class="img" /> <br
    /></a>

    <a
      target="_blank"
      href="{{ attachmentDetails.url }}"
      *ngIf="imageExtension == 'pdf'"
      ><img src="../../../assets/images/pdf-view.svg" class="img" /> <br
    /></a>

    <a
      target="_blank"
      href="{{ attachmentDetails.url }}"
      *ngIf="
        imageExtension == 'xls' ||
        imageExtension == 'xlsx' ||
        imageExtension == 'csv'
      "
    >
      <img src="../../../assets/images/excel.svg" class="img" /> <br
    /></a>

    <div *ngIf="editAccess && !editMode">
      <span
        style="
          float: right;
          color: #65a7f6;
          cursor: pointer;
          display: inline-flex;
        "
        (click)="enableEdit()"
      >
        <img src="../../../assets/images/editable.svg" />
        <!-- <mat-icon>edit</mat-icon> -->
        <span style="padding-left: 10px">{{ "Edit" | translate }}</span>
      </span>
    </div>
  </div>

  <div *ngIf="!editMode">
    <!-- <br /><br /> -->
    <mat-chip-grid *ngIf="this.attachmentDetails.tag">
      <mat-chip>
        {{
          this.attachmentDetails.tag.tag[currentLanguage]
            ? this.attachmentDetails.tag.tag[currentLanguage]
            : ""
        }}
      </mat-chip>
    </mat-chip-grid>
    <div style="margin: 2% 10% 10% 10%; text-align: left">
      <span class="content">
        {{
          this.attachmentDetails.description
            ? this.attachmentDetails.description
            : ""
        }}
      </span>
    </div>
  </div>
  <div *ngIf="editMode">
    <form [formGroup]="attachmentForm">
      <div style="text-align: center">
        <mat-form-field class="tag-selection">
          <mat-label>{{ "Choose a tags" | translate }}</mat-label>
          <mat-select
            formControlName="tags"
            (selectionChange)="changeTags($event.value)"
          >
            <mat-option *ngFor="let t of tags" [value]="t">{{
              t.tag[currentLanguage]
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <!-- <br /> -->
      <mat-form-field appearance="outline" style="width: 100%">
        <textarea
          matInput
          rows="5"
          class="textArea"
          style="resize: none"
          formControlName="description"
        ></textarea>
      </mat-form-field>
    </form>
  </div>

  <div *ngIf="!editMode" class="attachdone-button-row">
    <button mat-raised-button (click)="closePopup()">
      {{ "Done" | translate }}
    </button>
  </div>
  <div *ngIf="editMode" class="attach-button-row">
    <button mat-raised-button (click)="updateAttachment()">
      {{ "Save" | translate }}
    </button>
  </div>
</div>
