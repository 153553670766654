<div class="container">
  <div
    style="
      height: 0px;
      float: right;
      position: absolute;
      right: 150px;
      bottom: 320px;
    "
    *ngIf="startVideoCall"
  >
    <app-video
      [consultId]="consultId"
      [consultType]="consultDetail?.appointmentType"
      [userData]="singPatientRecord"
    ></app-video>
  </div>
  <div
    style="
      height: 0px;
      float: right;
      position: absolute;
      right: 150px;
      bottom: 220px;
    "
    *ngIf="startAudioCall"
  >
    <app-audio
      [consultType]="consultDetail.appointmentType"
      [userData]="singPatientRecord"
    ></app-audio>
  </div>

  <div
    style="height: 0px; position: absolute; right: 150px; bottom: 420px"
    *ngIf="startChat"
  >
    <app-chat
      [consultType]="consultDetail.appointmentType"
      [userData]="singPatientRecord"
    ></app-chat>
  </div>
  <div *ngIf="!loading">
    <mat-grid-list cols="10" rowHeight="190px" [gutterSize]="'10px'">
      <mat-grid-tile [colspan]="2" [rowspan]="1" style="border-radius: 8px">
        <mat-card class="card">
          <img
            *ngIf="singPatientRecord.patient.profilePic; else defaultProfile"
            class="user-profile-img"
            [src]="this.fileUrl + singPatientRecord.patient.profilePic"
          />
          <ng-template #defaultProfile>
            <ngx-avatars
              class="user-profile"
              [name]="
                singPatientRecord.patient.firstName +
                ' ' +
                singPatientRecord.patient.lastName
              "
              size="100"
            ></ngx-avatars>
          </ng-template>
          <mat-card-title style="text-transform: capitalize; text-align: center;padding: 5px 0px;
          font-size: 18px;color: #46535b;font-weight: 700;">
            <span *ngIf="singPatientRecord"
              >{{
                singPatientRecord.patient.firstName +
                  " " +
                  singPatientRecord.patient.lastName
              }}
            </span>
          </mat-card-title>
          <mat-card-subtitle style="text-align: center;">
            <div *ngIf="singPatientRecord">
              <span
                >ID. {{ singPatientRecord.patient.identificationNumber }}</span
              >
            </div>
            <span *ngIf="singPatientRecord"
              >{{ "(" + countryCode + "-" + phoneNumber + ")" }}
            </span>
          </mat-card-subtitle>
          <mat-card-content> </mat-card-content>
        </mat-card>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="8" [rowspan]="1" style="border-radius: 8px">
        <mat-card class="card">
          <mat-card-content style="padding: 0px !important;">
            <div class="left-side">
              <br />
              <div class="container-flx">
                <div class="text-container" style="text-wrap: nowrap">
                  <h4 class="heading">{{ "Chief Complaint" | translate }} :</h4>
                  <span class="field-values" *ngIf="singPatientRecord">
                    <span
                      *ngIf="
                        singPatientRecord.consultation.chiefComplaint.other;
                        else actualComplaint
                      "
                      [tooltip]="
                        consultDetail?.specialtyTitle +
                        singPatientRecord.consultation.chiefComplaint.other
                      "
                      placement="top"
                      delay="500"
                      [showEnable]="
                        (
                          consultDetail?.specialtyTitle +
                          singPatientRecord.consultation.chiefComplaint.other
                        ).length > 47
                      "
                    >
                      <!--    {{getSpecialtyName(consultDetail)}} -->

                      {{
                        (
                          consultDetail?.specialtyTitle +
                          singPatientRecord.consultation.chiefComplaint.other
                        ).length > 47
                          ? (consultDetail?.specialtyTitle +
                              singPatientRecord.consultation.chiefComplaint
                                .other | slice: 0 : 47) + "..."
                          : consultDetail?.specialtyTitle +
                            singPatientRecord.consultation.chiefComplaint.other
                      }}
                    </span>
                    <ng-template #actualComplaint>
                      <span
                        [tooltip]="
                          consultDetail?.specialtyTitle +
                          singPatientRecord.consultation.chiefComplaint
                            .chiefComplaint[currentLanguage]
                        "
                        placement="top"
                        delay="500"
                        [showEnable]="
                          (
                            consultDetail?.specialtyTitle +
                            singPatientRecord.consultation.chiefComplaint
                              .chiefComplaint[currentLanguage]
                          ).length > 47
                        "
                      >
                        <!--  {{getSpecialtyName(consultDetail)}} -->
                        {{
                          (
                            consultDetail?.specialtyTitle +
                            singPatientRecord.consultation.chiefComplaint
                              .chiefComplaint[currentLanguage]
                          ).length > 47
                            ? (consultDetail?.specialtyTitle +
                                singPatientRecord.consultation.chiefComplaint
                                  .chiefComplaint[currentLanguage]
                                | slice: 0 : 47) + "..."
                            : consultDetail?.specialtyTitle +
                              singPatientRecord.consultation.chiefComplaint
                                .chiefComplaint[currentLanguage]
                        }}
                      </span>
                    </ng-template>
                  </span>
                </div>

                <div class="text-container-right">
                  <h4 class="heading-right">
                    {{ "Affiliation" | translate }} :
                  </h4>
                  <span
                    class="field-values-right"
                    *ngIf="singPatientRecord"
                    [tooltip]="
                      singPatientRecord.providerCall ||
                      singPatientRecord.patient?.subscription?.provider
                        ?.provider ||
                      singPatientRecord.patient?.parent?.subscription?.provider
                        ?.provider
                    "
                    placement="top"
                    delay="500"
                    [showEnable]="
                      singPatientRecord.providerCall?.length > 25 ||
                      singPatientRecord.patient?.subscription?.provider
                        ?.provider?.length > 25 ||
                      singPatientRecord.patient?.parent?.subscription?.provider
                        ?.provider?.length > 25
                    "
                  >
                    <span
                      *ngIf="
                        singPatientRecord.providerCall ||
                          singPatientRecord.patient?.subscription?.provider;
                        else noprovider
                      "
                    >
                      <ng-template
                        *ngIf="
                          singPatientRecord.providerCall;
                          else patientProvider
                        "
                      >
                        {{
                          singPatientRecord.providerCall?.length > 25
                            ? (singPatientRecord.providerCall | slice: 0 : 22) +
                              "..."
                            : singPatientRecord.providerCall
                        }}
                      </ng-template>

                      <ng-template #patientProvider>
                        {{
                          singPatientRecord.patient.subscription.provider
                            .provider?.length > 25
                            ? (singPatientRecord.patient.subscription.provider
                                .provider | slice: 0 : 22) + "..."
                            : singPatientRecord.patient.subscription.provider
                                .provider
                        }}
                      </ng-template>
                    </span>
                    <ng-template #noprovider>
                      <span
                        *ngIf="
                          singPatientRecord.patient?.parent &&
                            singPatientRecord.patient.parent.subscription &&
                            singPatientRecord.patient.parent.subscription
                              .provider;
                          else noproviderson
                        "
                        [tooltip]="
                          singPatientRecord.patient?.parent?.subscription
                            ?.provider?.provider
                        "
                        placement="top"
                        delay="500"
                        [showEnable]="
                          singPatientRecord.patient?.parent?.subscription
                            ?.provider?.provider?.length > 25
                        "
                      >
                        {{
                          singPatientRecord.patient.parent.subscription.provider
                            .provider?.length > 25
                            ? (singPatientRecord.patient.parent.subscription
                                .provider.provider | slice: 0 : 22) + "..."
                            : singPatientRecord.patient.parent.subscription
                                .provider.provider
                        }}
                      </span>
                    </ng-template>
                    <ng-template #noproviderson> Sin membresía </ng-template>
                  </span>
                  <button
                    (click)="openModalWithAfilliations()"
                    class="see-more-button"
                  >
                    {{ "Show more" | translate }}
                  </button>
                </div>

                <div class="text-container">
                  <h4 class="heading">
                    {{ "Mode of Communication" | translate }} :
                  </h4>
                  <span
                    class="field-values"
                    *ngIf="
                      singPatientRecord &&
                      singPatientRecord.consultation.communicationMode == 2
                    "
                  >
                    {{ "Video Call" | translate }}
                  </span>
                  <span
                    class="field-values"
                    *ngIf="
                      singPatientRecord &&
                      singPatientRecord.consultation.communicationMode == 1
                    "
                  >
                    {{ "Phone Call" | translate }}
                  </span>
                  <span
                    class="field-values"
                    *ngIf="
                      singPatientRecord &&
                      singPatientRecord.consultation.communicationMode == 3
                    "
                  >
                    {{ "Chat" | translate }}
                  </span>
                </div>

                <div class="text-container-right">
                  <h4 class="heading-right">{{ "Email" | translate }} :</h4>

                  <span
                    class="field-values-right"
                    *ngIf="singPatientRecord"
                    [tooltip]="
                      singPatientRecord.patient.email ||
                      singPatientRecord.patient.parent.email
                    "
                    placement="top"
                    delay="500"
                    [showEnable]="
                      singPatientRecord.patient?.email?.length > 25 ||
                      singPatientRecord.patient?.parent?.email?.length > 25
                    "
                  >
                    <span
                      *ngIf="
                        singPatientRecord.patient.email;
                        else showOtherEmail
                      "
                    >
                      {{
                        singPatientRecord.patient.email.length > 25
                          ? (singPatientRecord.patient.email | slice: 0 : 22) +
                            "..."
                          : singPatientRecord.patient.email
                      }}
                    </span>
                    <ng-template #showOtherEmail>
                      <span *ngIf="singPatientRecord.patient.parent.email">
                        {{
                          singPatientRecord.patient.parent.email.length > 25
                            ? (singPatientRecord.patient.parent.email
                                | slice: 0 : 22) + "..."
                            : singPatientRecord.patient.parent.email
                        }}
                      </span>
                    </ng-template>
                  </span>
                </div>
              </div>
              <br />
              <div
                class="personal-info-container-headings"
                style="margin-top: -1.5%"
              >
                <div>
                  <h3 class="user-details-heading">
                    {{ "Height" | translate }}
                  </h3>
                  <div class="field-value">
                    <span
                      *ngIf="
                        singPatientRecord &&
                          singPatientRecord.medicalHistory.height;
                        else showNull
                      "
                    >
                      {{
                        singPatientRecord.medicalHistory.height / 100
                          | number: "1.2-2"
                      }}
                      m
                    </span>
                    <ng-template #showNull> - </ng-template>
                  </div>
                </div>
                <div>
                  <h3 class="user-details-heading-old">
                    {{ "Weight" | translate }}
                  </h3>
                  <div class="field-values">
                    <span
                      *ngIf="
                        singPatientRecord &&
                          singPatientRecord.medicalHistory.weight;
                        else showNullWeight
                      "
                    >
                      {{
                        singPatientRecord.medicalHistory.weight
                          | number: "1.1-1"
                      }}
                      Kg
                    </span>
                    <ng-template #showNullWeight> - </ng-template>
                  </div>
                </div>
                <div>
                  <h3 class="user-details-heading-old">
                    {{ "BMI" | translate }}
                  </h3>
                  <div class="field-values">
                    <span *ngIf="singPatientRecord && bmi; else nullBMI">
                      {{ displayBMI + " " + "kg/m²" }}
                    </span>
                    <ng-template #nullBMI> - </ng-template>
                  </div>
                </div>
                <div>
                  <h3 class="user-details-heading-old">
                    {{ "Age" | translate }}
                  </h3>
                  <div class="field-values">
                    {{ age }}
                  </div>
                </div>
                <div>
                  <h3 class="user-details-heading-old">
                    {{ "Gender" | translate }}
                  </h3>
                  <div class="field-values">
                    <span
                      *ngIf="
                        singPatientRecord &&
                        singPatientRecord.patient.gender == 1
                      "
                    >
                      {{ "Male" | translate }}
                    </span>
                    <span
                      *ngIf="
                        singPatientRecord &&
                        singPatientRecord.patient.gender == 2
                      "
                    >
                      {{ "Female" | translate }}
                    </span>
                    <span
                      *ngIf="
                        singPatientRecord &&
                        singPatientRecord.patient.gender == 3
                      "
                    >
                      {{ "Others" | translate }}
                    </span>
                  </div>
                </div>
                <div>
                  <h3 class="user-details-heading-old">
                    {{ "Date of birth" | translate }}
                  </h3>
                  <div class="field-values">
                    <span
                      *ngIf="
                        singPatientRecord &&
                        singPatientRecord.patient?.dateOfBirth
                      "
                    >
                      {{
                        singPatientRecord.patient.dateOfBirth
                          | date: "dd/MM/yyyy" : "+0:00"
                      }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="right-side">
              <div
                *ngIf="
                  consultDetail?.appointmentType == 3 &&
                  consultDetail.status !== 5
                "
              >
                <div
                  class="call-options specialty-button-container"
                  [class.full-width]="saveExit"
                >
                  <div
                    *ngIf="!saveExit"
                    class="goHome"
                    (click)="routeToBackState()"
                  >
                    Volver al home
                  </div>
                  <button
                    *ngIf="connectPatient || connectToPatientEM"
                    mat-raised-button
                    [class.blurconnectTodisabled]="connectTodisabled"
                    [disabled]="connectTodisabled"
                    (click)="
                      connectToPatient(
                        singPatientRecord.consultation.communicationMode
                      )
                    "
                    class="large-button-blue"
                    style="height: 43px !important; font-size: 16px !important; width: 220px !important;"
                  >
                    {{ "Connect to Patient" | translate }}
                  </button>
                  <button
                    *ngIf="!saveExit && consultDetail.appointmentStatus !== 5"
                    (click)="rescheduled(consultDetail._id)"
                    [disabled]="connectTodisabled"
                    class="just-save reschedule"
                  >
                    Reagendar
                  </button>
                  <button
                    *ngIf="saveExit"
                    [class.blurSave]="disabled"
                    [disabled]="disabled"
                    mat-raised-button
                    (click)="submitVisitSummary()"
                    style="height: 43px !important; font-size: 17px !important; width: 220px !important;"
                    class="save-exit large-button-blue"
                  >
                    {{ "Save & Exit" | translate }}
                  </button>
                  <div
                    *ngIf="!saveExit && consultDetail.appointmentStatus === 5"
                    class="reschedule-status"
                  >
                    Estado de cita: Por reagendar
                  </div>
                </div>
              </div>
              <div *ngIf="consultDetail?.appointmentType !== 3">
                <div
                  class="call-options"
                  [class.full-width]="
                    saveExit && consultDetail?.appointmentType === 2
                  "
                >
                  <div
                    class="triage-options"
                    *ngIf="saveExit && consultDetail?.appointmentType === 2"
                  >
                    <button
                      (click)="setTransfer(true)"
                      [disabled]="disabled"
                      class="transfer"
                    >
                      Transferir al agente
                    </button>
                    <button
                      (click)="setTransfer(false)"
                      [disabled]="disabled"
                      class="just-save"
                    >
                      Guardar y salir
                    </button>
                  </div>
                  <button
                    *ngIf="connectPatient"
                    mat-raised-button
                    [class.blurconnectTodisabled]="connectTodisabled"
                    [disabled]="connectTodisabled"
                    (click)="
                      connectToPatient(
                        singPatientRecord.consultation.communicationMode
                      )
                    "
                    class="large-button-blue"
                    style="height: 43px !important; font-size: 16px !important; width: 220px !important;"
                  >
                    {{ "Connect to Patient" | translate }}
                  </button>
                  <button
                    *ngIf="saveExit && consultDetail?.appointmentType !== 2"
                    [class.blurSave]="disabled"
                    [disabled]="disabled"
                    mat-raised-button
                    (click)="submitVisitSummary()"
                    style="height: 43px !important; font-size: 17px !important; width: 220px !important;"
                    class="save-exit large-button-blue"
                  >
                    {{ "Save & Exit" | translate }}
                  </button>
                  <br />
                  <div
                    style="bottom: 10px; position: relative; text-align: center"
                    *ngIf="saveExit"
                  >
                    <div
                      class="create-follow-up"
                      *ngIf="
                        canCreateFollowUp &&
                        consultDetail?.appointmentType !== 3
                      "
                    >
                      <div
                        class="schedule-follow-up"
                        (click)="openScheduleFollowUpConsultation()"
                      >
                        <mat-icon> add_call </mat-icon>
                        <div class="heading">
                          {{ "Schedule follow-up" | translate }}
                        </div>
                      </div>

                      <div class="follow-up-date">
                        <div class="heading">
                          {{ "Follow up date" | translate }}
                        </div>

                        <span *ngIf="!followCallingDate">{{
                          "Not scheduled" | translate
                        }}</span>
                        <span *ngIf="followCallingDate">{{
                          followCallingDate | date: "dd/MM/yyyy"
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div
                    *ngIf="connectPatient"
                    class="return-to-wait-room"
                    (click)="openDialog()"
                  >
                    {{ "Return to waiting room" | translate }}
                  </div>
                </div>
              </div>
              <div
                *ngIf="
                  consultComplete ||
                  (consultDetail?.appointmentType == 3 &&
                    consultDetail.status === 5)
                "
                style="margin-top: 10%; text-align: center"
              >
                <p style="font-weight: 600; font-size: 18px; color: green">
                  {{ "Consultation completed" | translate }}
                </p>
                <div class="center">
                  <a
                    (click)="routeToBackState()"
                    style="color: #0033a1; cursor: pointer"
                  >
                    {{ "Back to Home" | translate }}
                  </a>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
  <br />

  <div class="flex-container" fxLayout="row" #componentContainer>
    <div fxFlex="19" *ngIf="!loading">
      <div class="scrolling">
        <ul>
          <li [ngClass]="{ inAction: currentSection == 'visitHistory' }">
            <a
              [ngClass]="{ active: currentSection == 'visitHistory' }"
              (click)="scrollTo('visitHistory')"
              class="scrollContent"
              >{{ "Visit History" | translate }}</a
            >
          </li>
          <br /><br />
          <li [ngClass]="{ inAction: currentSection == 'medicalHistory' }">
            <a
              [ngClass]="{ active: currentSection === 'medicalHistory' }"
              (click)="scrollTo('medicalHistory')"
              class="scrollContent"
              >{{ "Medical History" | translate }}</a
            >
          </li>
          <br /><br />
          <li [ngClass]="{ inAction: currentSection == 'attachments' }">
            <a
              [ngClass]="{ active: currentSection == 'attachments' }"
              (click)="scrollTo('attachments')"
              class="scrollContent"
              >{{ "Attachment" | translate }}</a
            >
          </li>
          <br /><br />
          <li [ngClass]="{ inAction: currentSection == 'medication' }">
            <a
              [ngClass]="{ active: currentSection == 'medication' }"
              (click)="scrollTo('medication')"
              class="scrollContent"
              >{{ "Medication" | translate }}</a
            >
          </li>
          <br /><br />
          <li [ngClass]="{ inAction: currentSection == 'visitSummary' }">
            <div *ngIf="saveExit">
              <!-- <div> -->
              <a
                [ngClass]="{ active: currentSection == 'visitSummary' }"
                (click)="scrollTo('visitSummary')"
                class="scrollContent"
                >{{ "Visit Summary" | translate }}</a
              >
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div
      fxFlex="81"
      class="comp-container"
      scrollSpy
      [spiedTags]="['DIV']"
      (sectionChange)="onSectionChange($event)"
    >
      <div id="visitHistory">
        <app-new-visit-history></app-new-visit-history>
      </div>
      <div id="medicalHistory">
        <br /><br />
        <app-medical-history [isEditable]="saveExit"></app-medical-history>
      </div>
      <div id="attachments">
        <br /><br />
        <app-attachments
          [canAdd]="saveExit || singPatientRecord?.visitHistory?.length > 0"
        ></app-attachments>
      </div>
      <div id="medication">
        <br /><br />
        <app-medication [isEditable]="saveExit"></app-medication>
      </div>
      <div id="visitSummary">
        <br /><br />
        <app-new-visit-summary
          [followCallingUp]="followCallingUp"
          [transfer]="transfer"
          [normalConsult]="normalConsult"
        ></app-new-visit-summary>
      </div>
    </div>
  </div>
  <div
    class="overlay-memberships-container"
    [ngClass]="
      showOverlayAffiliationsView ? 'overlay-memberships-container-display' : ''
    "
  >
    <div class="modal-container-memberships">
      <div class="modal-container-close-button">
        <button (click)="closeModalWithAfilliations()">
          <img src="../../../assets/images/close-cancel.svg" alt="" />
        </button>
      </div>
      <span class="modal-container-memberships-title">{{
        "Afiliaciones" | translate
      }}</span>
      <span class="modal-container-memberships-subtitle">{{
        "The patient is affiliated with the following entities:" | translate
      }}</span>

      <div style="height: 145px; overflow: auto">
        <div
          *ngFor="let membership of subscriptionsElements"
          class="modal-memberships-container"
        >
          <p>
            <span
              *ngIf="membership.status == 1"
              class="modal-container-span-active"
              >{{ "activa" | translate }}</span
            >
            <span
              *ngIf="membership.status == 2"
              class="modal-container-span-active"
              ><img src="../../../assets/images/membership-check.svg" alt=""
            /></span>
            <span class="modal-container-span-title">{{
              membership.name
            }}</span>
          </p>
        </div>
      </div>

      <div class="modal-container-button-parent">
        <button
          (click)="closeModalWithAfilliations()"
          class="modal-container-button"
        >
          Ok
        </button>
      </div>

      <!-- <span ngf></span> -->
    </div>
  </div>
</div>
