<div class="dialog-container">
  <mat-icon class="close-button" (click)="onClose()">close</mat-icon>

  <img class="main-icon" src="assets/images/alert-icon.svg" alt="" />
  <h5 class="heading" [innerHTML]="data.title"></h5>
  <div class="sucessBtn">
    <button
      *ngIf="data.cancelButton.show"
      mat-raised-button
      (click)="onCancel()"
      class="back-btn"
    >
      {{ data.cancelButton.text }}
    </button>
    <button
      *ngIf="data.acceptButton.show"
      mat-raised-button
      (click)="onAccept()"
      class="accept-btn"
    >
      {{ data.acceptButton.text }}
    </button>
  </div>
</div>
