import { Component, OnInit } from "@angular/core";
import { RegistrationService } from "src/app/registration/registration.service";
import { SharedService } from "../shared.service";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: "app-terms-condition",
  templateUrl: "./terms-condition.component.html",
  styleUrls: ["./terms-condition.component.scss"],
})
export class TermsConditionComponent implements OnInit {
  term: any;
  constructor(
    private doctorService: RegistrationService,
    private sharedService: SharedService,
  ) {
    this.getTermConditions();
  }

  ngOnInit() {}

  getTermConditions() {
    this.doctorService.getTermsCondition().subscribe(
      (res: any) => {
        this.term = res.data[0];
      },
      (error: HttpErrorResponse) => {
        this.handleError(error);
      },
    );
  }
  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = error.error.message;
      this.sharedService.showErrorMessage(errorMessage, "single");
    } else {
      // server-side error

      if (error.status == 422) {
        let values = [];
        for (let key in error.error.errors.messages) {
          values.push(error.error.errors.messages[key]);
        }
        this.sharedService.showMultipleErrors(values, "multi");
      } else {
        if (typeof error.error.errors == "object") {
          errorMessage = error.error.errors.messages[0];
        } else {
          errorMessage = error.error.errors;
        }
        this.sharedService.showErrorMessage(errorMessage, "single");
      }
    }
  }
}
