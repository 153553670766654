<div class="dialog-container" style="padding: 16px;">
  <div>Selecciona la hora</div>
  <div class="select-container">
    <mat-form-field>
      <mat-select [(ngModel)]="hourSelected">
        <mat-option
          *ngFor="let hour of hours"
          [value]="hour < 10 ? '0' + hour : hour.toString()"
        >
          {{ hour < 10 ? "0" + hour : hour }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div style="font-size: 24px">:</div>

    <mat-form-field>
      <mat-select [(ngModel)]="minuteSelected">
        <mat-option
          *ngFor="let minute of minutes"
          [value]="minute < 10 ? '0' + minute : minute.toString()"
        >
          {{ minute < 10 ? "0" + minute : minute }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-select [(ngModel)]="meridianSelected">
        <mat-option value="AM">AM</mat-option>
        <mat-option value="PM">PM</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div style="display: flex; justify-content: end; gap: 10px">
    <button class="button-cancel" (click)="onCancel()">Cancelar</button>

    <button class="button-ok" (click)="onAccept()">Guardar</button>
  </div>
</div>
