<div class="cus-chart-wrapper">
  <div class="noDataFound" *ngIf="showNoData">
  <!--   <img
      style="display: block; margin: auto; width: 41%"
      src="../../../assets/images/chart.svg"
    /> -->
    <span class="job"> {{ "No data available" | translate }} </span>
  </div>
 <!--  <span *ngIf="!showNoData" class="job">{{ this.totalPatient }} Patients </span> -->
  <div class="text-center" *ngIf="!showNoData">
    <canvas #patientDetails class="patient"></canvas>
  </div>
</div>
