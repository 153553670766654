import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Credentials, CredentialsService } from "./credentials.service";
import { map } from "rxjs/operators";
import { Router } from "@angular/router";

export interface LoginContext {
  userName: string;
  password: string;
  remember?: boolean;
}

/**
 * Provides a base for authentication workflow.
 *
 */
@Injectable({
  providedIn: "root",
})
export class SpecialtiesService {
  constructor(
    private credentialsService: CredentialsService,
    private http: HttpClient,
    private router: Router,
  ) {}

  /**
   * Authenticates the user.
   * @return The user credentials.
   */
  getSpecialties(provider: any): Observable<any> {
    return this.http.get<any>(`/providers/${provider}`).pipe(
      map((res) => {
        return { ...res };
      }),
    );
  }

  getDoctorBySpecialty(params: any): Observable<any> {
    return this.http.get<any>(`/doctors`, { params }).pipe(
      map((res) => {
        return { ...res };
      }),
    );
  }

  getConsultations(params: any): Observable<any> {
    return this.http.get<any>(`/consultations`, { params }).pipe(
      map((res) => {
        return { ...res };
      }),
    );
  }

  scheduleAppointment(consultationId: string, params: any): Observable<any> {
    return this.http
      .patch<any>(
        `/consultations/${consultationId}/schedule-appointment`,
        params,
      )
      .pipe(
        map((res) => {
          return { ...res };
        }),
      );
  }

  confirmAppointment(
    consultationId: string,
    appointmentStatus: any,
  ): Observable<any> {
    return this.http
      .patch<any>(
        `/consultations/${consultationId}/confirm-appointment`,
        appointmentStatus,
      )
      .pipe(
        map((res) => {
          return { ...res };
        }),
      );
  }

  rescheduleAppointment(consultationId: string, params: any): Observable<any> {
    return this.http
      .patch<any>(
        `/consultations/${consultationId}/reschedule-appointment`,
        params,
      )
      .pipe(
        map((res) => {
          return { ...res };
        }),
      );
  }

  cancelAppointment(consultationId: string, params: any): Observable<any> {
    return this.http
      .patch<any>(`/consultations/${consultationId}/cancel-appointment`, params)
      .pipe(
        map((res) => {
          return { ...res };
        }),
      );
  }

  /**
   * sending requeust for forgot password.
   */
  forgotPassword(data: string): Observable<Credentials> {
    return this.http.patch<any>("/doctors/forgot-password", data).pipe(
      map((res) => {
        return res;
      }),
    );
  }

  /**
   * Logs out the user and clear credentials.
   * @return True if the user was logged out successfully.
   */
  logout(): Observable<boolean> {
    // Customize credentials invalidation here
    return this.http.post<any>(`/${this.getRolePath()}/logout`, {}).pipe(
      map((res) => {
        this.credentialsService.setCredentials();
        return true;
      }),
    );
  }

  getRolePath() {
    const role = this.credentialsService.credentials.role;
    if (role === "agent") {
      return "agents";
    } else {
      return "doctors";
    }
  }
}
