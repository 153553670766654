import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { Subscription, Subject } from "rxjs";
import { PageEvent, MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
/* import {
  SatDatepickerInputEvent,
  SatDatepickerRangeValue,
} from "saturn-datepicker"; */
import moment from "moment";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { HttpErrorResponse } from "@angular/common/http";
import { CredentialsService } from "src/app/core/credentials.service";
import { DashboardService } from "../dashboard.service";
import { SharedService } from "src/app/shared/shared.service";

export class PageParams {
  status: number;
  search: string;
  sort: string;
  size: number;
  doctor: string;
  skip: number;
  includeFollowUp: boolean;
  startDate: string;
  endDate: string;
}
@Component({
  selector: "app-my-patient",
  templateUrl: "./my-patient.component.html",
  styleUrls: ["./my-patient.component.scss"],
})
export class MyPatientComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = [
    "FirstName",
    "LastName",
    "DOB",
    "Venezuelan ID",
    "Gender",
    "chiefComplaint",
    "date",
    "Call Type",
    "Status",
  ];

  pageIndex: 0;
  currentPage = 0;
  tableSearchQueryString: null;
  tableSearchQuery = new Subject<string>();
  loginDoctor;
  dataSource: MatTableDataSource<[]>;
  pageParams: Partial<PageParams> = {
    size: 10,
    skip: 0,
    status: 5,
    includeFollowUp: true,
    doctor: this.credentialService.credentials.userDetails._id,
  };
  length: number;
  pageSize: number;
  pageSizeOptions: number[] = [2, 10, 15, 20];
  startingDate;
  endingDate;
  searchDate: boolean = false;
  filtervalue;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
    date: any;
  lastDateInput: any ;
  lastDateChange: any ;

    onDateInput = (e: any) =>
    (this.lastDateInput = e.value as any);
  onDateChange = (e: any) =>
    (this.lastDateChange = e.value as any);
  maxDate = new Date();
  // MatPaginator Output
  pageEvent: PageEvent;
  emptyData: boolean = false;
  currentLanguage: string;
  pipeLanguage: string;
  private readonly subscriptions: Subscription = new Subscription();

  constructor(
    private credentialService: CredentialsService,
    private dashboardService: DashboardService,
    private router: Router,
    private translateService: TranslateService,
    private sharedService: SharedService,
  ) {
    this.pageSize = this.pageParams.size;
    this.searchFilters();
  }

  ngOnInit() {
    this.pageParams.sort = "-updatedAt";
    this.PatientConsult();

    if (this.translateService.currentLang == "en-US") {
      this.currentLanguage = "en";
      this.pipeLanguage = this.translateService.currentLang;
    } else {
      this.currentLanguage = "es";
      this.pipeLanguage = "es-ES";
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  handlePaginator(e: any) {
    // Get current page index
    this.pageIndex = e.pageIndex;
    // Get page size
    this.pageSize = e.pageSize;
    this.pageParams.size = this.pageSize;
    this.currentPage = this.pageIndex;
    this.pageParams.skip = e.pageIndex * this.pageParams.size;
    this.PatientConsult();
  }

  PatientConsult() {
    this.dataSource = new MatTableDataSource([]);
    this.dataSource.sort = this.sort;

    this.subscriptions.add(
      this.dashboardService.getPatientConsults(this.pageParams).subscribe({
        next: (res: any) => {
          let response: any = res.data;
           if(this.credentialService.credentials.userDetails.doctorRole === 2){
            response = this.sortConsultByDate(response);
          }

          console.log(response);
          this.dataSource = response;
          this.length = res["meta"].total;
          if (res.data.length > 0) {
            this.emptyData = false;
          } else {
            this.emptyData = true;
          }
        },
        error: (error: HttpErrorResponse) => {
          this.handleError(error);
        },
      }),
    );
  }

  sortConsultByDate(consults: any) {
    return consults.sort((a, b) => {
      const dateA = a.date.split("T")[0];
      const dateB = a.date.split("T")[0];
      const momentA = moment(
        `${dateA} ${a.hour}`,
        "YYYY-MM-DD hh:mm:ss",
      ).valueOf();
      const momentB = moment(
        `${dateB} ${b.hour}`,
        "YYYY-MM-DD hh:mm:ss",
      ).valueOf();
      return momentA - momentB;
    });
  }

  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = error.error.message;
      this.sharedService.showErrorMessage(errorMessage, "single");
    } else {
      // server-side error

      if (error.status == 422) {
        let values = [];
        for (let key in error.error.errors.messages) {
          values.push(error.error.errors.messages[key]);
        }
        this.sharedService.showMultipleErrors(values, "multi");
      } else {
        if (typeof error.error.errors == "object") {
          errorMessage = error.error.errors.messages[0];
        } else {
          errorMessage = error.error.errors;
        }
        this.sharedService.showErrorMessage(errorMessage, "single");
      }
    }
  }

  clearSearch() {
    this.tableSearchQueryString = null;
    this.tableSearchQuery.next("");
    this.searchFilters();
  }

  // Search filters
  searchFilters() {
    this.subscriptions.add(
      this.tableSearchQuery
        .pipe(debounceTime(1000), distinctUntilChanged())
        .subscribe({
          next: (value) => {
            this.pageParams.search = value ? value : "";
            this.pageParams.skip = 0;
            this.paginator.pageIndex = 0;
            this.PatientConsult();
          },
        }),
    );
  }

  sortData(event) {
    if (event.direction == "asc") {
      this.pageParams.sort = event.active;
    } else if (event.direction == "desc") {
      this.pageParams.sort = "-" + event.active;
    } else {
      delete this.pageParams.sort;
    }
    this.pageParams.skip = 0;
    this.paginator.pageIndex = 0;
    this.PatientConsult();
  }

  getPatientDetails(data) {
    let id = data._id;
    this.router.navigate(["/consults"], {
      queryParams: { id: id, patient: data.patient._id },
    });
  }
  setStartDate(event) {
    console.log(event)
    this.startingDate = event;
  }

  getdateFilter(value) {
    console.log(value)
    this.endingDate = value;
    if (this.startingDate !== null && this.endingDate !== null) {
      /* this.startingDate = value.begin;
      this.endingDate = value.end; */
      this.pageParams.startDate = this.startingDate
        ? moment(this.startingDate).startOf("day").utc().format()
        : "";
      this.pageParams.endDate = this.endingDate
        ? moment(this.endingDate).endOf("day").utc().format()
        : "";
      this.searchDate = true;
      this.PatientConsult();
    } else {
         this.clearDateFilter();
    }
  }

    clearDateFilter() {
    this.date = { begin: null, end: null };
    this.startingDate = null;
    this.endingDate = null;
    this.searchDate = true;
    this.pageParams.startDate = "";
    this.pageParams.endDate = "";
    this.searchDate = false;
    this.PatientConsult();
  }
}
