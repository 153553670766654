export const CANCEL_APPOINTMENT_DIALOG = {
  title: "¿Estás seguro que deseas <br> cancelar la cita?",
  acceptButton: {
    text: "Si",
    show: true,
  },
  cancelButton: {
    text: "No",
    show: true,
  },
};

export const CONFIRM_APPOINTMENT_DIALOG = {
  title: "¿El paciente confirmó la cita?",
  acceptButton: {
    text: "Si",
    show: true,
  },
  cancelButton: {
    text: "No",
    show: true,
  },
};
export const LOGOUT_DIALOG = {
  title: "¡Recuerda no dejar <br> solicitudes pendientes!",
  acceptButton: {
    text: "Cerrar sesión",
    show: true,
  },
  cancelButton: {
    text: "Cancelar",
    show: true,
  },
};
