import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { DataStoreServiceService } from "../data-store-service.service";
import { MedicationService } from "./medication.service";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { ModalService } from "../modal.service";
import { CredentialsService } from "src/app/core/credentials.service";
import { TranslateService } from "@ngx-translate/core";
import { SharedService } from "src/app/shared/shared.service";
import { HttpErrorResponse } from "@angular/common/http";
import { C } from "@angular/cdk/keycodes";

@Component({
  selector: "app-medication",
  templateUrl: "./medication.component.html",
  styleUrls: ["./medication.component.scss"],
})
export class MedicationComponent implements OnInit, OnDestroy, OnChanges {
  @Input() isEditable: boolean;
  consultDetails: any;
  prescriptionDetails: any;
  populatedAray: any;
  loading: boolean = true;
  patientId;
  disableButton: boolean = true;
  callFeedbackSubscription: Subscription;
  vscallStatusSubscription: Subscription;
  currentUserId: string;
  currentLanguage: string;
  panels = [
    {
      title: "Currently Taking",
      medicine: [],
    },
    {
      title: "Not Taking",
      medicine: [],
    },
    {
      title: "Completed",
      medicine: [],
    },
  ];
  pipeLanguage: string;

  constructor(
    private store: DataStoreServiceService,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private credentialService: CredentialsService,
    private medicationService: MedicationService,
    private translateService: TranslateService,
    private sharedService: SharedService,
  ) {
    this.route.queryParams.subscribe((params) => {
      this.patientId = params.patient;
      this.getUpdatedList(this.patientId);
    });
  }

  ngOnChanges(sc: SimpleChanges) {
    if (sc.isEditable.currentValue) {
      this.disableButton = false;
    }
  }

  ngOnInit(): void {
    if (this.translateService.currentLang == "en-US") {
      this.currentLanguage = "en";
      this.pipeLanguage = this.translateService.currentLang;
    } else {
      this.currentLanguage = "es";
      this.pipeLanguage = "es-ES";
    }
    this.currentUserId = this.credentialService.credentials.userDetails._id;
    this.vscallStatusSubscription =
      this.modalService.vscallStatusObservable.subscribe((res) => {
        if (res.callStarted == true) {
          this.disableButton = false;
        }
      });
  }

  prepareData(panel) {
    for (var i = 0; i < panel.length; i++) {
      for (var j = 0; j < panel[i].medicine.length; j++) {
        panel[i].medicine[j].alternative.push(panel[i].medicine[j]);
        panel[i].medicine[j].selected = panel[i].medicine[j];
      }
    }

    return panel;
  }

  changeMedicine(event, title) {
    if (title == "Completed" && event.value.isAlternative == true) {
      this.medicationService.completed(event.value._id).subscribe(
        (res: any) => {},
        (error: HttpErrorResponse) => {
          this.handleError(error);
        },
      );
    } else if (
      title == "Currently Taking" &&
      event.value.isAlternative == true
    ) {
      this.medicationService.currentlyTaking(event.value._id).subscribe(
        (res: any) => {},
        (error: HttpErrorResponse) => {
          this.handleError(error);
        },
      );
    } else if (title == "Not Taking" && event.value.isAlternative == true) {
      this.medicationService.notTaking(event.value._id).subscribe(
        (res: any) => {},
        (error: HttpErrorResponse) => {
          this.handleError(error);
        },
      );
    }
  }

  changeToCurrentlyTaking(id, index) {
    this.medicationService.currentlyTaking(id).subscribe(
      (res: any) => {
        this.getUpdatedList(this.patientId);
        this.translateService
          .get(
            "Medication status  has been changed  to currentlytalking  Successfully",
          )
          .subscribe((text: string) => {
            this.sharedService.showMessage(text, "success");
          });
      },
      (error: HttpErrorResponse) => {
        this.handleError(error);
      },
    );
  }

  changeToCurrentlyNotTaking(id, index) {
    this.medicationService.notTaking(id).subscribe(
      (res: any) => {
        this.getUpdatedList(this.patientId);
        this.translateService
          .get(
            "Medication status has been changed  to currentlynottalking  Successfully",
          )
          .subscribe((text: string) => {
            this.sharedService.showMessage(text, "success");
          });
      },
      (error: HttpErrorResponse) => {
        this.handleError(error);
      },
    );
  }

  changeToCompleted(id, index) {
    this.medicationService.completed(id).subscribe(
      (res: any) => {
        this.getUpdatedList(this.patientId);
        this.translateService
          .get("Medication status has been changed  to completed  Successfully")
          .subscribe((text: string) => {
            this.sharedService.showMessage(text, "success");
          });
      },
      (error: HttpErrorResponse) => {
        this.handleError(error);
      },
    );
  }

  deleteMedicine(id, index) {
    this.medicationService.deleteMedicine(id).subscribe(
      (res: any) => {
        this.getUpdatedList(this.patientId);
        this.translateService
          .get("Medication status has been changed  to deleted Successfully")
          .subscribe((text: string) => {
            this.sharedService.showMessage(text, "success");
          });
      },
      (error: HttpErrorResponse) => {
        this.handleError(error);
      },
    );
  }
  clearPanelValues() {
    for (var z = 0; z < this.panels.length; z++) {
      this.panels[z].medicine = [];
    }
  }

  getUpdatedList(id) {
    this.clearPanelValues();
    this.medicationService.getPrescriptionGroups(id).subscribe(
      (res: any) => {
        for (var i = 0; i < res.data.length; i++) {
          if (res.data[i].status == 1) {
            this.panels[0].medicine = res.data[i].medicine;
          } else if (res.data[i].status == 2) {
            this.panels[1].medicine = res.data[i].medicine;
          } else if (res.data[i].status == 3) {
            this.panels[2].medicine = res.data[i].medicine;
          }
        }

        this.panels = this.prepareData(this.panels);
        console.log(res);
        this.loading = false;
      },
      (error: HttpErrorResponse) => {
        this.loading = false;
        this.handleError(error);
      },
    );
  }
  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = error.error.message;
      this.sharedService.showErrorMessage(errorMessage, "single");
    } else {
      // server-side error

      if (error.status == 422) {
        let values = [];
        for (let key in error.error.errors.messages) {
          values.push(error.error.errors.messages[key]);
        }
        this.sharedService.showMultipleErrors(values, "multi");
      } else {
        if (typeof error.error.errors == "object") {
          errorMessage = error.error.errors.messages[0];
        } else {
          errorMessage = error.error.errors;
        }
        this.sharedService.showErrorMessage(errorMessage, "single");
      }
    }
  }

  ngOnDestroy() {
    this.vscallStatusSubscription.unsubscribe();
  }
}
