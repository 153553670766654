import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  AfterViewChecked,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DashboardService } from "../dashboard.service";
import moment from "moment";
import { HttpErrorResponse } from "@angular/common/http";
import { CredentialsService } from "src/app/core/credentials.service";
import { SharedService } from "src/app/shared/shared.service";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "src/environments/environment";
import { SubscriptionDetails } from "src/app/core/subscriptions.spec";
import { Subscription } from "rxjs";
import { ModalService } from "../modal.service";

export class PageParams {
  patient: string;
  status: number;
  size: number;
  skip: number;
}
@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"],
})
export class SearchComponent implements OnInit, AfterViewChecked {
  @ViewChild("medicalhistory", { read: ElementRef })
  medicalHistoryElement: ElementRef;
  @ViewChild("attachments", { read: ElementRef })
  attachmentsElement: ElementRef;
  @ViewChild("medication", { read: ElementRef }) medicationElement: ElementRef;
  @ViewChild("visithistory", { read: ElementRef })
  visitHistoryElement: ElementRef;

  @ViewChild("componentContainer", { static: true })
  compContainerElement: ElementRef;
  public currentActive = 0;
  public visitSummaryOffset: Number = null;
  public medicalHistoryOffset: Number = null;
  public attachmentsOffset: Number = null;
  public medicationOffset: Number = null;
  public visitHistoryOffset: Number = null;
  public mainScrollOffset: number = null;
  pageParams: Partial<PageParams> = { size: 3, skip: 0 };
  visitHistoryDetails = [];
  patientId: string;
  patientDetials: any;
  medicalHistoryDetails: any;
  age: number;
  bmi: any;
  displayBMI: any;
  loading: boolean = true;
  activeState = "Medical History";
  fileUrl: string;
  countryCode: string;
  phoneNumber: string;
  currentSection: string = "visitHistory";

  subscriptionsSubscription: Subscription;
  subscriptionsElements: SubscriptionDetails[] = [];
  showOverlayAffiliationsView = false;

  otherChiefComplaintId: string = "";

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private credService: CredentialsService,
    private dashboardService: DashboardService,
    private translationService: TranslateService,
    private sharedService: SharedService,
    private modalService: ModalService,
  ) {
    this.route.queryParams.subscribe((params) => {
      this.patientId = params.patient;
      this.getPatientDetails(this.patientId);
      this.getConsultationList();
    });
  }

  ngOnInit(): void {
    this.fileUrl = this.credService.credentials.fileUrl + "/";

    this.dashboardService.getChiefComplaintList().subscribe((data) => {
      if (data) {
        const idx = data.findIndex((x) => x.isOther);
        if (idx !== 1) {
          this.otherChiefComplaintId = data[idx]._id;
        }
      }
    });

    const getSubscriptions$ = this.modalService.getSubscriptions(
      this.patientId,
    );
    getSubscriptions$.subscribe(
      (subscriptionElements: any) => {
        // this.isLoading = false;
        this.subscriptionsElements = subscriptionElements;
      },
      (error: HttpErrorResponse) => {
        // this.isLoading = false;
        this.handleError(error);
      },
    );
  }

  ngAfterViewChecked() {}

  setStateAsActive(link) {
    this.activeState = link.name;
  }

  scroll(el: HTMLElement, active: number) {
    el.scrollIntoView();
    this.currentActive = active;
  }

  divScroll(e) {
    this.mainScrollOffset = e.target.scrollTop;
    if (
      this.mainScrollOffset >= this.visitHistoryOffset?.valueOf() &&
      this.mainScrollOffset < this.medicalHistoryOffset?.valueOf()
    ) {
      this.currentActive = 1;
    } else if (
      this.mainScrollOffset >= this.medicalHistoryOffset?.valueOf() &&
      this.mainScrollOffset < this.attachmentsOffset?.valueOf()
    ) {
      this.currentActive = 2;
    } else if (
      this.mainScrollOffset >= this.attachmentsOffset?.valueOf() &&
      this.mainScrollOffset < this.medicationOffset?.valueOf()
    ) {
      this.currentActive = 3;
    } else if (this.mainScrollOffset >= this.medicationOffset?.valueOf()) {
      this.currentActive = 4;
    } else {
      this.currentActive = 0;
    }
  }

  getPatientDetails(patientId) {
    this.dashboardService.getPatientDetials(patientId).subscribe(
      (res: any) => {
        console.log(res);
        this.patientDetials = res;
        if (res.parent) {
          this.countryCode = res.parent.countryCode;
          this.phoneNumber = res.parent.phoneNumber;
        } else {
          this.countryCode = res.countryCode;
          this.phoneNumber = res.phoneNumber;
        }
        this.calculateAge(this.patientDetials.dateOfBirth);
        this.getMedicalDetails(this.patientId);
      },
      (error: HttpErrorResponse) => {
        this.handleError(error);
      },
    );
  }

  getConsultationList() {
    this.pageParams.patient = this.patientId;
    this.pageParams.status = 5;

    this.dashboardService.getConsultationList(this.pageParams).subscribe(
      (res: any) => {
        for (var i = 0; i < res.data.length; i++) {
          this.visitHistoryDetails.push(res.data[i]);
        }
      },
      (error: HttpErrorResponse) => {
        this.handleError(error);
      },
    );
  }

  getMedicalDetails(patientId) {
    this.dashboardService
      .getMedicalHistory({
        patient: patientId,
      })
      .subscribe(
        (res: any) => {
          this.medicalHistoryDetails = res;
          this.calculateBMI(
            this.medicalHistoryDetails.height,
            this.medicalHistoryDetails.weight,
          );
          this.loading = false;
        },

        (error: HttpErrorResponse) => {
          if (error.status == 404) {
            this.medicalHistoryDetails = [];
          } else {
            this.handleError(error);
          }
          this.loading = false;
        },
      );
  }

  calculateAge(dob: any) {
    this.age = moment().diff(dob, "years");
  }

  calculateBMI(height: number, weight: number) {
    if (weight > 0 && height > 0) {
      this.bmi = weight / (((height / 100) * height) / 100);
      this.displayBMI = this.bmi.toFixed(2);
    } else {
      this.bmi = null;
    }
  }

  scrollTo(section) {
    document.querySelector("#" + section).scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }

  onSectionChange(sectionId: string) {
    this.currentSection = sectionId;
  }

  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = error.error.message;
      this.sharedService.showErrorMessage(errorMessage, "single");
    } else {
      // server-side error

      if (error.status == 422) {
        let values = [];
        for (let key in error.error.errors.messages) {
          values.push(error.error.errors.messages[key]);
        }
        this.sharedService.showMultipleErrors(values, "multi");
      } else {
        if (typeof error.error.errors == "object") {
          errorMessage = error.error.errors.messages[0];
        } else {
          errorMessage = error.error.errors;
        }
        this.sharedService.showErrorMessage(errorMessage, "single");
      }
    }
  }

  get subscription(): string {
    return (
      this.patientDetials?.subscription?.provider?.id ??
      this.patientDetials?.parent?.subscription?.provider?.id ??
      "Sin membresía"
    );
  }

  get isTelecarrier(): boolean {
    return !!this.patientDetials?.subscription?.provider?.provider;
  }

  get providerId(): string {
    return this.subscription !== "Sin membresía"
      ? this.subscription
      : environment.organizationId;
  }

  get canCreateConsult(): boolean {
    const isFreeTrialUsed =
      this.patientDetials && this.patientDetials.isFreeTrialUsed;
    return !(
      this.subscription === "Sin membresía" &&
      !this.isTelecarrier &&
      isFreeTrialUsed
    );
  }

  createContult() {
    if (!this.canCreateConsult) {
      this.translationService
        .get("Cant create consult")
        .subscribe((text: string) => {
          this.sharedService.showErrorMessage(text, "single");
        });
      return;
    }

    const newConsultationBody = {
      parent: this.patientDetials.parent?._id ?? this.patientDetials._id,
      patient: this.patientDetials._id,
      chiefComplaint: this.otherChiefComplaintId,
      otherChiefComplaint: "Solicitar llamada",
      provider: this.providerId,
    };

    this.dashboardService
      .postConsultation(newConsultationBody)
      .subscribe((postResult) => {
        const bookConsultationBody = {
          chiefComplaint: null,
          otherChiefComplaint: null,
          communicationMode: 1,
          isPregnant: 3,
          document: [],
          latitude: "",
          longitude: "",
          provider: this.providerId,
          patient: this.patientDetials.parent?._id ?? this.patientDetials._id,
        };

        this.dashboardService
          .bookConsultation(postResult.data._id, bookConsultationBody)
          .subscribe((bookResult) => {
            this.sharedService.showMessage(
              "Consulta creada exitosamente",
              "success",
            );

            this.dashboardService
              .getConsultation(bookResult.data._id)
              .subscribe(
                (consultationResponse) => {
                  this.router.navigate(["/consults"], {
                    queryParams: {
                      id: bookResult.data._id,
                      patient: bookResult.data.patient,
                    },
                  });
                },
                (error) => {
                  this.sharedService.showErrorMessage(
                    error.error.errors,
                    "single",
                  );
                },
              );
          });
      });
  }

  openModalWithAfilliations() {
    this.showOverlayAffiliationsView = true;
  }

  closeModalWithAfilliations() {
    this.showOverlayAffiliationsView = false;
  }
}
