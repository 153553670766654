<div class="dialog-container">
  <mat-icon
    style="
      right: 20px;
      top: 15px;
      cursor: pointer;
      position: absolute;
      width: 32px;
      font-size: 32px;
      height: 32px;
      color: #2e3a59;
    "
    (click)="onClose()"
    >close</mat-icon
  >

  <img class="main-icon" src="assets/images/alert-icon.svg" alt="" />
  <h5 class="heading">
    {{ data.title }}
  </h5>
  <div mat-dialog-actions class="sucessBtn" [class.margin-left]="data.margin">
    <button  (click)="onCancel()" class="mat-raised-button back-btn">
      {{ data.cancelAction }}
    </button>
    <button (click)="onAccept()" class=" mat-raised-button accept-btn">
      {{ data.acceptAction }}
    </button>
  </div>
</div>
