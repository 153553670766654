<div class="container">
  <div *ngIf="!loading">
    <mat-grid-list cols="10" rowHeight="190px" [gutterSize]="'10px'">
      <mat-grid-tile [colspan]="2" [rowspan]="1" style="border-radius: 8px">
        <mat-card class="card">
          <img
            *ngIf="patientDetials.profilePic; else defaultProfile"
            mat-card-avatar
            [src]="this.fileUrl + patientDetials.profilePic"
          />
          <ng-template #defaultProfile>
            <ngx-avatars
              class="user-profile"
              [name]="patientDetials.firstName + ' ' + patientDetials.lastName"
              size="100"
            ></ngx-avatars>
          </ng-template>
          <mat-card-title style="text-transform: capitalize">
            <span *ngIf="patientDetials">{{
              patientDetials.firstName + " " + patientDetials.lastName
            }}</span>
          </mat-card-title>
          <mat-card-subtitle>
            <div *ngIf="patientDetials">
              <span>ID. {{ patientDetials.identificationNumber }}</span>
            </div>
            <span *ngIf="patientDetials"
              >{{ "(" + countryCode + "-" + phoneNumber + ")" }}
            </span>
          </mat-card-subtitle>
          <mat-card-content> </mat-card-content>
        </mat-card>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="8" [rowspan]="1" style="border-radius: 8px">
        <mat-card class="card">
          <mat-card-content>
            <div class="left-side">
              <br />
              <div class="container-flx">
                <div class="text-container">
                  <h4 class="heading">{{ "Chief Complaint" | translate }} :</h4>
                  <span class="field-values"> - </span>
                </div>
                <div class="text-container-right">
                  <h4 class="heading-right">
                    {{ "Affiliation" | translate }} :
                  </h4>
                  <span
                    class="field-values-right"
                    *ngIf="patientDetials"
                    [tooltip]="
                      patientDetials?.subscription?.provider?.provider ||
                      patientDetials?.parent?.subscription?.provider?.provider
                    "
                    placement="top"
                    delay="500"
                    [showEnable]="
                      patientDetials?.subscription?.provider?.provider?.length >
                        25 ||
                      patientDetials?.parent?.subscription?.provider?.provider
                        ?.length > 25
                    "
                  >
                    <span
                      *ngIf="
                        patientDetials?.subscription?.provider;
                        else noprovider
                      "
                    >
                      {{
                        patientDetials.subscription.provider.provider?.length >
                        25
                          ? (patientDetials.subscription.provider.provider
                              | slice: 0 : 22) + "..."
                          : patientDetials.subscription.provider.provider
                      }}
                    </span>
                    <ng-template #noprovider>
                      <span
                        *ngIf="
                          patientDetials?.parent?.subscription?.provider;
                          else noproviderson
                        "
                      >
                        {{
                          patientDetials.parent.subscription.provider.provider
                            ?.length > 25
                            ? (patientDetials.parent.subscription.provider
                                .provider | slice: 0 : 22) + "..."
                            : patientDetials.parent.subscription.provider
                                .provider
                        }}
                      </span>
                    </ng-template>
                    <ng-template #noproviderson> Sin membresía </ng-template>
                  </span>
                  <button
                    (click)="openModalWithAfilliations()"
                    class="see-more-button"
                  >
                    {{ "Show more" | translate }}
                  </button>
                </div>
                <div class="text-container">
                  <h4 class="heading">
                    {{ "Mode of Communication" | translate }} :
                  </h4>
                  <span class="field-values"> - </span>
                </div>

                <div class="text-container-right">
                  <h4 class="heading-right">{{ "Email" | translate }} :</h4>
                  <span
                    class="field-values-right"
                    *ngIf="patientDetials"
                    [tooltip]="
                      patientDetials.email || patientDetials.parent.email
                    "
                    placement="top"
                    delay="500"
                    [showEnable]="
                      patientDetials?.email?.length > 25 ||
                      patientDetials?.parent?.email?.length > 25
                    "
                  >
                    <span *ngIf="patientDetials?.email; else parentEmail">
                      {{
                        patientDetials.email.length > 25
                          ? (patientDetials.email | slice: 0 : 22) + "..."
                          : patientDetials.email
                      }}
                    </span>
                    <ng-template #parentEmail>
                      <span>
                        {{
                          patientDetials.parent.email.length > 25
                            ? (patientDetials.parent.email | slice: 0 : 22) +
                              "..."
                            : patientDetials.parent.email
                        }}</span
                      >
                    </ng-template>
                  </span>
                </div>
              </div>
              <div
                class="personal-info-container-headings"
                style="margin-top: 1%"
              >
                <div>
                  <h3 class="user-details-heading">
                    {{ "Height" | translate }}
                  </h3>
                  <div class="field-value">
                    <span *ngIf="medicalHistoryDetails.height; else showNull">
                      {{ medicalHistoryDetails.height / 100 | number: "1.2-2" }}
                      m
                    </span>
                    <ng-template #showNull> - </ng-template>
                  </div>
                </div>
                <div>
                  <h3 class="user-details-heading">
                    {{ "Weight" | translate }}
                  </h3>
                  <div class="field-values">
                    <span
                      *ngIf="medicalHistoryDetails.weight; else showNullWeight"
                    >
                      {{ medicalHistoryDetails.weight | number: "1.1-1" }} Kg
                    </span>
                    <ng-template #showNullWeight> - </ng-template>
                  </div>
                </div>
                <div>
                  <h3 class="user-details-heading-old">
                    {{ "BMI" | translate }}
                  </h3>
                  <div class="field-values">
                    <span *ngIf="patientDetials && bmi; else nullBMI">
                      {{ displayBMI + " " + "kg/m²" }}
                    </span>
                    <ng-template #nullBMI> - </ng-template>
                  </div>
                </div>
                <div>
                  <h3 class="user-details-heading-old">
                    {{ "Age" | translate }}
                  </h3>
                  <div class="field-values">
                    {{ age }}
                  </div>
                </div>
                <div>
                  <h3 class="user-details-heading-old">
                    {{ "Gender" | translate }}
                  </h3>
                  <div class="field-values">
                    <span *ngIf="patientDetials && patientDetials.gender == 1">
                      {{ "Male" | translate }}
                    </span>
                    <span *ngIf="patientDetials && patientDetials.gender == 2">
                      {{ "Female" | translate }}
                    </span>
                    <span *ngIf="patientDetials && patientDetials.gender == 3">
                      {{ "Others" | translate }}
                    </span>
                  </div>
                </div>
                <div>
                  <h3 class="user-details-heading-old">
                    {{ "Date of birth" | translate }}
                  </h3>
                  <div class="field-values">
                    <span *ngIf="patientDetials && patientDetials.dateOfBirth">
                      {{
                        patientDetials.dateOfBirth
                          | date: "dd/MM/yyyy" : "+0:00"
                      }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="right-side">
              <div style="margin-top: 10%; text-align: center">
                <div class="center" style="margin-bottom: 0.5rem">
                  <a routerLink="" style="color: #0033a1">
                    {{ "Back to Home" | translate }}
                  </a>
                </div>
                <button mat-raised-button (click)="createContult()">
                  <mat-icon style="font-size: 18px; margin-right: 3px"
                    >phone_forwarded</mat-icon
                  >
                  {{ "Create Consult" | translate }}
                </button>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
  <br />
  <div class="flex-container" fxLayout="row" #componentContainer>
    <div fxFlex="19" *ngIf="!loading">
      <div class="scrolling">
        <ul>
          <li [ngClass]="{ inAction: currentSection == 'visitHistory' }">
            <a
              [ngClass]="{ active: currentSection == 'visitHistory' }"
              (click)="scrollTo('visitHistory')"
              class="scrollContent"
              >{{ "Visit History" | translate }}</a
            >
          </li>
          <br /><br />
          <li [ngClass]="{ inAction: currentSection == 'medicalHistory' }">
            <a
              [ngClass]="{ active: currentSection === 'medicalHistory' }"
              (click)="scrollTo('medicalHistory')"
              class="scrollContent"
              >{{ "Medical History" | translate }}</a
            >
          </li>
          <br /><br />
          <li [ngClass]="{ inAction: currentSection == 'attachments' }">
            <a
              [ngClass]="{ active: currentSection == 'attachments' }"
              (click)="scrollTo('attachments')"
              class="scrollContent"
              >{{ "Attachment" | translate }}</a
            >
          </li>
          <br /><br />
          <li [ngClass]="{ inAction: currentSection == 'medication' }">
            <a
              [ngClass]="{ active: currentSection == 'medication' }"
              (click)="scrollTo('medication')"
              class="scrollContent"
              >{{ "Medication" | translate }}</a
            >
          </li>
          <br /><br />
        </ul>
      </div>
    </div>
    <div
      fxFlex="81"
      class="comp-container"
      scrollSpy
      [spiedTags]="['DIV']"
      (sectionChange)="onSectionChange($event)"
    >
      <div id="visitHistory">
        <app-new-visit-history></app-new-visit-history>
      </div>
      <div id="medicalHistory">
        <br /><br />
        <app-medical-history></app-medical-history>
      </div>
      <div id="attachments">
        <br /><br />
        <app-attachments
          [canAdd]="visitHistoryDetails.length > 0"
        ></app-attachments>
      </div>
      <div id="medication">
        <br /><br />
        <app-medication></app-medication>
      </div>
    </div>
  </div>
  <div
    class="overlay-memberships-container"
    [ngClass]="
      showOverlayAffiliationsView ? 'overlay-memberships-container-display' : ''
    "
  >
    <div class="modal-container-memberships">
      <div class="modal-container-close-button">
        <button (click)="closeModalWithAfilliations()">
          <img src="../../../assets/images/close-cancel.svg" alt="" />
        </button>
      </div>
      <span class="modal-container-memberships-title">{{
        "Afiliaciones" | translate
      }}</span>
      <span class="modal-container-memberships-subtitle">{{
        "The patient is affiliated with the following entities:" | translate
      }}</span>

      <div style="height: 145px; overflow: auto">
        <div
          *ngFor="let membership of subscriptionsElements"
          class="modal-memberships-container"
        >
          <p>
            <span
              *ngIf="membership.status == 1"
              class="modal-container-span-active"
              >{{ "activa" | translate }}</span
            >
            <span
              *ngIf="membership.status == 2"
              class="modal-container-span-active"
              ><img src="../../../assets/images/membership-check.svg" alt=""
            /></span>
            <span class="modal-container-span-title">{{
              membership.name
            }}</span>
          </p>
        </div>
      </div>

      <div class="modal-container-button-parent">
        <button
          (click)="closeModalWithAfilliations()"
          class="modal-container-button"
        >
          Ok
        </button>
      </div>

      <!-- <span ngf></span> -->
    </div>
  </div>
</div>
