import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { dataPrueba } from "../../../data-test";
import { Subscription, interval } from "rxjs";
import { CredentialsService } from "src/app/core/credentials.service";
import { ConnectionService } from "src/app/dashboard/connection.service";
import { SocketEvent, SocketEvents } from "src/app/dashboard/socketio.service";
import { DashboardService } from "src/app/dashboard/dashboard.service";
import { HttpErrorResponse } from "@angular/common/http";
import { SharedService } from "src/app/shared/shared.service";
import moment from "moment";
import { MatDialog } from "@angular/material/dialog";
import { NotificationDialogComponent } from "../../dialog/dialog.component";
import {
  trigger,
  transition,
  style,
  animate,
  query,
} from "@angular/animations";

const animations = {
  fade: {
    from: { opacity: 1 },
    to: { opacity: 0 },
  },
};

@Component({
  selector: "app-appointment-list",
  templateUrl: "./appointment-list.component.html",
  styleUrls: ["./appointment-list.component.scss"],
  animations: [
    trigger("listAnimation", [
      transition("* <=> *", [
        query(
          ":enter",
          [
            style({ opacity: 0, "background-color": "red !important" }),
            animate(
              "2s ease-out",
              style({ opacity: 1, background: "red !important" }),
            ),
          ],
          { optional: true },
        ),
      ]),
    ]),
  ],
})
export class AppointmentListComponent implements OnInit {
  addCount = 0;
  activeTab = 0;
  fileUrl: string | undefined;
  loading: boolean = true;
  scheduleList: any[] = [];
  scheduledList: any[] = [];
  rescheduleList: any[] = [];

  notifications: any[] = [];
  /*   {
      id:1,
      patient:'Juan Perez',
      reason:'Consulta de rutina',
    },
    {
      id:2,

      patient:'Maria Perez',
      reason:'Consulta de rutina',
    },
    {
      id:3,
      patient:'Juanito Perez',
      reason:'Consulta de rutina',
    },
    {
      id:4,
      patient:'Mariana Perez',
      reason:'Consulta de rutina',
    }, */

  appointmentList = dataPrueba.filter((item: any) => item.status === "nueva");
  confirmList = dataPrueba.filter(
    (item: any) =>
      item.status === "reagendada" ||
      item.status === "agendada" ||
      item.status === "cancelada",
  );
  reagentList = dataPrueba.filter(
    (item: any) => item.status === "por reagendar",
  );
  private readonly subscriptions: Subscription = new Subscription();
  constructor(
    private readonly dialog: MatDialog,
    private readonly router: Router,
    private connectionService: ConnectionService,
    private changeDetector: ChangeDetectorRef,
    private credentialService: CredentialsService,
    private readonly dashboardService: DashboardService,
    private readonly sharedService: SharedService,
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.connectionService.messages.subscribe({
        next: (message) => {
          console.log(message, " message socket");

          const operations: any = {
            [SocketEvents.AgentScheduleRoom]: (event: SocketEvent) => {
              this.insertConsultation(this.scheduleList, event, false, true);
              localStorage.setItem(
                "scheduleList",
                this.scheduleList.length.toString(),
              );
            },
            [SocketEvents.AgentScheduledRoom]: (event: SocketEvent) => {
              this.insertConsultation(this.scheduledList, event, true);
            },
            [SocketEvents.AgentRescheduleRoom]: (event: SocketEvent) => {
              this.insertConsultation(this.rescheduleList, event);
            },

            [SocketEvents.RemoveFromAgentScheduleRoom]: (
              event: SocketEvent,
            ) => {
              const idx = this.scheduleList.findIndex(
                (x) => x._id === event.data._id,
              );
              if (idx !== -1) {
                this.scheduleList.splice(idx, 1);
                localStorage.setItem(
                  "scheduleList",
                  this.scheduleList.length.toString(),
                );
              }
            },
            [SocketEvents.RemoveFromAgentScheduledRoom]: (
              event: SocketEvent,
            ) => {
              const idx = this.scheduledList.findIndex(
                (x) => x._id === event.data._id,
              );
              if (idx !== -1) {
                this.scheduledList.splice(idx, 1);
              }
            },
            [SocketEvents.RemoveFromAgentRescheduleRoom]: (
              event: SocketEvent,
            ) => {
              const idx = this.rescheduleList.findIndex(
                (x) => x._id === event.data._id,
              );
              if (idx !== -1) {
                this.rescheduleList.splice(idx, 1);
              }
            },
          };

          operations[message.operation]?.(message);
          this.loading = false;
        },
      }),
    );

    this.subscriptions.add(
      interval(1000).subscribe({
        next: () => {
          if (!this.changeDetector["destroyed"]) {
            this.changeDetector.detectChanges();
          }
        },
      }),
    );

    this.changeDetector.detectChanges();
    this.sendMessage();
    this.fileUrl = this.credentialService.credentials.fileUrl + "/";
  }
  sendMessage() {
    this.connectionService.sendMsg(SocketEvents.AgentScheduleRoom);
    this.connectionService.sendMsg(SocketEvents.AgentScheduledRoom);
    this.connectionService.sendMsg(SocketEvents.AgentRescheduleRoom);
  }

  addNotification(consult: any) {
    this.notifications.push(consult);

    const element = document.getElementById(
      `${consult._id}noti`,
    ) as HTMLElement;

    setTimeout(() => {
      this.removeWithoutClick(consult._id);
    }, 5000);
  }

  removeWithoutClick(id) {
    const element = document.getElementById(`${id}noti`) as HTMLElement;

    setTimeout(() => {
      this.notifications = this.notifications.filter((item) => item._id !== id);
    }, 500);

    element.animate([{ opacity: 1 }, { opacity: 0, display: "none" }], {
      duration: 500,
      iterations: 1,
      fill: "forwards",
    });
  }

  removeNoti(id) {
    const element = document.getElementById(`${id}noti`) as HTMLElement;

    setTimeout(() => {
      this.notifications = this.notifications.filter((item) => item._id !== id);
    }, 500);

    element.animate([{ opacity: 1 }, { opacity: 0, display: "none" }], {
      duration: 500,
      iterations: 1,
      fill: "forwards",
    });
    this.router.navigate([`agent/patient`], {
      queryParams: { patient: id, appointment: true },
    });
  }

  room(record) {
    this.subscriptions.add(
      this.dashboardService.getConsultation(record._id).subscribe({
        next: (res: any) => {
          this.router.navigate(["/consults"], {
            queryParams: { id: record._id, patient: record.patient._id },
          });
        },
        error: (error: HttpErrorResponse) => {
          this.handleError(error);
        },
      }),
    );
  }

  setActiveTab(tab: number) {
    this.activeTab = tab;
  }

  goDashboard() {
    this.router.navigate(["agent/dashboard"]);
  }
  gotoPatient() {
    this.router.navigate(["agent/patient"]);
  }

  private insertConsultation(
    consultationMap: any[],
    message: SocketEvent,
    onlyNextDay: boolean = false,
    notification: boolean = false,
  ): void {
    if (Array.isArray(message.data)) {
      message.data.forEach((consultation) => {
        const isInList = consultationMap.some(
          (x) => x._id === consultation._id,
        );
        if (!isInList) {
          if (onlyNextDay) {
            if (this.validateDateIsNextDay(consultation.date)) {
              consultationMap.push(consultation);
            }
          } else {
            consultationMap.push(consultation);
          }
        }
      });
    } else {
      const isInList = consultationMap.some((x) => x._id === message.data._id);
      if (!isInList) {
        if (onlyNextDay) {
          if (this.validateDateIsNextDay(message.data.date)) {
            consultationMap.push(message.data);
          }
        } else {
          if (notification) {
            this.addNotification(message.data);
          }
          consultationMap.push(message.data);
        }
      }
    }
  }

  validateDateIsNextDay(date: string): boolean {
    const tomorrow = moment().add(1, "days");
    const dates = moment.utc(date).add(4, "hour");
    return dates.isSame(tomorrow, "day");
  }

  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = error.error.message;
      this.sharedService.showErrorMessage(errorMessage, "single");
    } else {
      // server-side error

      if (error.status == 422) {
        let values = [];
        for (let key in error.error.errors.messages) {
          values.push(error.error.errors.messages[key]);
        }
        this.sharedService.showMultipleErrors(values, "multi");
      } else {
        if (typeof error.error.errors == "object") {
          errorMessage = error.error.errors.messages[0];
        } else {
          errorMessage = error.error.errors;
        }
        this.sharedService.showErrorMessage(errorMessage, "single");
      }
    }
  }
}
