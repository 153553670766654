<div class="reset-container">
  <app-header></app-header>
  <div class="container">
    <p *ngIf="showError" style="font-weight: bold; color: #ee2029">
      {{ errorMsg }}
    </p>
    <!-- <p class="subheading">Please enter your email below</p> -->

    <mat-card class="reset-card" *ngIf="!hideForm">
      <h4 class="heading">{{ "Set New Password" | translate }}</h4>

      <form
        class="example-form"
        [formGroup]="changepasswordForm"
        (ngSubmit)="changePasswordSubmit()"
      >
        <!--  <p style="text-align: initial; color: #46535b; font-weight: 600">
          {{
            "Please enter your new password and confirm password." | translate
          }}
        </p> -->

        <div style="text-align: left; margin-bottom: 42px; margin-left: -23px">
          <ul>
            <li>{{ "Use at least one letter." | translate }}</li>
            <li>{{ "Use at least one number." | translate }}</li>
            <li>
              {{
                "Have a length greater than or equal to 8 characters."
                  | translate
              }}
            </li>
          </ul>
        </div>
        <div style="margin-left: 20px" class="row">
          <div class="col">
            <span
              style="margin-right: 315px; margin-left: 1px; white-space: nowrap"
              >{{ "New Password" | translate }}</span
            >
            <mat-form-field
              appearance="outline"
              class="example-full-width my-form-field"
              style="width: 95%; float: left; margin-top: 1px"
            >
              <input
                [type]="passwordHide ? 'password' : 'text'"
                matInput
                formControlName="password"
              />
              <mat-icon matPrefix class="mi-icono">lock_outline</mat-icon>
              <!-- <div class="icon-container"> -->
              <mat-icon
                matSuffix
                (click)="passwordHide = !passwordHide"
                class="mi-iconodos"
                >{{ passwordHide ? "visibility_off" : "visibility" }}</mat-icon
              >
              <!-- <img src="../../assets/images/candado.png"  class="mi-icono" /> -->

              <!-- <img [src]="passwordHide ? '../../assets/images/ocultar.png' : '../../assets/images/mostrar.png'" class="mi-iconodos" (click)="passwordHide = !passwordHide" style="padding-left: 327px;width: 22px !important;
                height: 18.35px !important;" /> </div>-->

              <!--  <mat-icon matSuffix (click)="passwordHide = !passwordHide">{{
            passwordHide ? "visibility_off" : "visibility"
          }}</mat-icon>  -->
              <!-- <img src="../../assets/images/candado.png" (click)="passwordHide = !passwordHide"/> -->

              <mat-error
                *ngIf="changepasswordForm.get('password').hasError('required')"
              >
                {{ "Password is Required." | translate }}
              </mat-error>
              <mat-error
                *ngIf="
                  changepasswordForm.get('password').hasError('pattern') &&
                  !changepasswordForm.get('password').hasError('minlength') &&
                  !changepasswordForm.get('password').hasError('maxlength')
                "
              >
                {{
                  "Minimum 8 characters (1 numeric and 1 alphabet)" | translate
                }}
              </mat-error>
              <mat-error
                *ngIf="changepasswordForm.get('password').hasError('minlength')"
              >
                {{ "Password should be minimum of 8 characters." | translate }}
              </mat-error>
              <mat-error
                *ngIf="changepasswordForm.get('password').hasError('maxlength')"
              >
                {{ "Password should be maximum of 15 characters." | translate }}
              </mat-error>
            </mat-form-field>
            <br /><br />
            <span
              style="
                margin-right: 188px !important;
                white-space: nowrap;
                margin-left: -28px;
              "
              >{{ "Repeat password" | translate }}</span
            >
            <mat-form-field
              appearance="outline"
              class="example-full-width my-form-field"
              style="width: 95%; float: left; margin-left: 1px; margin-top: 1px"
            >
              <input
                [type]="confirmPasswordHide ? 'password' : 'text'"
                matInput
                formControlName="confirmPassword"
              />
              <mat-icon matPrefix class="mi-icono">lock_outline</mat-icon>
              <!--   <img src="../../assets/images/ocultar.png" />
          <mat-icon
            matSuffix
            (click)="confirmPasswordHide = !confirmPasswordHide"
            >{{
              confirmPasswordHide ? "visibility_off" : "visibility"
            }}</mat-icon
          > -->
              <mat-icon
                matSuffix
                (click)="confirmPasswordHide = !confirmPasswordHide"
                class="mi-iconodos"
                >{{
                  confirmPasswordHide ? "visibility_off" : "visibility"
                }}</mat-icon
              >
              <!-- <div  class="icon-container"> -->
              <!-- <img src="../../assets/images/candado.png"  class="mi-icono" /> -->

              <!-- <img [src]="confirmPasswordHide ? '../../assets/images/ocultar.png' : '../../assets/images/mostrar.png'"  class="mi-iconodos" (click)="confirmPasswordHide = !confirmPasswordHide" style="padding-left: 327px;width: 22px !important;
          height: 18.35px !important;" /> </div>-->
              <mat-error
                *ngIf="
                  changepasswordForm.get('confirmPassword').hasError('required')
                "
              >
                {{ "Password is Required." | translate }}
              </mat-error>
              <mat-error
                *ngIf="
                  changepasswordForm
                    .get('confirmPassword')
                    .hasError('mustMatch')
                "
              >
                {{ "Password and Confirm Password do not match." | translate }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <br />
        <div class="reset-button-row">
          <button [disabled]="loading" mat-raised-button>
            {{ "Save" | translate }}
          </button>
        </div>
      </form>
    </mat-card>
  </div>
</div>
