import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AuthenticationService } from "../core/authentication.service";
import { Router } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";
import { SharedService } from "../shared/shared.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"],
})
export class ForgotPasswordComponent implements OnInit {
  forgotpasswordForm: FormGroup;
  showSuccessMsg: boolean = false;
  showErrorMsg: boolean = false;
  errorMsg: any;
  loading: boolean = false;
  disableBtn: boolean = false;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthenticationService,
    private translationService: TranslateService,
    private sharedService: SharedService,
  ) {}

  ngOnInit() {
    let emailregex: RegExp =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    this.forgotpasswordForm = this.fb.group({
      email: ["", [Validators.required, Validators.pattern(emailregex)]],
    });
  }

  forgotPasswordSubmit() {
    if (this.forgotpasswordForm.invalid) {
      return;
    }
    this.loading = true;
    this.showErrorMsg = false;
    this.showSuccessMsg = false;
    this.authService.forgotPassword(this.forgotpasswordForm.value).subscribe(
      (res: any) => {
        this.loading = false;
        this.translationService
          .get("The reset password link has been sent to your registered mail.")
          .subscribe((text: string) => {
            this.sharedService.showMessage(text, "success");
          });
        this.disableBtn = false;
      },
      (error: HttpErrorResponse) => {
        this.handleError(error);
        this.loading = false;
        this.disableBtn = false;
      },
    );
  }

  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = error.error.message;
      this.sharedService.showErrorMessage(errorMessage, "single");
    } else {
      // server-side error
      if (error.status === 404) {
        this.translationService
          .get(
            "The user entered is not registered. Please contact the HolaDOC team",
          )
          .subscribe((text: string) => {
            this.sharedService.showErrorMessage(text, "single");
          });
      } else if (error.status == 422) {
        let values = [];
        for (let key in error.error.errors.messages) {
          values.push(error.error.errors.messages[key]);
        }
        this.sharedService.showMultipleErrors(values, "multi");
      } else {
        if (typeof error.error.errors == "object") {
          errorMessage = error.error.errors.messages[0];
        } else {
          errorMessage = error.error.errors;
        }
        this.sharedService.showErrorMessage(errorMessage, "single");
      }
    }
  }
}
