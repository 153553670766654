import { Component, OnInit, Input } from "@angular/core";
import { CallfeebackComponent } from "../callfeeback/callfeeback.component";
import { MatDialog } from "@angular/material/dialog";
import { ModalService } from "../modal.service";

@Component({
  selector: "app-audio",
  templateUrl: "./audio.component.html",
  styleUrls: ["./audio.component.scss"],
})
export class AudioComponent implements OnInit {
  @Input() userData: any;
  @Input() consultType: any;
  time: number = 0;
  interval;
  constructor(
    private dialog: MatDialog,
    private modalService: ModalService,
  ) {}

  ngOnInit(): void {
    this.startTimer();
    this.modalService.callStatus({
      callStarted: true,
    });
  }

  disConnect() {
    this.modalService.disconnectCallSubject.next({
      callStatus: "disconnected",
    });
    this.pauseTimer();
    this.callFeedbackPopup();
  }

  callFeedbackPopup() {
    const dialogRef = this.dialog.open(CallfeebackComponent, {
      width: "418px",
      disableClose: true,
      autoFocus: false,
      closeOnNavigation: true,
      data: this.consultType,
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  startTimer() {
    this.interval = setInterval(() => {
      if (this.time === 0) {
        this.time++;
      } else {
        this.time++;
      }
    }, 1000);
  }

  transform(value: number, args?: any) {
    var h = Math.floor(value / 3600);
    var m = Math.floor((value % 3600) / 60);
    var s = Math.floor((value % 3600) % 60);
    return (
      this.str_pad_left(h, "0", 2) +
      ":" +
      this.str_pad_left(m, "0", 2) +
      ":" +
      this.str_pad_left(s, "0", 2)
    );
  }

  str_pad_left(string, pad, length) {
    return (new Array(length + 1).join(pad) + string).slice(-length);
  }

  pauseTimer() {
    clearInterval(this.interval);
  }
}
