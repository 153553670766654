import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DashboardRoutingModule } from "./dashboard-routing.module";
import { DashboardComponent } from "./dashboard.component";
import { SharedModule } from "../shared/shared.module";
import { MaterialModule } from "../material.module";
import { QueuestatusComponent } from "./queuestatus/queuestatus.component";
import { Routes, RouterModule } from "@angular/router";
import { FlexLayoutModule } from "@angular/flex-layout";
import { ConsultsPageComponent } from "./consults-page/consults-page.component";
import { AvatarModule } from "ngx-avatars";
import { MedicationComponent } from "./medication/medication.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AttachmentsComponent } from "./attachments/attachments.component";
import { MedicalHistoryComponent } from "./medical-history/medical-history.component";
import { MainPageComponent } from "./main-page/main-page.component";
import { MatChipsModule } from "@angular/material/chips";
import { ReconnectinPopupComponent } from "./reconnectin-popup/reconnectin-popup.component";
import { ReturnWaitingRoomComponent } from "./return-waiting-room/return-waiting-room.component";
import { CallfeebackComponent } from "./callfeeback/callfeeback.component";
import { PostponePopupComponent } from "./postpone-popup/postpone-popup.component";
import { SucessPopupComponent } from "./sucess-popup/sucess-popup.component";
import { VideoComponent } from "./video/video.component";
import { ChatComponent } from "./chat/chat.component";
import { AudioComponent } from "./audio/audio.component";
import { NewVisitSummaryComponent } from "./new-visit-summary/new-visit-summary.component";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { NewVisitHistoryComponent } from "./new-visit-history/new-visit-history.component";
import { VisitHistoryFormComponent } from "./visit-history-form/visit-history-form.component";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { ViewAttachmentComponent } from "./view-attachment/view-attachment.component";
import { SearchComponent } from "./search/search.component";
import { AttachmentPopupComponent } from "./attachment-popup/attachment-popup.component";
import { TranslateModule } from "@ngx-translate/core";
import { MyPatientComponent } from "./my-patient/my-patient.component";
import { EditprofileComponent } from "./editprofile/editprofile.component";
/* import {
  MAT_DATE_LOCALE,
  SatDatepickerModule,
  SatNativeDateModule,
} from "saturn-datepicker"; */
import { BnNgIdleService } from "bn-ng-idle";
import { ScrollSpyDirective } from "./scroll-spy.directive";
//import { CallStateService } from "../shared/call-state/call-state.service";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TooltipDirective } from "../shared/tootip-directive/tooltip.directive";
//import { SignatureFieldComponent } from "../shared/signature-field/signature-page.component";
import { MatInputModule } from "@angular/material/input";
import { SignatureModule } from "../shared/signature-module/signature.module";
import { VisitHistoryFollowUpItemComponent } from "./visit-history-follow-up-item/visit-history-follow-up-item.component";
import { ScheduleFollowUpConsultationComponent } from "./schedule-follow-up-consultation/schedule-follow-up-consultation.component";
import { UploadFileComponent } from "./upload-file/upload-file.component";
import { ConfirmEmConsultationComponent } from "./confirm-em-consultation/confirm-em-consultation.component";
import { SpecialistComponent } from "./main-page/dashboard-specialist/specialist.component";
import { DoctorComponent } from "./main-page/dashboard-doctor/doctor.component";
import { QueuestatusEmComponent } from "./queuestatus-em/queuestatus.component";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatIconModule } from "@angular/material/icon";

@NgModule({
  declarations: [
    DashboardComponent,
    QueuestatusComponent,
    QueuestatusEmComponent,
    ConsultsPageComponent,
    MedicationComponent,
    AttachmentsComponent,
    MedicalHistoryComponent,
    MainPageComponent,
    ReturnWaitingRoomComponent,
    CallfeebackComponent,
    PostponePopupComponent,
    SucessPopupComponent,
    ReconnectinPopupComponent,
    VideoComponent,
    ChatComponent,
    AudioComponent,
    NewVisitSummaryComponent,
    NewVisitHistoryComponent,
    VisitHistoryFormComponent,
    ChangePasswordComponent,
    ViewAttachmentComponent,
    SearchComponent,
    AttachmentPopupComponent,
    MyPatientComponent,
    EditprofileComponent,
    ScrollSpyDirective,
    TooltipDirective,
    VisitHistoryFollowUpItemComponent,
    ScheduleFollowUpConsultationComponent,
    UploadFileComponent,
    ConfirmEmConsultationComponent,
    ///
    SpecialistComponent,
    DoctorComponent,
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    FormsModule,
    SharedModule,
    MaterialModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    AvatarModule,
    MatChipsModule,
    RouterModule,
    MatSnackBarModule,
    TranslateModule,
    //SatDatepickerModule,
    //SatNativeDateModule,
    
    MatTooltipModule,
    MatInputModule,
    SignatureModule,
    MatIconModule
  ],
  //entryComponents: [DashboardComponent],
  /*   providers: [BnNgIdleService, { provide: MAT_DATE_LOCALE, useValue: "es-ES" }], */
})
export class DashboardModule {}
