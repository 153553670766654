import { NgModule } from "@angular/core";
import { Routes, RouterModule, ExtraOptions } from "@angular/router";
import { DashboardComponent } from "./dashboard.component";
import { ConsultsPageComponent } from "./consults-page/consults-page.component";
//import { QueuestatusComponent } from "./queuestatus/queuestatus.component";
import { MainPageComponent } from "./main-page/main-page.component";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { SearchComponent } from "./search/search.component";
import { MyPatientComponent } from "./my-patient/my-patient.component";
import { EditprofileComponent } from "./editprofile/editprofile.component";
const routes: Routes = [
  {
    path: "",
    component: DashboardComponent,
    children: [
      {
        path: "",
        redirectTo: "/dashboard",
        pathMatch: "full",
      },
      {
        path: "dashboard",
        component: MainPageComponent,
      },
      {
        path: "consults",
        component: ConsultsPageComponent,
      },
      {
        path: "search",
        component: SearchComponent,
      },
      {
        path: "change-pwd",
        component: ChangePasswordComponent,
      },
      {
        path: "patient",
        component: MyPatientComponent,
      },
      {
        path: "editProfile",
        component: EditprofileComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule {}
