import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class MedicationService {
  constructor(private http: HttpClient) {}

  currentlyTaking(id: number) {
    return this.http.patch(`/medical-prescriptions/${id}/currently-taking`, {});
  }

  notTaking(id: number) {
    return this.http.patch(
      `/medical-prescriptions/${id}/currently-not-taking`,
      {},
    );
  }

  completed(id: number) {
    return this.http.patch(`/medical-prescriptions/${id}/completed`, {});
  }

  deleteMedicine(id: number) {
    return this.http.delete(`/medical-prescriptions/${id}`, {});
  }

  getPrescriptionGroups(id) {
    return this.http.get(`/medical-prescriptions/group`, {
      params: {
        patient: id,
      },
    });
  }
}
