import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Observable, Subject } from "rxjs";
import moment from "moment";
import type { RecordedVideoOutput } from "./video/video.model";

@Injectable({
  providedIn: "root",
})
export class DashboardService {
  public historyUpdateSubject = new Subject<any>();
  historyUpdateObservable = this.historyUpdateSubject.asObservable();

  constructor(private http: HttpClient) {}

  getDiagnosticList(search: string) {
    return this.http.get(`/cie11`, { params: { search } });
  }

  getNextConsultations(doctor: any) {
    const starDate = moment().add(1, "days").format("YYYY-MM-DD");
    const endDate = moment().add(8, "days").format("YYYY-MM-DD");
    return this.http.get<any>(
      `/consultations?&status[0]=0&status[1]=1&appointmentStatus[0]=3&appointmentStatus[1]=8&appointmentStatus[2]=9&appointmentType[0]=3&doctor=${doctor}&startDate=${starDate}&endDate=${endDate}&language=es`,
    );
  }

  getConsultation(id: number) {
    return this.http.patch(`/consultations/${id}/accept`, {});
  }

  getIllness() {
    return this.http.get(`/illnesses`).pipe(
      map((res: any) => {
        return res.data;
      }),
    );
  }

  getDisease() {
    return this.http.get(`/diseases`).pipe(
      map((res: any) => {
        return res.data;
      }),
    );
  }

  getbodySystem() {
    return this.http.get(`/body-systems`).pipe(
      map((res: any) => {
        return res.data;
      }),
    );
  }

  getChiefComplaintList() {
    return this.http.get(`/chief-complaints`).pipe(
      map((res: any) => {
        return res.data;
      }),
    );
  }

  getAttachmentsInfo(id) {
    return this.http.get(`/documents/${id}`);
  }

  getTags() {
    return this.http.get(`/tags`);
  }
  getUnits() {
    return this.http.get("/units");
  }
  getRoute() {
    return this.http.get("/route-of-adminstrations");
  }
  getTimes() {
    return this.http.get("/times");
  }

  getPatientDetails(id: number) {
    return this.http.get(`/consultations/${id}/detail`).pipe(
      map((res: any) => {
        return res.data;
      }),
    );
  }

  getConsults(param: object) {
    let params = new HttpParams();
    for (const key in param) {
      if (param.hasOwnProperty(key)) {
        params = params.set(key, param[key]);
      }
    }
    return this.http.get<any>(`/consultations`, { params: params });
  }

  getMedicalHistory(param) {
    let params = new HttpParams();
    for (const key in param) {
      if (param.hasOwnProperty(key)) {
        params = params.set(key, param[key]);
      }
    }
    return this.http.get(`/medical-histories`, { params: params }).pipe(
      map((res: any) => {
        return res.data;
      }),
    );
  }

  getPatientDetials(id) {
    return this.http.get(`/patients/${id}`).pipe(
      map((res: any) => {
        return res.data;
      }),
    );
  }

  editMedicalHistory(data: any) {
    return this.http.post(`/medical-histories`, data);
  }

  getwaitTime() {
    return this.http.get<any>(`/consultations/waiting-time`);
  }
  getAverageConsult() {
    return this.http.get<any>(`/consultations/consultation-time`);
  }
  getQueueStatus(param: object) {
    let params = new HttpParams();
    for (const key in param) {
      if (param.hasOwnProperty(key)) {
        params = params.set(key, param[key]);
      }
    }
    return this.http.get<any>(`/consultations/status-count`, {
      params: params,
    });
  }
  getupdateAttachement(id: number, data: object) {
    return this.http.put(`/documents/${id}`, data);
  }

  /**
   * Assign params with key value pare
   * @param params: any
   */
  private getHttpParams(params) {
    let httpParams = new HttpParams();
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const element = params[key];
        httpParams = httpParams.set(key, element);
      }
    }
    return httpParams;
  }

  getDocuments(param: object) {
    const httpParams = this.getHttpParams(param);
    return this.http.get(`/documents`, { params: httpParams });
  }
  returntowaitingRoom(id: number, data: object) {
    return this.http.patch(`/consultations/${id}/decline`, data);
  }
  postponeCallByDoctor(id: string) {
    return this.http.patch(`/consultations/${id}/decline`, {});
  }
  successCallByDoctor(id: string) {
    return this.http.patch(`/consultations/${id}/end`, {});
  }
  createVisitSummary(id: string, data: object) {
    return this.http.post(`/consultations/${id}/visit-summaries`, data);
  }

  updateVisitHistory(id: string, data: object) {
    return this.http.put(`/consultations/${id}/visit-summaries`, data);
  }
  startCallByDoctor(id: string, data: any) {
    return this.http.patch(`/consultations/${id}/start`, data);
  }
  uploadVideoCall$(recordedVideoOutput: RecordedVideoOutput): Observable<void> {
    const { consultId, file } = recordedVideoOutput;
    const formData = new FormData();
    formData.append("video", file, file.name);
    return this.http.patch<void>(
      `/consultations/${consultId}/upload-videocall`,
      formData,
    );
  }

  getConsultationList(param: object) {
    const httpParams = this.getHttpParams(param);
    return this.http.get(`/consultations`, { params: httpParams });
  }

  getConsultationDetails(id: string) {
    //const httpParams = this.getHttpParams(param);
    return this.http.get(`/consultations/${id}`, {});
  }

  removeAlternativeMedicine(id: string) {
    return this.http.delete(`/medical-prescriptions/${id}`);
  }

  removeMedicine(id: string) {
    return this.http.delete(`/medical-prescriptions/${id}`);
  }

  downloadMedicalPrescription(param: object) {
    const httpParams = this.getHttpParams(param);
    return this.http.get(`/medical-prescriptions/download-by-doctor`, {
      params: httpParams,
      /*   responseType: "blob", */
    });
  }

  removeParaClinicalPrescription(id: string) {
    return this.http.delete(`/paraclinical-prescriptions/${id}`);
  }
  getPatientConsults(param: object) {
    let params = new HttpParams();
    for (const key in param) {
      if (param.hasOwnProperty(key)) {
        params = params.set(key, param[key]);
      }
    }
    return this.http.get<any>(`/consultations`, { params: params });
  }

  sendInactiveNotification() {
    return this.http.post(`/doctors/inactive`, {});
  }

  postConsultation(body: any) {
    return this.http.post<any>(`/consultations/createByDoctor`, body, {});
  }

  bookConsultation(consultationId: number, body: any) {
    return this.http.patch<any>(
      `/consultations/${consultationId}/bookByDoctor`,
      body,
      {},
    );
  }

  followUpCount(params: any) {
    return this.http.get<any>(`/consultations/follow-up-count`, {
      params,
    });
  }

  upload(file: File) {
    const formData: any = new FormData();
    formData.append("file", file, file.name);

    const params = new HttpParams().set("Hide-Loader", "true");

    return this.http.post("/uploads", formData, {
      reportProgress: true,
      observe: "events",
      params,
    });
  }

  createDocument(body: any) {
    return this.http.post<any>(`/documents/create-by-doctor`, body, {});
  }

  getClosureReasons() {
    return this.http.get(`/closure-reasons`).pipe(
      map((res: any) => {
        return res.data;
      }),
    );
  }

  getAgentConsults(param: object) {
    let params = new HttpParams();
    for (const key in param) {
      if (param.hasOwnProperty(key)) {
        params = params.set(key, param[key]);
      }
    }
    return this.http.get<any>(`/consultations`, { params: params });
  }

  transferConsultToAgent(consultId: any) {
    return this.http.patch(`/consultations/${consultId}/transfer/agent`, {});
  }
}
