export const dataPrueba: any = [
  /// AGENDAR LIST
  {
    id: 3,
    identification: "221244233",
    email: "email3@gmail.com",
    firstName: "Con Doc",
    lastName: "No agendada",
    chiefComplaint: "Headache",
    affiliation: "Androvent",
    consultAmount: 10,
    doctor: "Dr. John Doe",
    status: "nueva",
    phoneNumber: "0414-1234567",
  },
  {
    id: 5,
    identification: "1234567890",
    email: "email5@gmail.com",
    firstName: "Jhonny Sin Doc",
    lastName: "Garcia",
    chiefComplaint: "Headache",
    affiliation: "Otra",
    consultAmount: 7,
    doctor: "",
    status: "nueva",
    phoneNumber: "0414-1234567",
  },

  /// CONFIRM LIST
  {
    id: 1,
    identification: "1234567890",
    email: "email@gmail.com",
    firstName: "Pablo",
    lastName: "Agendada",
    chiefComplaint: "Headache",
    affiliation: "Aetna",
    consultAmount: 10,
    doctor: "Dr. John Doe",
    phoneNumber: "0414-1234567",
    status: "agendada",
    appointment: [
      {
        status: "agendada",
        appointmentDate: "31 de Diciembre del 2024",
        doctor: "Ricardo Fernandez",
        date: "31/12/2024",
        specialist: "Cardiologo",
        hour: "10:00 AM",
        reagentIntents: 4,
      },
    ],
  },
  {
    id: 10,
    identification: "1234567890",
    email: "email@gmail.com",
    firstName: "Fabiana",
    lastName: "reagendada",
    chiefComplaint: "Headache",
    affiliation: "Androvent",
    consultAmount: 10,
    doctor: "Dr. John Doc",
    phoneNumber: "0414-1234567",
    status: "reagendada",
    appointment: [
      {
        status: "reagendada",
        appointmentDate: "31 de Diciembre del 2024",
        doctor: "John Doe Ricardo Fernandez",
        date: "31/12/2024",
        hour: "10:00 AM",
        specialist: "Cardiologo",

        reagentIntents: 4,
      },
    ],
  },
  {
    id: 2,
    identification: "3463423432",
    email: "email2@gmail.com",
    firstName: "Pablo",
    lastName: "Teran",
    chiefComplaint: "Headache",
    affiliation: "Androvent",
    consultAmount: 5,
    doctor: "",
    status: "reagendada",
    phoneNumber: "0414-1234567",
    appointment: [
      {
        status: "reagendada",
        appointmentDate: "30 de Septiembre del 2024",
        doctor: "John Doe",
        date: "31/12/2024",
        specialist: "Cardiologo",

        hour: "10:00 AM",
        reagentIntents: 4,
      },
    ],
  },

  {
    id: 20,
    identification: "3463423432",
    email: "email2@gmail.com",
    firstName: "Pablo Cancelada",
    lastName: "Teran",
    chiefComplaint: "Headache",
    affiliation: "Androvent",
    consultAmount: 5,
    doctor: "",
    status: "cancelada",
    phoneNumber: "0414-1234567",
    appointment: [
      {
        status: "cancelada",
        appointmentDate: "30 de Septiembre del 2024",
        doctor: "John Doe",
        date: "31/12/2024",
        specialist: "Cardiologo",

        hour: "10:00 AM",
        reagentIntents: 4,
      },
      {
        status: "cancelada",
        appointmentDate: "28 de Septiembre del 2024",
        doctor: "John Doe",
        date: "31/12/2025",
        specialist: "Cardiologo",

        hour: "10:00 AM",
        reagentIntents: 4,
      },
    ],
  },
  {
    id: 6,
    identification: "1234567890",
    email: "email6@gmail.com",
    firstName: "Sara No reagendar",
    lastName: "Sara",
    chiefComplaint: "Headache",
    affiliation: "Aetna",
    consultAmount: 9,
    doctor: "Dr. John Doe",
    status: "reagendada",
    phoneNumber: "0414-1234567",
    appointment: [
      {
        status: "reagendada",
        appointmentDate: "30 de Septiembre del 2024",
        doctor: "John Doe",
        date: "31/12/2024",
        specialist: "Cardiologo",

        hour: "10:00 AM",
        reagentIntents: 0,
      },
    ],
  },
  /// REAGENDAR LIST
  {
    id: 4,
    identification: "1234567890",
    email: "email4@gmail.com",
    firstName: "Jom",
    lastName: " Smith",
    chiefComplaint: "Headache",
    affiliation: "Plum",
    consultAmount: 1,
    doctor: "Dr. John Doe",
    phoneNumber: "0414-1234567",
    status: "por reagendar",
    appointment: [
      {
        status: "reagendada",
        appointmentDate: "30 de Septiembre del 2024",
        doctor: "John Doe",
        date: "31/12/2024",
        specialist: "Cardiologo",

        hour: "10:00 AM",
        reagentIntents: 4,
      },
    ],
  },
  {
    id: 7,
    identification: "1234567890",
    email: "email4@gmail.com",
    firstName: "Dan",
    lastName: "Gutierrez",
    chiefComplaint: "Headache",
    affiliation: "Androvent",
    consultAmount: 2,
    doctor: "Dr. John Dab",
    phoneNumber: "0414-1234567",
    status: "por reagendar",
    appointment: [
      {
        status: "agendada",
        appointmentDate: "30 de Septiembre del 2024",
        doctor: "John Doe",
        specialist: "Cardiologo",
        date: "31/12/2024",
        hour: "10:00 AM",
        reagentIntents: 4,
      },
    ],
  },
  {
    id: 8,
    identification: "1234567890",
    email: "email4@gmail.com",
    firstName: "Dan",
    lastName: "Dan",
    chiefComplaint: "Headache",
    affiliation: "Plum",
    consultAmount: 1,
    doctor: "Dr. John Doe",
    phoneNumber: "0414-1234567",
    status: "por reagendar",
    appointment: [
      {
        status: "confirmada",
        appointmentDate: "30 de Septiembre del 2024",
        doctor: "John Doe",
        specialist: "Cardiologo",
        date: "31/12/2024",
        hour: "10:00 AM",
        reagentIntents: 4,
      },
    ],
  },

  {
    id: 21,
    identification: "1234567890",
    email: "email4@gmail.com",
    firstName: "Dan Sin agendar",
    lastName: "Dan",
    chiefComplaint: "Headache",
    affiliation: "Plum",
    consultAmount: 1,
    doctor: "Dr. John Doe",
    phoneNumber: "0414-1234567",
    status: "por reagendar",
    appointment: [
      {
        status: "confirmada",
        appointmentDate: "30 de Septiembre del 2024",
        doctor: "John Doe",
        specialist: "Cardiologo",
        date: "31/12/2024",
        hour: "10:00 AM",
        reagentIntents: 0,
      },
    ],
  },
];
