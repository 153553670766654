import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { PostponePopupComponent } from "../postpone-popup/postpone-popup.component";
import { SucessPopupComponent } from "../sucess-popup/sucess-popup.component";
import { ReconnectinPopupComponent } from "../reconnectin-popup/reconnectin-popup.component";

@Component({
  selector: "app-callfeeback",
  templateUrl: "./callfeeback.component.html",
  styleUrls: ["./callfeeback.component.scss"],
})
export class CallfeebackComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit() {}
  postponePopup() {
    const dialogRef = this.dialog.open(PostponePopupComponent, {
      width: "330px",
      disableClose: true,
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }
  sucessPopup() {
    const dialogRef = this.dialog.open(SucessPopupComponent, {
      width: "330px",
      disableClose: true,
      autoFocus: false,
      // height:'165px'
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }
  reconnectingPopup() {
    const dialogRef = this.dialog.open(ReconnectinPopupComponent, {
      width: "550px",
      disableClose: true,
      autoFocus: false,
      // height:'165px'
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }
}
