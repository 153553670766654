import {
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
} from "@angular/common/http";
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { forkJoin, Subscription } from "rxjs";
import { LoaderService } from "src/app/loader.service";
import { SharedService } from "src/app/shared/shared.service";
import { DashboardService } from "../dashboard.service";

@Component({
  selector: "app-upload-file",
  templateUrl: "./upload-file.component.html",
  styleUrls: ["./upload-file.component.scss"],
})
export class UploadFileComponent implements OnInit {
  @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef;

  files: {
    file: File;
    progress: number;
    upload: Subscription;
    url: string;
    error: boolean;
  }[] = [];

  fileWithError: boolean = false;
  fileUploadWithError: boolean = false;

  dropzoneActive: boolean = false;

  ALLOWED_FILES = [
    "image/jpeg",
    "image/jpg",
    "image/png",
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];

  ALLOWED_EXTENSIONS = [
    ".jpeg",
    ".jpg",
    ".png",
    ".pdf",
    ".doc",
    ".docx",
    ".xls",
    ".xlsx",
  ];

  MAX_UPLOAD_SIZE = 5e6;

  isSaving: boolean = false;

  constructor(
    private readonly dashboardService: DashboardService,
    private readonly loaderService: LoaderService,
    private readonly sharedService: SharedService,
    private readonly dialogRef: MatDialogRef<UploadFileComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {}

  dropzoneState($event: boolean) {
    this.dropzoneActive = $event;
  }

  loadFilesAndCheckErrors(files: File[] | FileList) {
    this.fileWithError = false;

    for (let i = 0; i < files.length; i++) {
      if (this.isFileAllowed(files[i])) {
        this.files = [
          ...this.files,
          {
            file: files[i],
            progress: 0,
            upload: new Subscription(),
            url: "",
            error: false,
          },
        ];
      } else {
        this.fileWithError = true;
      }
    }
  }

  onFileDropped(fileList: FileList) {
    const files = fileList;
    this.loadFilesAndCheckErrors(files);
    this.uploadFiles();
  }

  isFileAllowed(file: File) {
    return (
      this.ALLOWED_FILES.includes(file.type) &&
      file.size <= this.MAX_UPLOAD_SIZE
    );
  }

  getFile(event: any) {
    const files = [...event.target.files];
    this.fileDropEl.nativeElement.value = "";
    this.loadFilesAndCheckErrors(files);
    this.uploadFiles();
  }

  removeFile(index: number) {
    if (index >= 0) {
      this.files[index].upload.unsubscribe();
      this.files = this.files.filter((_, i) => i !== index);
    }
  }

  getFileSizeWithUnit(file: File) {
    const { size } = file;
    const units = ["MB", "KB", "B"];
    const raisedTo = [6, 3, 0];

    function getSizeWithUnit(i = 0) {
      const result = size / Math.pow(10, raisedTo[i]);

      if (result <= 1) {
        return getSizeWithUnit(i + 1);
      } else {
        return `${result.toFixed(2)} ${units[i]}`;
      }
    }

    if (size === 0) {
      return `${size.toFixed(2)} ${units[units.length - 1]}`;
    }

    return getSizeWithUnit(0);
  }

  uploadFiles() {
    this.fileUploadWithError = false;

    this.files.map((file, index, arr) => {
      file.upload = this.dashboardService.upload(file.file).subscribe(
        (event: HttpEvent<any>) => {
          this.loaderService.isLoading.next(false);
          switch (event.type) {
            case HttpEventType.Sent:
              break;
            case HttpEventType.ResponseHeader:
              break;
            case HttpEventType.UploadProgress:
              const eventTotal = event.total ? event.total : 0;
              file.progress = Math.round((event.loaded / eventTotal) * 100);
              break;
            case HttpEventType.Response:
              file.progress = 100;
              file.url = event.body.data;
          }
        },
        () => {
          file.error = true;
          this.fileUploadWithError = true;
        },
      );
      return file;
    });
  }

  cancelFile(index: number) {
    this.removeFile(index);
  }

  canSave(): boolean {
    return this.files.some((file) => file.url);
  }

  save() {
    if (this.canSave()) {
      const requests = this.files
        .filter((file) => file.url)
        .map((file) => {
          const body = {
            parent:
              this.data.patientDetials.parent?._id ??
              this.data.patientDetials._id,
            patient: this.data.patientDetials._id,
            url: file.url,
            name: file.file.name,
          };
          return this.dashboardService.createDocument(body);
        });

      forkJoin(requests).subscribe(
        (res: any) => {
          this.dialogRef.close({
            event: "refreshList",
          });
        },
        (error: HttpErrorResponse) => {
          this.handleError(error);
        },
      );
    }
  }

  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = error.error.message;
      this.sharedService.showErrorMessage(errorMessage, "single");
    } else {
      // server-side error

      if (error.status == 422) {
        let values = [];
        for (let key in error.error.errors.messages) {
          values.push(error.error.errors.messages[key]);
        }
        this.sharedService.showMultipleErrors(values, "multi");
      } else {
        if (typeof error.error.errors == "object") {
          errorMessage = error.error.errors.messages[0];
        } else {
          errorMessage = error.error.errors;
        }
        this.sharedService.showErrorMessage(errorMessage, "single");
      }
    }
  }
}
