<div class="container" *ngIf="!loading">
  <h1 class="title">
    {{ "Visit History" | translate }}
  </h1>
  <mat-accordion>
    <div *ngFor="let visit of visitHistoryDetails; let i = index">
      <mat-expansion-panel
        (opened)="openVisit(i)"
        (closed)="closeVisit(i)"
        style="
          box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.07);
          background: #ffffff;
          border-radius: 6px;

          margin-bottom: 20px;
        "
      >
        <mat-expansion-panel-header
          [ngClass]="visit.hasFollowUp ? 'expansion-with-followup' : ''"
        >
          <mat-panel-title>
            <span
              class="chiefCompliant"
              *ngIf="visit.chiefComplaint.other; else actualComplaint"
            >
              {{ getConsultationType(visit) }}{{ visit.chiefComplaint.other }}
            </span>
            <ng-template #actualComplaint>
              <span class="chiefCompliant">
                {{ getConsultationType(visit)
                }}{{ visit.chiefComplaint.chiefComplaint[currentLanguage] }}
              </span>
            </ng-template>
            <div class="consultDate">
              {{ visit?.doctor?.gender === 1 ? "Dr" : "Dra" }}.
              {{ visit?.doctor?.firstName }} {{ visit?.doctor?.lastName }}
            </div>
            <div class="consultDate">
              {{
                visit.createdAt
                  | date: "MMMM d, yyyy" : "+0:00" : pipeLanguage
                  | titlecase
              }}
            </div>
            <div *ngIf="visit.hasFollowUp">
              <app-visit-history-follow-up-item
                *ngIf="!!visit.followUpDate"
                [doctorName]="getFollowUpDoctorName(visit, 1)"
                [hasNext]="!!visit.followUpDate2"
                date="{{
                  visit.followUpDate
                    | date: 'MMMM d, yyyy' : '+0:00' : pipeLanguage
                }}"
              ></app-visit-history-follow-up-item>

              <app-visit-history-follow-up-item
                *ngIf="!!visit.followUpDate2"
                [doctorName]="getFollowUpDoctorName(visit, 2)"
                [pos]="'2'"
                [hasNext]="!!visit.followUpDate3"
                date="{{
                  visit.followUpDate2
                    | date: 'MMMM d, yyyy' : '+0:00' : pipeLanguage
                }}"
              ></app-visit-history-follow-up-item>

              <app-visit-history-follow-up-item
                *ngIf="!!visit.followUpDate3"
                [doctorName]="getFollowUpDoctorName(visit, 3)"
                [pos]="'3'"
                date="{{
                  visit.followUpDate3
                    | date: 'MMMM d, yyyy' : '+0:00' : pipeLanguage
                }}"
              ></app-visit-history-follow-up-item>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-visit-history-form
          *ngIf="visit.opened"
          [visit]="visit"
        ></app-visit-history-form>
      </mat-expansion-panel>
    </div>

    <div style="float: right" *ngIf="!hideViewMore">
      <button mat-button class="viewMore" (click)="getNext()">
        {{ "View More" | translate }}
      </button>
    </div>
  </mat-accordion>
  <div *ngIf="visitHistoryDetails?.length == 0">
    <img
      style="display: block; margin: 0 auto"
      *ngIf="currentLanguage == 'en'"
      src="../../../assets/images/no-history-en.svg"
    />
    <img
      style="display: block; margin: 0 auto"
      *ngIf="currentLanguage == 'es'"
      src="../../../assets/images/no-history-es.svg"
    />
  </div>
</div>
