import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CredentialsService } from "src/app/core/credentials.service";
import { AuthenticationService } from "src/app/core/authentication.service";
import { MatDialog } from "@angular/material/dialog";
import { TermsConditionComponent } from "../../shared/terms-condition/terms-condition.component";
import { PrivacypolicyComponent } from "../../shared/privacypolicy/privacypolicy.component";
import { FormGroup, FormBuilder } from "@angular/forms";
import { Observable, of } from "rxjs";
import {
  debounceTime,
  tap,
  switchMap,
  filter,
  map,
  startWith,
  distinctUntilChanged,
} from "rxjs/operators";
import { SearchService } from "./search.service";
import { I18nService } from "src/app/core/i18n.service";
import { SocketioService } from "src/app/dashboard/socketio.service";
import { RegistrationService } from "src/app/registration/registration.service";
import { LOGOUT_DIALOG } from "../dialog/agent-dialog.config";
import { ConfirmDialogComponent } from "src/app/agent/components/confirm-dialog/confirm-dialog.component";

@Component({
  selector: "app-home-header",
  templateUrl: "./home-header.component.html",
  styleUrls: ["./home-header.component.scss"],
})
export class HomeHeaderComponent implements OnInit {
  userName: string;
  searchForm: FormGroup;
  searchResult = [];
  booksName = [];
  isLoading: boolean = false;
  profilePicPath: any;
  profilePic: any;
  selected: number = 1;
  fileUrl: string;
  role: string;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private credService: CredentialsService,
    private authService: AuthenticationService,
    private i18nService: I18nService,
    private socketIoservice: SocketioService,
    private fb: FormBuilder,
    private serachService: SearchService,
    private doctorService: RegistrationService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.userName =
      this.credService.credentials.userDetails.firstName +
      " " +
      this.credService.credentials.userDetails.lastName;
    this.role = this.credService.credentials.role;

    this.serachService.bookDetails.filter((name) => {
      this.booksName.push(name.name);
    });
    this.searchForm = this.fb.group({
      searchBar: "",
    });

    this.onChanges();
    this.getUserInfo();
  }

  getUserInfo() {
    const role = this.credService.credentials.role;
    if (role !== "agent") {
      this.doctorService.getDoctors().subscribe((res: any) => {
        this.fileUrl = res.meta.fileUrl;
        this.profilePicPath = res.data.profilePic;
        this.profilePic = this.fileUrl + "/" + this.profilePicPath;
      });
    }
  }
  changePassword() {
    this.router.navigate(["/change-pwd"]);
  }
  myPatient() {
    this.selected = 2;
    this.router.navigate(["patient"]);
  }

  myProfile() {
    this.router.navigate(["editProfile"]);
  }

  gotoDashboard() {
    this.selected = 1;
    this.router.navigate([""]);
  }

  dashboard() {
    this.router.navigate([""]);
  }
  privacy() {
    const dialogRef = this.dialog.open(PrivacypolicyComponent, {
      width: "752px",
      height: "450px",
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  termConditions() {
    const dialogRef = this.dialog.open(TermsConditionComponent, {
      width: "752px",
      height: "450px",
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  logOut() {
    if (this.role === "agent") {
      const scheduleCount = parseInt(localStorage.getItem("scheduleList"));
      if (scheduleCount && scheduleCount > 0) {
        console.log("BBBB");
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          width: "600px",
          autoFocus: false,
          data: LOGOUT_DIALOG,
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result === true) {
            this.authService.logout().subscribe((res: any) => {
              this.socketIoservice.disconnect();
              this.router.navigate(["/login"]).then(() => {
                window.location.reload();
              });
            });
          }
        });
      } else {
        this.authService.logout().subscribe((res: any) => {
          this.socketIoservice.disconnect();
          this.router.navigate(["/login"]).then(() => {
            window.location.reload();
          });
        });
      }
    } else {
      console.log("AHHH");
      this.authService.logout().subscribe((res: any) => {
        this.socketIoservice.disconnect();
        this.router.navigate(["/login"]).then(() => {
          window.location.reload();
        });
      });
    }
  }
  gotoLandingPage() {
    if (this.credService.isAuthenticated()) {
      this.router.navigate(["/"]);
    } else {
      this.router.navigate(["/login"]);
    }
  }

  onChanges() {
    this.isLoading = true;
    this.searchForm
      .get("searchBar")
      .valueChanges.pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe((data: any) => {
        if (data.length > 0) {
          this.serachService
            .searchingValue({
              search: data,
              includeChild: true,
            })
            .subscribe((res: any) => {
              this.isLoading = false;
              this.searchResult = res.data as Array<{}>;
              this.cdr.detectChanges();
            });
        } else {
          this.isLoading = true;
        }
      });
  }

  displayFn(value?: any): string | undefined {
    return value ? value.firstName + " " + value.lastName : undefined;
  }

  gotoPatientDetails(value) {
    if (this.role === "agent") {
      this.agentSearch(value);
    } else {
      this.router.navigate(["/search"], {
        queryParams: { patient: value._id },
      });
    }
  }

  agentSearch(value: any) {
    console.log(value, "AAAAAAAA");
    this.router.navigate([`agent/patient`], {
      queryParams: { patient: value._id, appointment: false },
    });
  }

  changeLanguage(lang: string) {
    this.i18nService.language = lang;
    window.location.reload();
  }
}
