import { Component, Inject } from "@angular/core";

import {
  MatDialogRef,
  MatDialog,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";

interface DialogData {
  title: string;
  acceptButton: {
    text: string;
    show: boolean;
  };
  cancelButton: {
    text: string;
    show: boolean;
  };
}

@Component({
  selector: "app-timepicker-dialog",
  templateUrl: "./timepicker-dialog.component.html",
  styleUrls: ["./timepicker-dialog.component.scss"],
})
export class TimepickerDialogComponent {
  hourSelected = "01";
  minuteSelected = "00";
  meridianSelected = "AM";

  hours = Array.from({ length: 12 }, (_, i) => i + 1);
  minutes = Array.from({ length: 60 }, (_, i) => i);

  constructor(
    public dialogRef: MatDialogRef<TimepickerDialogComponent>,

    @Inject(MAT_DIALOG_DATA) public data: string,
  ) {
    if (data) {
      this.formatHour();
    }
  }

  formatHour() {
    const onlyNumber = this.data.replace(/AM|PM|\s/g, "");
    const meridian = this.data.replace(/[^a-zA-Z]/g, "");
    this.hourSelected =
      onlyNumber.split(":")[0].length === 1
        ? "0" + onlyNumber.split(":")[0]
        : onlyNumber.split(":")[0];
    this.minuteSelected = onlyNumber.split(":")[1];
    this.meridianSelected = meridian;
  }

  onAccept() {
    const hour =
      this.hourSelected.length === 1
        ? `0${this.hourSelected}`
        : `${this.hourSelected}`;
    const minute =
      this.minuteSelected.length === 1
        ? `0${this.minuteSelected}`
        : `${this.minuteSelected}`;
    const time = `${hour}:${minute} ${this.meridianSelected}`;
    this.dialogRef.close(time);
  }

  onClose() {
    this.dialogRef.close();
  }

  onCancel() {
    this.dialogRef.close();
  }
}
