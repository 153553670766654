<div>
  <app-dashboard-specialist *ngIf="doctorRole === 2"></app-dashboard-specialist>
  <app-dashboard-doctor *ngIf="doctorRole === 1"></app-dashboard-doctor>
  <!--  <div class="container">
    <div class="flex-container" fxLayout="row" fxLayoutGap="50px">
      <div fxFlex="70">
        <div>
          <div fxLayout="row" fxLayoutGap="50px">
            <div style="width: 50%">
              <mat-card class="card">
                <h5 class="heading">{{ "Queue Status" | translate }}</h5>
                <app-queuestatus></app-queuestatus>
              </mat-card>
            </div>

            <div style="width: 50%">
              <mat-card class="card">
                <div class="averagetime">
                  <div>
                    <h5 class="heading">
                      <span>{{
                        "Average Consultation Length" | translate
                      }}</span>
                   
                    </h5>
                  </div>
                  <div style="display: flex; align-items: center">
                    <img src="../../../assets/images/times.svg" /><span
                      style="
                        text-align: center;
                        padding-left: 15px;
                        font-size: 1.125rem;
                      "
                      >{{ averageconsulttime }}</span
                    >
                  </div>
                </div>
                <div class="averagewait">
                  <div>
                    <h5 class="heading">
                      <span>{{ "Average Wait Time" | translate }}</span>
                    
                    </h5>
                  </div>
                  <div style="display: flex; align-items: center">
                    <img src="../../../assets/images/clock.svg" /><span
                      style="
                        text-align: center;
                        padding-left: 15px;
                        font-size: 1.125rem;
                      "
                      >{{ display }}</span
                    >
                  </div>
                </div>
              </mat-card>
            </div>
          </div>
        </div>
        <br /><br />
        <div *ngIf="inProcessConsult.length > 0">
          <br />
        </div>
        <div *ngIf="inProcessConsult.length > 0">
          <mat-card class="card">
            <h5 class="heading">{{ "In Process Consult" | translate }}</h5>
            <mat-list>
              <mat-list-item
                *ngFor="let consults of inProcessConsult"
                style="text-align: left"
              >
                <img
                  *ngIf="consults.patient.profilePic; else defaultInprocessPic"
                  matListAvatar
                  [src]="fileUrl + consults.patient.profilePic"
                />
                <ng-template #defaultInprocessPic>
                  <ngx-avatar
                    class="user-profile"
                    [name]="
                      consults.patient.firstName +
                      ' ' +
                      consults.patient.lastName
                    "
                    size="40"
                  ></ngx-avatar>
                </ng-template>
                <h3
                  matLine
                  style="
                    font-style: normal;
                    font-weight: 600;
                    font-size: 0.875rem;
                    color: #46535b;
                  "
                >
                  {{
                    consults.patient.firstName + " " + consults.patient.lastName
                  }}
                </h3>
                <p matLine>
                  <span
                    style="
                      font-style: normal;
                      font-weight: normal;
                      font-size: 0.813rem;
                      color: #666666;
                    "
                  >
                    <span
                      *ngIf="consults.chiefComplaint.other; else normalConsult"
                    >
                      {{ consults.chiefComplaint.other }}
                    </span>
                    <ng-template #normalConsult>
                      {{
                        consults.chiefComplaint.chiefComplaint[currentLanguage]
                      }}
                    </ng-template>
                  </span>
                </p>
                <button
                  mat-raised-button
                  style="float: right; padding-right: 40px"
                  (click)="resumeConsult(consults)"
                >
                  {{ "Resume Consult" | translate }}
                </button>
              </mat-list-item>
            </mat-list>
          </mat-card>
        </div>
        <br /><br />
        <div>
          <mat-card class="recent-card" style="height: 543px">
            <h5 class="heading">
              {{ "My recenly completed consults" | translate }}
            </h5>
            <mat-list>
              <mat-list-item
                *ngFor="let recent of recentConsult"
                style="text-align: left"
                class="completed-list"
                (click)="recentCompleteInfo(recent)"
              >
                <img
                  *ngIf="recent.patient.profilePic; else defaultCompletedPic"
                  matListAvatar
                  [src]="fileUrl + recent.patient.profilePic"
                />
                <ng-template #defaultCompletedPic>
                  <ngx-avatar
                    class="user-profile"
                    [name]="
                      recent.patient.firstName + ' ' + recent.patient.lastName
                    "
                    size="40"
                  ></ngx-avatar>
                </ng-template>
                <h3
                  matLine
                  style="
                    font-style: normal;
                    font-weight: 600;
                    font-size: 0.875rem;
                    color: #46535b;
                  "
                  [ngClass]="
                    recent.patient.profilePic
                      ? 'profile-pic-exist'
                      : 'no-profile-pic'
                  "
                >
                  {{ recent.patient.firstName + " " + recent.patient.lastName }}
                </h3>
                <p matLine>
                  <span
                    style="
                      font-style: normal;
                      font-weight: normal;
                      font-size: 0.813rem;
                      color: #666666;
                    "
                    [ngClass]="
                      recent.patient.profilePic
                        ? 'profile-pic-exist'
                        : 'no-profile-pic'
                    "
                  >
                    <span *ngIf="recent.chiefComplaint.other; else actual">
                      {{ recent.chiefComplaint.other }}
                    </span>
                    <ng-template #actual>
                      {{
                        recent.chiefComplaint.chiefComplaint[currentLanguage]
                      }}
                    </ng-template>
                  </span>
                </p>
              </mat-list-item>
            </mat-list>
            <div *ngIf="recentConsult.length == 0">
              <img
                class="img-nocontents"
                *ngIf="currentLanguage == 'en'"
                src="../../../assets/images/no-history-en.svg"
              />
              <img
                class="img-nocontents"
                *ngIf="currentLanguage == 'es'"
                src="../../../assets/images/no-history-es.svg"
              />
            </div>
          </mat-card>
        </div>
      </div>

      <div fxFlex="30" fxLayoutAlign="stretch">
        <mat-card class="waiting-card">
          <h5 class="heading" style="margin-left: 3%">
            {{ "Waiting Room" | translate }}
          </h5>
          <div>
            <mat-tab-group>
              <mat-tab label="Primary Calls">
                <ng-template matTabLabel>
                 
                  <span>{{ "Primary Calls" | translate }}</span>
                  <span class="text-span" *ngIf="messages.length > 0">{{
                    messages.length
                  }}</span>
                </ng-template>

                <div class="primary-call-container">
                  <mat-list>
                    <mat-list-item
                      class="waiting-list"
                      *ngFor="let message of messages"
                      style="text-align: left; position: relative"
                      (click)="waitingRoom(message)"
                    >
                      <div class="img-container">
                        <img
                          *ngIf="
                            message.patient.profilePic;
                            else defaultPicture
                          "
                          matListAvatar
                          [src]="fileUrl + message.patient.profilePic"
                        />
                        <ng-template #defaultPicture>
                          <ngx-avatar
                            class="user-profile"
                            [name]="
                              message.patient.firstName +
                              ' ' +
                              message.patient.lastName
                            "
                            size="40"
                          ></ngx-avatar>
                        </ng-template>
                        <div
                          message
                          *ngIf="isYounger(message)"
                          class="younger-icon"
                          matTooltip="Este paciente es menor de
                        edad"
                          matTooltipClass="custom-tooltips right"
                          matTooltipPosition="right"
                        >
                          <img
                            style="width: 70%; height: 70%"
                            src="../../../assets/images/younger.svg"
                          />
                        </div>
                      </div>

                      <div class="call-content-container">
                        <h3
                          matLine
                          style="
                            font-style: normal;
                            font-weight: 600;
                            font-size: 0.875rem;
                            color: #46535b;
                            text-transform: capitalize;
                          "
                        >
                          {{
                            message.patient.firstName +
                              " " +
                              message.patient.lastName
                          }}
                        </h3>
                        <p matLine>
                          <span
                            style="
                              font-style: normal;
                              font-weight: normal;
                              font-size: 0.813rem;
                              color: #666666;
                            "
                          >
                            <span
                              *ngIf="
                                message.chiefComplaint.other;
                                else msgConsult
                              "
                            >
                              {{ message.chiefComplaint.other }}{{message.appointmentType === 2 ? ' - Por triaje' : ''}}
                            </span>
                            <ng-template #msgConsult>
                              {{
                                message.chiefComplaint.chiefComplaint[
                                  currentLanguage
                                ]
                              }}{{message.appointmentType === 2 ? ' - Por triaje' : ''}}
                            </ng-template>
                            <img
                              *ngIf="message.communicationMode == 1"
                              src="../../../assets/images/call.svg"
                              class="call"
                            />
                            <img
                              *ngIf="message.communicationMode == 2"
                              src="../../../assets/images/video.svg"
                              class="call"
                            />
                            <img
                              *ngIf="message.communicationMode == 3"
                              src="../../../assets/images/msg.svg"
                              class="call"
                            />
                          </span>
                        </p>
                        <p
                          matLine
                          style="
                            font-style: normal;
                            font-weight: normal;
                            font-size: 0.75rem;
                            color: #666666;
                          "
                        >
                          <span *ngIf="secondsToHms(message) as result">
                            {{ "Wait Time" | translate }} {{ result }}
                          </span>
                        </p>
                        <div class="tooltip">
                          <p
                            matLine
                            style="
                              font-style: normal;
                              font-weight: normal;
                              font-size: 0.75rem;
                              color: #666666;
                            "
                          >
                            <span>
                              {{
                                message.providerCall
                                  ? message.providerCall.length > 25
                                    ? (message.providerCall | slice: 0:22) +
                                      "..."
                                    : message.providerCall
                                  : "Sin membresía"
                              }}
                            </span>
                            <span
                              style="
                                font-style: normal;
                                font-weight: 400;
                                font-size: 0.7rem;
                                color: #2751a5;
                              "
                              *ngIf="message.countSubscription > 0"
                            >
                              +{{ message.countSubscription }}
                            </span>
                          </p>
                          <span
                            class="tooltiptext"
                            *ngIf="message.countSubscription > 0"
                          >
                            {{ "Affiliations" | translate }}
                          </span>
                        </div>
                      </div>
                    </mat-list-item>
                    <mat-list-item style="height: 30px"></mat-list-item>
                  </mat-list>
                  <div *ngIf="messages.length == 0">
                    <img
                      class="img-nocontentssss"
                      src="../../../assets/images/family.png"
                    />
                  </div>
                </div>
              </mat-tab>
              <mat-tab label="Followup Calls">
                <ng-template matTabLabel>
                
                  <span>{{ "Followup Calls" | translate }}</span>
                  <span class="text-span" *ngIf="followUpList.length > 0">{{
                    followUpList.length
                  }}</span>
                </ng-template>
                <mat-list>
                  <mat-list-item
                    *ngFor="let follow of followUpList"
                    style="text-align: left"
                    (click)="followUpCall(follow)"
                  >
                    <div class="img-container">
                      <img
                        *ngIf="follow.patient.profilePic; else defaultFollowPic"
                        matListAvatar
                        [src]="fileUrl + follow.patient.profilePic"
                      />
                      <ng-template #defaultFollowPic>
                        <ngx-avatar
                          class="user-profile"
                          [name]="
                            follow.patient.firstName +
                            ' ' +
                            follow.patient.lastName
                          "
                          size="40"
                        ></ngx-avatar>
                      </ng-template>

                      <div
                        *ngIf="isYounger(follow)"
                        class="younger-icon"
                        matTooltip="Este paciente es menor de
                      edad"
                        matTooltipClass="custom-tooltips right"
                        matTooltipPosition="right"
                      >
                        <img
                          style="width: 70%; height: 70%"
                          src="../../../assets/images/younger.svg"
                        />
                      </div>
                    </div>
                    <div class="call-content-container">
                      <h3
                        matLine
                        style="
                          font-style: normal;
                          font-weight: 600;
                          font-size: 0.875rem;
                          color: #46535b;
                          text-transform: capitalize;
                        "
                      >
                        {{
                          follow.patient.firstName +
                            " " +
                            follow.patient.lastName
                        }}
                      </h3>
                      <p matLine>
                        <span
                          style="
                            font-style: normal;
                            font-weight: normal;
                            font-size: 0.813rem;
                            color: #666666;
                          "
                        >
                          {{
                            follow.chiefComplaint.chiefComplaint[
                              currentLanguage
                            ]
                          }}
                          <img
                            *ngIf="follow.communicationMode == 1"
                            src="../../../assets/images/call.svg"
                            class="call"
                          />
                          <img
                            *ngIf="follow.communicationMode == 2"
                            src="../../../assets/images/video.svg"
                            class="call"
                          />
                          <img
                            *ngIf="follow.communicationMode == 3"
                            src="../../../assets/images/msg.svg"
                            class="call"
                          />
                        </span>
                      </p>
                      <p
                        matLine
                        style="
                          font-style: normal;
                          font-weight: normal;
                          font-size: 0.75rem;
                          color: #666666;
                        "
                      >
                        <span>
                          {{
                            follow.providerCall
                              ? follow.providerCall.length > 25
                                ? (follow.providerCall | slice: 0:22) + "..."
                                : follow.providerCall
                              : "Sin membresía"
                          }}
                        </span>
                      </p>
                    </div>
                  </mat-list-item>
                </mat-list>
                <div *ngIf="followUpList.length == 0">
                  <img
                    style="display: block; margin: auto"
                    *ngIf="currentLanguage == 'en'"
                    src="../../../assets/images/no-history-en.svg"
                  />
                  <img
                    style="display: block; margin: auto"
                    *ngIf="currentLanguage == 'es'"
                    src="../../../assets/images/no-history-es.svg"
                  />
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
        </mat-card>
      </div>
    </div>
  </div> -->
</div>
