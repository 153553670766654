import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { finalize } from "rxjs/operators";
import { AuthenticationService } from "../core/authentication.service";
import passwordHash from "js-sha512";
import { HttpErrorResponse } from "@angular/common/http";
import { SharedService } from "../shared/shared.service";
import { TranslateService } from "@ngx-translate/core";
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit, OnDestroy {
  error: string | undefined;
  showError = false;
  loginForm: FormGroup;
  isLoading = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private authenticationService: AuthenticationService,
    private sharedService: SharedService,
    private translationService: TranslateService,
  ) {}

  ngOnInit() {
    let passwordregex = /^(?=.*\d)(?=.*[a-zA-Z]).{8,15}$/;
    let emailregex: RegExp =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.loginForm = this.fb.group({
      email: ["", [Validators.required, Validators.pattern(emailregex)]],
      password: [
        "",
        [
          Validators.required,
          Validators.pattern(passwordregex),
          Validators.minLength(8),
          Validators.maxLength(15),
        ],
      ],
      remember: [true],
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  ngOnDestroy() {}

  login() {
    if (this.loginForm.invalid) {
      return;
    }
    this.isLoading = true;
    this.showError = false;
    let hashedPassword = passwordHash.sha512(this.loginForm.value.password);
    const login$ = this.authenticationService.login(
      this.loginForm.value,
      hashedPassword,
    );
    login$
      .pipe(
        finalize(() => {
          this.loginForm.markAsPristine();
        }),
      )
      .subscribe(
        (credentials: any) => {
          if (credentials.meta.validate2FA) {
            this.router.navigateByUrl("/login-2FA");
          } else {
            this.isLoading = false;
            if (credentials.role === "agent") {
              this.router.navigate(["/agent"], { replaceUrl: true });
            } else {
              this.router.navigate(
                [this.route.snapshot.queryParams.redirect || "/"],
                { replaceUrl: true },
              );
            }
          }
        },

        (error: HttpErrorResponse) => {
          this.isLoading = false;
          this.handleError(error);
        },
      );
  }
  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = error.error.message;
      this.sharedService.showErrorMessage(errorMessage, "single");
    } else {
      // server-side error

      if (error.status === 404) {
        this.translationService
          .get(
            "The user entered is not registered. Please contact the HolaDOC team",
          )
          .subscribe((text: string) => {
            this.sharedService.showErrorMessage(text, "single");
          });
      } else if (error.status == 422) {
        let values = [];
        for (let key in error.error.errors.messages) {
          values.push(error.error.errors.messages[key]);
        }
        this.sharedService.showMultipleErrors(values, "multi");
      } else {
        if (typeof error.error.errors == "object") {
          errorMessage = error.error.errors.messages[0];
        } else {
          errorMessage = error.error.errors;
        }
        this.sharedService.showErrorMessage(errorMessage, "single");
      }
    }
  }
}
