<div class="background">
  <div class="left-bg">
    <img
      class="bottom-right-watermark"
      src="../../assets/images/holadoc-corazon.svg"
    />
    <div class="login-container">
      <div class="container">
        <mat-card class="login-card">
          <div class="login-2fa-container">
            <img src="../../assets/images/2FAIcon.svg" class="logo-2fa" />
            <p class="title-2fa">
              {{
                "We have detected that you are signing in from another device"
                  | translate
              }}
            </p>
            <p class="subtitle-label">
              {{ "Select an option to receive a login code." | translate }}
            </p>
            <form class="form" (ngSubmit)="send2FACode()">
              <mat-radio-group name="opciones" [(ngModel)]="methodSelected">
                <mat-radio-button
                  *ngFor="let op of methodsFor2FA"
                  name="opciones"
                  [value]="op"
                  style="margin: 10px"
                  color="primary"
                  [labelPosition]="'after'"
                >
                  {{ op | translate }}
                </mat-radio-button>
              </mat-radio-group>

              <div class="login-button-row">
                <button [disabled]="isLoading" mat-raised-button type="submit" class="large-button-blue medium-width">
                  {{ "Send code" | translate }}
                </button>
              </div>
            </form>
            <div class="login-back-row">
              <button (click)="doBack()">
                {{ "Back" | translate }}
              </button>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
</div>
