<div class="reset-container">
  <app-header></app-header>
  <div class="container">
    <h4 class="heading">{{ "Set New Password" | translate }}</h4>
    <p *ngIf="showError" style="font-weight: bold; color: #ee2029">
      {{ errorMsg }}
    </p>
    <!-- <p class="subheading">Please enter your email below</p> -->
    <mat-card class="reset-card" *ngIf="!hideForm">
      <form
        class="example-form"
        [formGroup]="changepasswordForm"
        (ngSubmit)="changePasswordSubmit()"
      >
        <p style="text-align: initial; color: #46535b; font-weight: 600">
          {{
            "Please enter your new password and confirm password." | translate
          }}
        </p>
        <mat-form-field
          class="example-full-width my-form-field"
          style="width: 95%; float: left"
        ><mat-label>{{ 'New Password' | translate }}</mat-label>
          <input
            [type]="passwordHide ? 'password' : 'text'"
            matInput
            placeholder=""
            formControlName="password"
          />
          <mat-icon matSuffix (click)="passwordHide = !passwordHide">{{
            passwordHide ? "visibility_off" : "visibility"
          }}</mat-icon>
          <mat-error
            *ngIf="changepasswordForm.get('password').hasError('required')"
          >
            {{ "Password is Required." | translate }}
          </mat-error>
          <mat-error
            *ngIf="
              changepasswordForm.get('password').hasError('pattern') &&
              !changepasswordForm.get('password').hasError('minlength') &&
              !changepasswordForm.get('password').hasError('maxlength')
            "
          >
            {{ "Minimum 8 characters (1 numeric and 1 alphabet)" | translate }}
          </mat-error>
          <mat-error
            *ngIf="changepasswordForm.get('password').hasError('minlength')"
          >
            {{ "Password should be minimum of 8 characters." | translate }}
          </mat-error>
          <mat-error
            *ngIf="changepasswordForm.get('password').hasError('maxlength')"
          >
            {{ "Password should be maximum of 15 characters." | translate }}
          </mat-error>
        </mat-form-field>
        <br /><br />
        <mat-form-field
          class="example-full-width my-form-field"
          style="width: 95%; float: left; margin-top: 1%"
        >
        <mat-label>{{ 'Confirm Password' | translate }}</mat-label>
          <input
            [type]="confirmPasswordHide ? 'password' : 'text'"
            matInput
            placeholder=""
            formControlName="confirmPassword"
          />
          <mat-icon
            matSuffix
            (click)="confirmPasswordHide = !confirmPasswordHide"
            >{{
              confirmPasswordHide ? "visibility_off" : "visibility"
            }}</mat-icon
          >
          <mat-error
            *ngIf="
              changepasswordForm.get('confirmPassword').hasError('required')
            "
          >
            {{ "Password is Required." | translate }}
          </mat-error>
          <mat-error
            *ngIf="
              changepasswordForm.get('confirmPassword').hasError('mustMatch')
            "
          >
            {{ "Password and confirm password do not match" | translate }}
          </mat-error>
        </mat-form-field>
        <br />
        <div class="reset-button-row">
          <button [disabled]="loading" mat-raised-button class="large-button-blue medium-width"> 
            {{ "Create" | translate }}
          </button>
        </div>
      </form>
    </mat-card>
  </div>
</div>
