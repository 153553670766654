<div class="container" id="upload-file-container">
  <h2 mat-dialog-title class="title" style="padding: 24px ;font-size:20px ;">
    {{ "Add file" | translate }}
    <mat-icon
      style="float: right; cursor: pointer"
      [mat-dialog-close]="{ event: 'close' }"
    >
      close
    </mat-icon>
  </h2>
  <div mat-dialog-content class="dialog-content">
    <div
      class="file-container"
      appDragAndDropFile
      (filesDropped)="onFileDropped($event)"
      (filesHovered)="dropzoneState($event)"
      [ngClass]="{ active: dropzoneActive }"
    >
      <input
        type="file"
        #fileDropRef
        id="fileDropRef"
        (change)="getFile($event)"
        class="file"
        [accept]="ALLOWED_EXTENSIONS.join(',')"
        multiple
      />
      <div class="content">
        <mat-icon color="primary" class="file-icon" aria-label="File">
          insert_drive_file
        </mat-icon>
        <p>
          <span class="drag-the-file-text">{{
            "Drag the file you want to upload or" | translate
          }}</span>
          <br />
          <a
            href="javascript:void(0)"
            class="select-from-computer-text"
            (click)="fileDropRef.click()"
          >
            {{ "select from your computer" | translate }}
          </a>
        </p>
        <p class="drag-the-file-sub-text">
          {{ "Allowed extensions" | translate }}
          <br />
          {{ "Maximum size: 5MB" | translate }}
        </p>
      </div>
    </div>

    <div class="file-error" *ngIf="fileWithError">
      <p>
        <mat-icon>error</mat-icon>
        <span class="error-text">
          {{ "Tamaño o formato de archivo no permitido" | translate }}.
        </span>
      </p>
    </div>

    <div class="file-error" *ngIf="fileUploadWithError">
      <p>
        <mat-icon>error</mat-icon>
        <span class="error-text">
          {{
            "Ha ocurrido un error durante la carga de un archivo. Intenta nuevamente"
              | translate
          }}.
        </span>
      </p>
    </div>

    <div *ngFor="let file of files; index as i" class="single-file">
      <div class="left-side">
        <mat-icon class="loader" *ngIf="file.progress < 100 && !file.error">
          <img src="../../assets/images/loader-upload-file.svg" />
        </mat-icon>

        <mat-icon
          class="file-uploaded"
          *ngIf="file.progress === 100 && !file.error"
        >
          done
        </mat-icon>

        <mat-icon class="file-upload-error" *ngIf="file.error">
          error
        </mat-icon>
      </div>
      <div class="right-side">
        <div class="file-center">
          <div class="file-line-up">
            <div class="file-name">
              {{ file.file.name }}
            </div>
            <div class="file-options">
              <div
                *ngIf="file.progress < 100 && !file.error"
                class="cancel-file-progress"
                (click)="cancelFile(i)"
              >
                <mat-icon>cancel</mat-icon>
              </div>

              <div
                *ngIf="file.progress === 100 && !file.error"
                class="remove-file"
                (click)="removeFile(i)"
              >
                <mat-icon>delete</mat-icon>
              </div>
              <div class="file-error-text" *ngIf="file.error">
                <span>Error</span>
              </div>
            </div>
          </div>

          <div class="file-line-down">
            <div class="file-size">
              {{ getFileSizeWithUnit(file.file) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div mat-dialog-actions class="actions">
    <button
    class="large-button-blue"
    style="width: 114px !important;"
      mat-button
      mat-raised-button
      (click)="save()"
      [disabled]="!canSave() || isSaving"
    >
      {{ "Save" | translate }}
    </button>
  </div>
</div>
