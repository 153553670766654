import { Component, Inject } from "@angular/core";

import {
  MatDialogRef,
  MatDialog,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";

interface DialogData {
  title: string;
  acceptButton: {
    text: string;
    show: boolean;
  };
  cancelButton: {
    text: string;
    show: boolean;
  };
}

@Component({
  selector: "app-confirm-dialog",
  templateUrl: "./confirm-dialog.component.html",
  styleUrls: ["./confirm-dialog.component.scss"],
})
export class ConfirmDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,

    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {}

  onAccept() {
    this.dialogRef.close(true);
  }

  onClose() {
    this.dialogRef.close("close");
  }

  onCancel() {
    this.dialogRef.close(false);
  }
}
