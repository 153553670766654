import { Component, Inject, OnInit } from "@angular/core";
import {
  MatDialogRef,
  MatDialog,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";

@Component({
  selector: "app-confirm-em-consultation",
  templateUrl: "./confirm-em-consultation.component.html",
  styleUrls: ["./confirm-em-consultation.component.scss"],
})
export class ConfirmEmConsultationComponent {
  consultId: any;
  constructor(
    private matDialog: MatDialog,
    public dialogRef: MatDialogRef<ConfirmEmConsultationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  onClose() {
    this.dialogRef.close("close");
  }

  onCancel() {
    this.dialogRef.close(false);
  }

  onAccept() {
    this.dialogRef.close(true);
  }
}
