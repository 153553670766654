<div
  *ngIf="appointmentInfo"
  style="
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 30px;
    height: 100%;
  "
>
  <div class="section-container">
    <div
      *ngIf="completeInfo"
      style="
        display: flex;
        justify-content: space-between;
        padding: 30px 25px 0px 50px;
      "
    >
      <div
        style="
          display: flex;
          align-items: center;
          gap: 10px;
          font-size: 24px;
          font-weight: bold;
        "
      >
        <div
          style="
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: #5664af;
            display: flex;
            align-items: center;
            justify-content: center;
          "
        >
          <mat-icon style="color: white">event_note</mat-icon>
        </div>
        {{ "Appointment information" | translate }}
      </div>
      <div
        style="
          color: #65a7f6;
          font-size: 16px;
          cursor: pointer;
          display: flex;
          gap: 20px;
          margin-right: 3%;
        "
      >
        <p
          style="margin: 0px; margin-top: 7px"
          (click)="
            openScheduleFormDialog(
              'reschedule',
              'Reagendar cita médica',
              'Reagendar'
            )
          "
          *ngIf="
            !cancelStatus.includes(appointmentInfo.appointmentStatus) &&
            appointmentInfo.appointmentRescheduledCount <= 2 &&
            rescheduleStatus.includes(appointmentInfo.appointmentStatus)
          "
        >
          Editar
        </p>
        <div class="open-icon" (click)="hideCompleteInfo()">
          <mat-icon>keyboard_arrow_up</mat-icon>
        </div>
      </div>
    </div>
    <div
      *ngIf="completeInfo"
      style="
        display: flex;
        padding: 0px 25px 20px 50px;
        border-bottom: 1px solid #0000000d;
      "
    >
      <div style="display: flex; align-items: end; gap: 15px; width: 45%">
        <button
          *ngIf="!cancelStatus.includes(appointmentInfo.appointmentStatus)"
          [disabled]="appointmentInfo.appointmentStatus !== 3"
          class="button confirm"
          (click)="confirmAppointment(appointmentInfo._id)"
        >
          {{ "Confirm" | translate }}
        </button>
        <button
          *ngIf="!cancelStatus.includes(appointmentInfo.appointmentStatus)"
          class="button reagent"
          (click)="
            openScheduleFormDialog(
              'reschedule',
              'Reagendar cita médica',
              'Reagendar'
            )
          "
          [disabled]="
            appointmentInfo.appointmentRescheduledCount > 2 ||
            !rescheduleStatus.includes(appointmentInfo.appointmentStatus)
          "
        >
          {{ "Reschedule management" | translate }}
        </button>
        <button
          *ngIf="!cancelStatus.includes(appointmentInfo.appointmentStatus)"
          class="button cancel"
          (click)="cancelAppointment(appointmentInfo._id)"
        >
          {{ "Cancel" | translate }}
        </button>
      </div>
      <div>
        <p
          style="
            font-size: 16px;
            margin: 0px;
            line-height: 21.82px;
            margin-bottom: 5px;
          "
        >
          {{ "Appointment date" | translate }}
        </p>
        <p
          style="
            font-size: 20px;
            font-weight: bold;
            margin: 0px;
            line-height: 27.28px;
          "
        >
          {{
            cancelStatus.includes(appointmentInfo.appointmentStatus)
              ? "Cancelada"
              : formatAppointmentDate(appointmentInfo.date)
          }}
        </p>
      </div>
    </div>
    <div style="display: flex; justify-content: space-around; padding: 20px">
      <div class="appointment-info-item" [class.width-20]="completeInfo">
        <p class="appointment-info-title">{{ "Specialist" | translate }}</p>
        <p class="appointment-info-text">{{ appointmentInfo.specialtyName }}</p>
      </div>
      <div class="appointment-info-item" [class.width-20]="completeInfo">
        <p class="appointment-info-title">{{ "Dr" | translate }}</p>
        <p class="appointment-info-text">
          {{
            appointmentInfo.doctor.firstName +
              " " +
              appointmentInfo.doctor.lastName
          }}
        </p>
      </div>
      <div class="appointment-info-item" [class.width-20]="completeInfo">
        <p class="appointment-info-title">{{ "Date" | translate }}</p>
        <p class="appointment-info-text">{{ getDate(appointmentInfo.date) }}</p>
      </div>
      <div class="appointment-info-item" [class.width-20]="completeInfo">
        <p class="appointment-info-title">{{ "Hour" | translate }}</p>
        <p class="appointment-info-text">{{ getTime(appointmentInfo.hour) }}</p>
      </div>
      <div class="appointment-info-item" [class.width-20]="completeInfo">
        <p class="appointment-info-title">{{ "State" | translate }}</p>
        <p class="appointment-info-text">
          <img
            *ngIf="appointmentInfo.appointmentStatus === 8"
            src="assets/images/tick.svg"
            alt=""
          />
          <img
            *ngIf="appointmentInfo.appointmentStatus === 9"
            src="assets/images/y-tick.svg"
            alt=""
          />
          {{
            getAppointmentStatus(
              appointmentInfo.appointmentStatus,
              appointmentInfo.appointmentRescheduledCount +
                appointmentInfo.appointmentRescheduledByDoctorCount
            )
          }}
        </p>
      </div>
      <div
        *ngIf="!completeInfo"
        class="appointment-info-item-icon"
        (click)="showCompleteInfo()"
      >
        <mat-icon>keyboard_arrow_down</mat-icon>
      </div>
    </div>
  </div>
</div>

<div
  *ngIf="!appointmentInfo"
  style="
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 30px;
    height: 100%;
  "
>
  <div class="section-container">
    <div
      *ngIf="completeInfo"
      style="
        display: flex;
        justify-content: space-between;
        padding: 30px 25px 0px 50px;
      "
    >
      <div
        style="
          display: flex;
          align-items: center;
          gap: 10px;
          font-size: 24px;
          font-weight: bold;
        "
      >
        <div
          style="
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: #5664af;
            display: flex;
            align-items: center;
            justify-content: center;
          "
        >
          <mat-icon style="color: white">event_note</mat-icon>
        </div>
        {{ "Appointment information" | translate }}
      </div>
    </div>

    <div style="display: flex; justify-content: space-around; padding: 20px">
      <div class="appointment-info-item" [class.width-20]="completeInfo">
        <p class="appointment-info-title">{{ "Specialist" | translate }}</p>
      </div>
      <div class="appointment-info-item" [class.width-20]="completeInfo">
        <p class="appointment-info-title">{{ "Dr" | translate }}</p>
      </div>
      <div class="appointment-info-item" [class.width-20]="completeInfo">
        <p class="appointment-info-title">{{ "Date" | translate }}</p>
      </div>
      <div class="appointment-info-item" [class.width-20]="completeInfo">
        <p class="appointment-info-title">{{ "Hour" | translate }}</p>
      </div>
      <div class="appointment-info-item" [class.width-20]="completeInfo">
        <p class="appointment-info-title">{{ "State" | translate }}</p>
      </div>
    </div>
    <div
      style="
        display: flex;
        justify-content: center;
        min-height: 50px;
        font-size: 16px;
        font-weight: 700;
      "
    >
      Sin citas agendadas para paciente seleccionado
    </div>
  </div>
</div>
