import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { I18nService } from "./core/i18n.service";
import { environment } from "src/environments/environment";
/* import { version } from "../../package.json";
 */
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  /*   public version: string = version; */

  constructor(
    private router: Router,
    private i18nService: I18nService,
  ) {
    /* console.log(`Versión ${version}`); */
  }

  ngOnInit() {
    // Setup translations
    this.i18nService.init(
      environment.defaultLanguage,
      environment.supportedLanguages,
    );
    if (localStorage.getItem("language") === null) {
      this.i18nService.language = environment.defaultLanguage;
    } else {
      this.i18nService.language = localStorage.getItem("language") as any;
    }
  }
}
