<div class="dialog-container" style="padding: 30px;padding-top: 50px;">
  <mat-icon
    style="right: 20px; top: 25px; cursor: pointer; position: absolute"
    (click)="onClose()"
    >close</mat-icon
  >

  <mat-icon class="phone-icon">add_call</mat-icon>
  <h5 class="heading">
    {{ data.modalTitle }}
  </h5>
  <h4 class="sub-title">
    Completa los campos para continuar con el agendamiento de cita.
  </h4>

  <form [formGroup]="appointmentForm" class="form" action="">
    <mat-form-field>
      <mat-select
        formControlName="medicalSpecialty"
        (selectionChange)="onSpecialtyChange($event)"
      >
        <mat-option
          *ngFor="let specialty of specialtyList"
          [value]="specialty.medicalSpecialty._id"
        >
          {{ getSpecialtyName(specialty.medicalSpecialty) }}</mat-option
        >
      </mat-select>
      <mat-error
        *ngIf="appointmentForm.get('medicalSpecialty').hasError('required')"
        >Requerido</mat-error
      >
    </mat-form-field>
    <mat-form-field>
      <mat-select formControlName="doctorSpecialist">
        <mat-option value="">Selecciona un doctor</mat-option>
        <mat-option *ngFor="let doctor of doctorList" [value]="doctor._id">
          {{ doctor.firstName + " " + doctor.lastName }}</mat-option
        >
      </mat-select>
      <mat-error
        *ngIf="appointmentForm.get('doctorSpecialist').hasError('required')"
        >Requerido</mat-error
      >
    </mat-form-field>
    <mat-form-field>
      <input
        readonly
        matInput
        [matDatepicker]="picker"
        [min]="minDate"
        formControlName="date"
        [placeholder]="getDatePlaceholder()"
        (change)="onDateChange($event)"
      />
      <mat-icon matSuffix (click)="picker.open()">calendar_today</mat-icon>
      <mat-datepicker #picker></mat-datepicker>
      <mat-error *ngIf="appointmentForm.get('date').hasError('required')"
        >Requerido</mat-error
      >
    </mat-form-field>
    <mat-form-field>
      <input
        readonly
        type="text"
        matInput
        [placeholder]="getHourPlaceholder()"
        formControlName="hour"
      />

      <mat-icon matSuffix (click)="openTimepicker()"> schedule</mat-icon>
      <mat-error *ngIf="appointmentForm.get('hour').hasError('required')"
        >Requerido</mat-error
      >
    </mat-form-field>
  </form>

  <div mat-dialog-actions class="sucessBtn large-button-blue mid-width"  [class.margin-left]="data.margin">
    <button  (click)="submitAppointment()" class="accept-btn">
      {{ data.button }}
    </button>
  </div>
</div>
<!-- <div class="dialog-container">
  <mat-icon style="right: 5px;top: 5px; cursor: pointer; position: absolute;" (click)="onClose()"
    >close</mat-icon
  >

  <img class="main-icon" src="assets/images/alert-icon.svg" alt="">
  <h5 class="heading">
    asdasd
  </h5>
  <div mat-dialog-actions class="sucessBtn" [class.margin-left]="data.margin">
  
   
    <button mat-raised-button (click)="onCancel()" class="back-btn" >
      cancel
    </button>
     <button mat-raised-button (click)="onAccept()" class="accept-btn" >
    accept
    </button>
  </div>
</div> -->
