<div
  style="
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 30px;
    height: 100%;
    position: relative;
  "
>
  <div
    style="
      position: absolute;
      top: 25px;
      left: 30px;
      display: flex;
      align-items: center;
      gap: 5px;
      cursor: pointer;
    "
    (click)="goDashboard()"
  >
    <mat-icon style="width: 18px; height: 18px; font-size: 18px"
      >arrow_back</mat-icon
    >
    <p style="margin: 0; font-size: 14px">{{ "Back to Home" | translate }}</p>
  </div>
  <div class="section-container last-appointment">
    <div *ngIf="!loading">
      <app-agent-patient-info
        [isAppointment]="onlyOne"
        [patientInfo]="patient"
        [consultationList]="consultationList"
      ></app-agent-patient-info>
    </div>
  </div>
</div>
