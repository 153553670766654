import { Component } from "@angular/core";

@Component({
  selector: "app-agent-dashboard",
  templateUrl: "./agent-dashboard.component.html",
  styleUrls: ["./agent-dashboard.component.scss"],
})
export class AgentDashboardComponent {
  constructor() {}
}
