<div style="position: relative; height: 100%">
  <div
    style="
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 40px;
    "
  >
    {{ "Latest scheduled appointments" | translate }}
  </div>
  <div style="width: 100%; margin-top: 15px; height: 100%">
    <div class="tHead">
      <p class="date">Fecha</p>
      <p class="patient">Paciente</p>
      <p class="reason">Motivo de consulta</p>
      <p class="doctor">Doctor</p>
    </div>

    <div style="max-height: calc(100% - 105px) !important; overflow: auto">
      <div class="tBody-item" *ngFor="let item of consults">
        <div class="date">
          {{ item.date | date: "dd/MM/yy" }}
        </div>
        <div class="patient">
          {{ item.patient.firstName + " " + item.patient.lastName }}
        </div>
        <div class="reason">
          {{ item.chiefComplaint.chiefComplaint.es }}
        </div>
        <div class="doctor">
          {{ item.doctor.firstName + " " + item.doctor.lastName }}
        </div>
      </div>
    </div>
  </div>
</div>
