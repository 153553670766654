<ng-container [formGroup]="parentFormGroup">
  <div class="text-area-component">
    <label
      [ngClass]="{
        'text-area-component__label': style === 'opaque',
        'text-area-component__label-normal': style === 'normal',
      }"
      class="subheading text-area-component__label"
      >{{ label | translate }}
      <label *ngIf="!valid" style="color: red">*</label></label
    >
    <div
      *ngIf="edit"
      [innerHtml]="!!data ? data : ''"
      contenteditable="true"
      (keyup)="changeEvent($event)"
      class="text-area-component__input text-area-component__input--edit"
      [ngClass]="{ valid: valid, invalid: !valid }"
    ></div>
    <div
      *ngIf="!edit"
      [innerHtml]="!!data ? data : '-'"
      class="text-area-component__input"
    ></div>
    <input [id]="controller" [formControlName]="controller" />
  </div>
</ng-container>
