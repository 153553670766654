import { Component, OnInit, Inject, ViewEncapsulation } from "@angular/core";
import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA,
} from "@angular/material/snack-bar";

@Component({
  selector: "app-message",
  templateUrl: "./message.component.html",
  styleUrls: ["./message.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class MessageComponent implements OnInit {
  constructor(
    public snackBarRef: MatSnackBarRef<MessageComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: string,
  ) {}

  ngOnInit(): void {}
}
