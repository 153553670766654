import { NgModule } from "@angular/core";
import { Routes, RouterModule, ExtraOptions } from "@angular/router";
//import { SignaturePadModule } from "angular2-signaturepad";
//import { SignaturePadModule } from "ngx-signaturepad";
import { SignatureFieldComponent } from "../signature-field/signature-page.component";

@NgModule({
  imports: [],
  declarations: [SignatureFieldComponent],
  exports: [SignatureFieldComponent],
})
export class SignatureModule {}
