<div
  style="
    position: fixed;
    top: 70px;
    right: 20px;
    display: flex;
    gap: 10px;
    z-index: 100;
    flex-direction: column-reverse;
  "
>
  <div
    *ngFor="let noti of notifications"
    (click)="removeNoti(noti._id)"
    [id]="noti._id + 'noti'"
    class="notification-container"
    @listAnimation
  >
    <div
      style="display: flex; align-items: center; gap: 10px; margin-bottom: 10px"
    >
      <img src="assets/images/heart.svg" alt="" />
      <p>Nueva solicitud para agendamiento</p>
    </div>
    <p>Paciente : {{ noti.patient.firstName + " " + noti.patient.lastName }}</p>
    <p>Motivo de consulta : {{ noti.chiefComplaint.chiefComplaint.es }}</p>
  </div>
</div>
<div
  class="section-container"
  style="
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  "
>
  <div style="display: flex; align-items: center; gap: 20px; height: 10%">
    <div
      style="
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #5664af;
        display: flex;
        align-items: center;
        justify-content: center;
      "
    >
      <mat-icon style="color: white">event_note</mat-icon>
    </div>
    <p style="margin: 0px; font-size: 24px">
      {{ "Appointment request" | translate }}
    </p>
  </div>
  <div style="display: flex; justify-content: space-around; height: 45px">
    <button
      class="button"
      (click)="setActiveTab(0)"
      [class.tab-active]="activeTab == 0"
    >
      {{ "Schedule" | translate }}
      <span class="add-container" *ngIf="scheduleList.length > 0">{{
        scheduleList.length
      }}</span>
    </button>
    <button
      class="button"
      (click)="setActiveTab(1)"
      [class.tab-active]="activeTab == 1"
    >
      {{ "Confirm" | translate }}
    </button>
    <button
      class="button"
      (click)="setActiveTab(2)"
      [class.tab-active]="activeTab == 2"
    >
      {{ "Reschedule management" | translate }}
      <span class="icon-container" *ngIf="rescheduleList.length > 0"
        ><mat-icon>priority_high</mat-icon>
      </span>
    </button>
  </div>

  <mat-tab-group
    [selectedIndex]="activeTab"
    style="max-height: 60vh"
    class="list-container-tab"
  >
    <mat-tab
      style="
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        max-height: 100%;
      "
    >
      <div
        *ngIf="!loading"
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          gap: 15px;
        "
      >
        <div *ngIf="scheduleList.length <= 0">
          <img src="assets/images/emptylist.svg" alt="" />
          <p
            style="
              margin: 0;
              font-size: 16px;
              max-width: 27ch;
              text-align: center;
            "
          >
            {{ "You do not have pending appointments to schedule" | translate }}
          </p>
        </div>
        <app-appointment-item
          [messages]="scheduleList"
          style="width: 100%"
        ></app-appointment-item>
      </div>
    </mat-tab>
    <mat-tab
      style="height: 100%; width: 100%; display: flex; flex-direction: column"
    >
      <div
        *ngIf="!loading"
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          gap: 15px;
        "
      >
        <div *ngIf="scheduledList.length <= 0">
          <img src="assets/images/emptylist.svg" alt="" />
          <p
            style="
              margin: 0;
              font-size: 16px;
              max-width: 27ch;
              text-align: center;
            "
          >
            {{ "You do not have pending appointments to confirm" | translate }}
          </p>
        </div>
        <app-appointment-item
          [messages]="scheduledList"
          style="width: 100%"
        ></app-appointment-item>
      </div>
    </mat-tab>
    <mat-tab
      style="height: 100%; width: 100%; display: flex; flex-direction: column"
    >
      <div
        *ngIf="!loading"
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          gap: 15px;
        "
      >
        <div *ngIf="rescheduleList.length <= 0">
          <img src="assets/images/emptylist.svg" alt="" />
          <p
            style="
              margin: 0;
              font-size: 16px;
              max-width: 27ch;
              text-align: center;
            "
          >
            {{
              "You do not have pending appointments to reschedule" | translate
            }}
          </p>
        </div>
        <app-appointment-item
          [messages]="rescheduleList"
          [reAgent]="true"
          style="width: 100%"
        ></app-appointment-item>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
