import { Injectable, OnDestroy } from "@angular/core";
import _isEqual from "lodash.isequal";
import moment from "moment";
import { MultiStreamRecorder, RecordRTCPromisesHandler } from "recordrtc";
import {
  BehaviorSubject,
  combineLatest,
  from,
  interval,
  of,
  Subject,
} from "rxjs";
import {
  distinctUntilChanged,
  filter,
  map,
  takeUntil,
  scan,
  switchMap,
  withLatestFrom,
  skip,
} from "rxjs/operators";

import type { RecordedVideoOutput } from "./video.model";
import type { Options, Recorder } from "recordrtc";
import type { Observable } from "rxjs";

declare var QB: any;

@Injectable({
  providedIn: "root",
})
export class VideoService implements OnDestroy {
  sessionSubject = new Subject<any>();
  sessionObservable = this.sessionSubject.asObservable();
  private recorder: RecordRTCPromisesHandler;
  private consultId: string = "";
  private readonly chunksIntervals: number = 20 * 60 * 1000;
  private readonly previewStream$ = new BehaviorSubject<MediaStream>(null);
  private readonly recordingInProgress$ = new BehaviorSubject<boolean>(false);
  private readonly recordedVideoOutput$ =
    new BehaviorSubject<RecordedVideoOutput>(null);
  private readonly defaultVideoRecordingOptions: Options = {
    disableLogs: true,
    type: "video",
    mimeType: "video/webm;codecs=vp8,opus" as any,
    audioBitsPerSecond: 128000,
    videoBitsPerSecond: 44000,
    frameInterval: 30,
    frameRate: 30,
    sampleRate: 44100,
    video: {
      width: 360,
      height: 240,
    } as HTMLVideoElement,
  };

  ngOnDestroy(): void {
    this.stopRecording(true);
  }

  getPreviewStream$(): Observable<MediaStream> {
    return this.previewStream$.asObservable();
  }

  getRecordedVideoOutput$(): Observable<RecordedVideoOutput> {
    return combineLatest([
      this.recordedVideoOutput$.asObservable(),
      this.recordingInProgress$,
    ]).pipe(
      distinctUntilChanged(_isEqual),
      switchMap(([recordedVideoOutput, isRecordingInProgress]) => {
        if (isRecordingInProgress && !recordedVideoOutput) {
          return interval(this.chunksIntervals).pipe(
            takeUntil(this.recordingInProgress$.pipe(skip(1))),
            switchMap(() => from(this.restartRecording())),
            map((recordedVideoOutputChunk) => [
              recordedVideoOutputChunk,
              isRecordingInProgress,
            ]),
          );
        }

        return of([recordedVideoOutput, isRecordingInProgress]);
      }),
      distinctUntilChanged(_isEqual),
      scan(
        (
          recordedVideoOutputChunks,
          [recordedVideoOutput, isRecordingInProgress]: [
            RecordedVideoOutput,
            boolean,
          ],
        ) => {
          let result: RecordedVideoOutput[] = null;

          if (isRecordingInProgress && recordedVideoOutput) {
            result = Array.isArray(recordedVideoOutputChunks)
              ? [...recordedVideoOutputChunks, recordedVideoOutput]
              : [recordedVideoOutput];
          } else if (recordedVideoOutput) {
            result = Array.isArray(recordedVideoOutputChunks)
              ? recordedVideoOutputChunks
              : [recordedVideoOutput];
          }

          return result;
        },
        null as RecordedVideoOutput[],
      ),
      withLatestFrom(this.recordingInProgress$),
      distinctUntilChanged(_isEqual),
      filter(
        ([recordedVideoOutputChunks, isRecordingInProgress]) =>
          !isRecordingInProgress && recordedVideoOutputChunks?.length > 0,
      ),
      switchMap(([recordedVideoOutputChunks]) =>
        of(...recordedVideoOutputChunks),
      ),
    );
  }

  createSession(calleesIds, sessionType, callerID, additionalOptions) {
    let sessionValue = QB.webrtc.createNewSession(
      calleesIds,
      sessionType,
      null,
      additionalOptions,
    );
    this.sessionSubject.next(sessionValue);
  }

  async startRecording(
    consultId: string,
    localStream: MediaStream,
    remoteStream?: MediaStream,
    showPreview: boolean = false,
  ): Promise<void> {
    if (!this.recorder) {
      const videoRecordingOptions: Options = {
        ...this.defaultVideoRecordingOptions,
        ...(showPreview && {
          timeSlice: 1000,
          previewStream: (stream: MediaStream) => {
            this.previewStream$.next(stream);
          },
        }),
      };
      const streams: any = remoteStream
        ? [localStream, remoteStream]
        : [localStream];
      this.consultId = consultId;
      this.recorder = new RecordRTCPromisesHandler(
        streams,
        videoRecordingOptions,
      );
      await this.recorder.startRecording();
      this.recordingInProgress$.next(true);
    }
  }

  async addStreams(
    recorder: RecordRTCPromisesHandler,
    streams: MediaStream[],
  ): Promise<void> {
    if (recorder && streams.length) {
      const internalRecorder: Recorder = await recorder.getInternalRecorder();

      if (internalRecorder instanceof MultiStreamRecorder) {
        return internalRecorder.addStreams(streams);
      }
    }
  }

  async stopRecording(destroyRecorder?: boolean): Promise<void> {
    if (this.recorder) {
      await this.recorder.stopRecording();

      if (this.consultId) {
        const videoFile = await this.processVideo(this.consultId);
        this.recordedVideoOutput$.next({
          file: videoFile,
          consultId: this.consultId,
        });
        this.consultId = "";
      }

      this.recordingInProgress$.next(false);
      this.recordedVideoOutput$.next(null);

      if (destroyRecorder) {
        return this.destroyRecorder();
      }
    }
  }

  async restartRecording(): Promise<void | RecordedVideoOutput> {
    if (this.recorder) {
      await this.recorder.stopRecording();
      const videoFile = await this.processVideo(this.consultId);
      this.recorder.startRecording();

      return { file: videoFile, consultId: this.consultId };
    }
  }

  private async processVideo(consultId: string): Promise<File> {
    if (consultId) {
      const recordedBlob: Blob = await this.recorder.getBlob();
      const filename = encodeURIComponent(
        `videocall_${consultId}_${moment().format("DD-MM-YYYY_HH-mm-ss")}.webm`,
      );

      return new File([recordedBlob], filename, {
        type: this.defaultVideoRecordingOptions.mimeType,
      });
    }
  }

  async destroyRecorder(): Promise<void> {
    if (this.recorder) {
      await this.recorder.destroy();
      this.recorder = null;
    }
  }
}
