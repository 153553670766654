import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from "@angular/core";
import { QueuestatusService } from "./queuestatus.service";
import { DashboardService } from "../dashboard.service";
import { Chart } from "chart.js";
import _ from "lodash";
import { HttpErrorResponse } from "@angular/common/http";
import { SharedService } from "src/app/shared/shared.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-queuestatus",
  templateUrl: "./queuestatus.component.html",
  styleUrls: ["./queuestatus.component.scss"],
})
export class QueuestatusComponent implements OnInit {
  showNoData = false;
  draw: Chart;
  waiting;
  completed;
  consulted;
  totalPatient;
  queueRes: any = [];
  patientsWaiting: string;
  patientsConsulted: string;
  patientsIConsulted: string;
  totalPatientText: string;
  private context: CanvasRenderingContext2D;
  @ViewChild("patientDetails", { static: false }) canvas: ElementRef;

  constructor(
    private queueService: QueuestatusService,
    private sharedService: SharedService,
    private dashboardService: DashboardService,
    private translationService: TranslateService,
  ) {}

  ngOnInit() {
    this.translationService
      .get("Patients waiting")
      .subscribe((text: string) => {
        this.patientsWaiting = text;
      });
    this.translationService
      .get("Patients consulted")
      .subscribe((text: string) => {
        this.patientsConsulted = text;
      });
    this.translationService
      .get("Patients I consulted")
      .subscribe((text: string) => {
        this.patientsIConsulted = text;
      });
    this.translationService.get("Total Patients").subscribe((text: string) => {
      this.totalPatientText = text;
    });
    this.queueStatus();
  }
  queueStatus() {
    this.dashboardService.getQueueStatus({}).subscribe(
      (res: any) => {
        this.queueRes = res["data"];

        const response: any = res;
        if (this.queueRes.length === 0) {
          this.showNoData = true;
          // Clear canvas
        } else {
          this.queueRes.forEach((element) => {
            this.waiting = element.waiting;
            this.completed = element.completed;
            this.consulted = element.completedByMe;
            this.totalPatient = this.waiting + this.completed;
          });
          if (this.totalPatient > 0) {
            this.creatChart();
            this.showNoData = false;
          } else {
            this.showNoData = true;
          }
        }
      },
      (error: HttpErrorResponse) => {
        this.handleError(error);
      },
    );
  }
  creatChart() {
    if (this.draw) {
      this.draw.destroy();
    }

    this.context = (this.canvas.nativeElement as HTMLCanvasElement).getContext(
      "2d",
    );
    this.draw = new Chart(this.context, {
      // The type of chart we want to create
      type: "doughnut",
      // The data for our dataset
      data: {
        // labels: ["Patient waiting", "Patient consulted", "Patient I consulted"],
        labels: [
          this.patientsWaiting,
          this.patientsConsulted,
          this.patientsIConsulted,
        ],
        datasets: [
          {
            backgroundColor: ["#CD252D", "#2751A5", "#65A7F6"],
            data: [this.waiting, this.completed, this.consulted],
          },
        ],
      },
      options: {
        elements: {
          center: {
            text:
              (this.totalPatient > 0 ? this.totalPatient : 0) +
              " " +
              this.totalPatientText,
            color: "#46535B", // Default is #000000
            fontStyle: "Arial", // Default is Arial
            sidePadding: 20, // Defualt is 20 (as a percentage)
          },
          arc: {
            borderWidth: 10,
          },
        },
        responsive: true,
        maintainAspectRatio: true,
        title: {
          display: true,
        },
        aspectRatio: 2,
        cutoutPercentage: 65,
        legend: {
          display: true,
          position: "right",
          labels: {
            usePointStyle: true,
            fontSize: 14,
          },
        },
        borderWidth: 0,
        tooltips: {
          backgroundColor: "#FFF",
          titleFontSize: 14,
          titleFontColor: "#0066ff",
          bodyFontColor: "#000",
          bodyFontSize: 14,
          displayColors: false,
          borderWidth: 2,
          borderColor: "#6da9fb",
        },
      },
      centerText: {
        display: true,
      },
    });

    Chart.controllers.doughnut.extend({
      draw: function (ease) {
        var ctx = this.chart.ctx;
        var easingDecimal = ease || 1;
        var arcs = this.getMeta().data;
        Chart.helpers.each(arcs, function (arc, i) {
          arc.transition(easingDecimal).draw();

          var pArc = arcs[i === 0 ? arcs.length - 1 : i - 1];
          var pColor = pArc._view.backgroundColor;

          var vm = arc._view;
          var radius = (vm.outerRadius + vm.innerRadius) / 2;
          var thickness = (vm.outerRadius - vm.innerRadius) / 2;
          var startAngle = Math.PI - vm.startAngle - Math.PI / 2;
          var angle = Math.PI - vm.endAngle - Math.PI / 2;

          ctx.save();
          ctx.translate(vm.x, vm.y);

          ctx.fillStyle = i === 0 ? vm.backgroundColor : pColor;
          ctx.beginPath();
          ctx.arc(
            radius * Math.sin(startAngle),
            radius * Math.cos(startAngle),
            thickness,
            0,
            2 * Math.PI,
          );
          ctx.fill();

          ctx.fillStyle = vm.backgroundColor;
          ctx.beginPath();
          ctx.arc(
            radius * Math.sin(angle),
            radius * Math.cos(angle),
            thickness,
            0,
            2 * Math.PI,
          );
          ctx.fill();

          ctx.restore();
        });
      },
    });

    Chart.pluginService.register({
      beforeDraw: function (chart) {
        if (chart.config.options.elements.center) {
          //Get ctx from string
          var ctx = chart.chart.ctx;

          //Get options from the center object in options
          var centerConfig = chart.config.options.elements.center;
          var fontStyle = centerConfig.fontStyle || "Arial";
          var txt = centerConfig.text;
          var color = centerConfig.color || "#000";
          var sidePadding = centerConfig.sidePadding || 20;
          var sidePaddingCalculated =
            (sidePadding / 100) * (chart.innerRadius * 2);
          //Start with a base font of 30px
          ctx.font = "30px " + fontStyle;

          //Get the width of the string and also the width of the element minus 10 to give it 5px side padding
          var stringWidth = ctx.measureText(txt).width;
          var elementWidth = chart.innerRadius * 2 - sidePaddingCalculated;

          // Find out how much the font can grow in width.
          var widthRatio = elementWidth / stringWidth;
          var newFontSize = Math.floor(30 * widthRatio);
          var elementHeight = chart.innerRadius * 2;

          // Pick a new font size so it will not be larger than the height of label.
          var fontSizeToUse = Math.min(newFontSize, elementHeight);

          //Set font settings to draw it correctly.
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          var centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
          var centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
          ctx.font = fontSizeToUse + "px " + fontStyle;
          ctx.fillStyle = color;

          //Draw text in center
          ctx.fillText(txt, centerX, centerY);
        }
      },
    });
  }
  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = error.error.message;
      this.sharedService.showErrorMessage(errorMessage, "single");
    } else {
      // server-side error

      if (error.status == 422) {
        let values = [];
        for (let key in error.error.errors.messages) {
          values.push(error.error.errors.messages[key]);
        }
        this.sharedService.showMultipleErrors(values, "multi");
      } else {
        if (typeof error.error.errors == "object") {
          errorMessage = error.error.errors.messages[0];
        } else {
          errorMessage = error.error.errors;
        }
        this.sharedService.showErrorMessage(errorMessage, "single");
      }
    }
  }
}
